import handleFormatNumber from "./handleFormatNumber";

function handleFormatBasePrice(unformattedPriceRate, unit = "€"){
    var price = unformattedPriceRate +'';
    if(price.includes(unit)){
        price = price.replace(/\./g, "");
        price = price.replace(",", ".");
        price = price.replace(" €", "");
    }
    price = price.split('.');
    //format number before "," 4338 -> 4.338
    price[0] = handleFormatNumber(price[0]);
    //handle number after "," ,0 -> ,00
    if(typeof price[1] === 'undefined')
        price[1] = '00';
    else if(price[1].length === 1)
        price[1] = price[1] + "0";
    else if(price[1].length > 2)
        price[1] = price[1].substring(0, 2);
    return price[0] + "," + price[1] + " €";
}

export default handleFormatBasePrice;