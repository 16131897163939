import "whatwg-fetch";
async function getStreetsArray(postalCode, city) {
    var streetsArr;

    const response = await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiServiceNetfee,
            function: global.apiFunctionGetStreets,
            param: {
                postal_code: postalCode,
                city: city
            }
        })
    }).then(res => res.json())
        .then(
            (result) => {
                if (result.status === "ok") {
                    var streets = [];
                    if (result.result === "[]") {

                    }else{
                        //set new Vlaues for state Street array
                        streets = [];
                        for (var i = 0; i < result.result.length; i++) {
                            if (streets.indexOf(result.result[i].streetName) === -1){
                                streets.push(result.result[i].streetName);
                            }
                        }
                        return streets;
                    }
                }else{
                    return 'ERR';
                }
            }
        ).catch(function (error) {                        // catch
        console.log('Request failed', error);
    });
    streetsArr = response;
    return streetsArr;
}

export default getStreetsArray;





