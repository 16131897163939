import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import elesslogo from "../media/energydeal-blue.png";
import validateEmail from "../functions/validateEmail";
import passwordForgotten from "../fetches/passwordForgotten";

export default class PasswordForgottenMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleSubmitCsPoints = this.handleSubmitCsPoints.bind(this);

    }

    componentDidMount() {
        document.getElementById('root').classList.add('background-image'); //Add background Image on this page
        setTimeout(function () {
            var wrapperElement = document.getElementById('login-wrapper');
            wrapperElement.classList.add('show-login-wrapper');
        }, 100);
    }


    handleSubmitCsPoints(e){
        var subBtn = e.target;
        var spinner = document.getElementById("spinner");

        var errorHandling = document.getElementById("error-handling");
        var successHandling = document.getElementById("success-handling");

        var invalidEmail = document.getElementById("invalidEmail");

        var email = document.getElementById("email");

        var error = 0;
        subBtn.disabled = true;

        successHandling.classList.add("d-none");
        errorHandling.classList.add("d-none");
        spinner.classList.remove("d-none");


        if ( !validateEmail(email.value)  ){
            error = 1;
            email.classList.add("alert-border");
            invalidEmail.classList.remove("d-none");
        }
        else {
            invalidEmail.classList.add("d-none");
            email.classList.remove("alert-border");
        }

        if (error === 0){
            passwordForgotten(email.value).then(result => {
                console.log(result);

                        if (result !== "ERR") {
                            //success
                            errorHandling.classList.add("d-none");
                            setTimeout(function () {
                                successHandling.classList.remove("d-none");
                                spinner.classList.add("d-none");
                                subBtn.disabled = false;
                            }, 1500);
                        } else {

                        }
                        spinner.classList.add("d-none");
                        subBtn.disabled = false;
                    }).catch(function (error) {
                console.log('Request failed', error);
            });
        }else{
            successHandling.classList.add("d-none");
            setTimeout(function () {
                errorHandling.classList.remove("d-none");
                spinner.classList.add("d-none");
                subBtn.disabled = false;
            }, 1000);
        }

    }

    render () {
        const logo_style={width: '75%'};
        return (
            <div id='login-wrapper'>
                <div className={"row d-none d-sm-none d-md-block"}>
                    <div className={"col-md-12"}>
                        <div id={"login-header"}>
                            <a href={'https://www.eless.de/'}><img alt='' src={elesslogo} style={logo_style}/></a>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"} >
                        <div id={"login-body"} className={"eless-form"}>
                            <h2 className={'login-title'}>Passwort zurücksetzen</h2>
                            <Alert id="success-handling" className={'mb-1 d-none eless-success'}>
                                <span className={'o-checked-circle-1 medium-icon'}/><b>Erfolg!</b> <br/>
                                <span className={"ml-1"}>Ihnen wurde eine E-Mail zugesendet, bitte prüfen Sie Ihr Postfach.</span>
                            </Alert>

                            <Alert id="error-handling" className={'mb-1 d-none eless-error'}>
                                <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                <span id={"invalidEmail"} className={"d-none ml-1"}><br/>Bitte geben Sie eine valide E-Mail Adresse an.</span>
                            </Alert>

                            <input type='text' id='email' placeholder='E-Mail' />

                            <button id={"resetPassword"}  className='eless-cta-btn eless-btn w-100 login-cta mt-2 position-relative' onClick={(e) => this.handleSubmitCsPoints(e)}>
                                Bestätigen
                                <span id={'spinner'} className={'d-none'}><FontAwesomeIcon className={'fa-spin fa-2x'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/></span>
                            </button>
                        </div>
                    </div>
                    <div className={"col-md-12 text-center mt-1 mb-2"} >
                        <div id={"login-footer"} className={"eless-form"}>
                            <a title="Zum e.less Login" href={"/login"} className={"mb-2 link"}> Zurück zum Login</a> <br/>
                            <a className={"link"} title="Zur e.less Startseite" href={"https://eless.de/" }> Zur Startseite</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}