import React from 'react';
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import Alert from "../../node_modules/react-bootstrap/Alert";
import modifyCustomer3 from "../fetches/modifyCustomer3";
import APIErrorAlert from "../errorpage/APIErrorAlert";

export default class ActivatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonType: 'password',
            apiError: false,
            name: '',
            phone: '',
            company: '',
            gender: "male"
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.removeParam = this.removeParam.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
    }

    handleSubmit(e){
        e.preventDefault();
        var password = document.getElementById("password");
        var errorHandling = document.getElementById("error-handling");
        var error = 0;
        var companyInputField = document.getElementById("company");
        var phoneInputField = document.getElementById("phone");
        var nameInputField = document.getElementById("name");

        var regCheckForInt = /^\d+$/;
        //validation
        if (this.state.name.length <= 1){
            error = 1;
            nameInputField.classList.add("alert-border");
        }else nameInputField.classList.remove("alert-border");
         let phoneNumber = this.state.phone.replace(/ /g, '');
         phoneNumber = phoneNumber.replace("+49", "0");

        if (phoneNumber.length <= 4 || !regCheckForInt.test(phoneNumber) || phoneNumber.substring(0, 1) !== "0"){
            error = 1;
            phoneInputField.classList.add("alert-border");
        }else phoneInputField.classList.remove("alert-border");

        if (this.state.company.length <= 1){
            error = 1;
            companyInputField.classList.add("alert-border");
        }else companyInputField.classList.remove("alert-border");

        if (password.value.length < 6){
            error = 1;
            password.classList.add("alert-border");
        }else password.classList.remove("alert-border");

        if (error === 0) {
            modifyCustomer3(
                this.props.customerID,
                password.value,
                this.state.gender,
                this.state.company,
                this.state.name,
                this.state.phone,

            ).then((result) => {
                if (result !== "ERR"){
                    errorHandling.classList.add("d-none");
                    this.removeParam("activation");
                    this.props.closeModal();
                }else {
                    this.apiErrorOccured('modifyCustomer3', result)
                }
            });
        }else{
            errorHandling.classList.remove("d-none");
        }
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    removeParam(parameter){
        var url=document.location.href;
        var urlparts= url.split('?');
        if (urlparts.length>=2)
        {
            var urlBase=urlparts.shift();
            var queryString=urlparts.join("?");
            var prefix = encodeURIComponent(parameter)+'=';
            var pars = queryString.split(/[&;]/g);
            for (var i= pars.length; i-->0;)
                if (pars[i].lastIndexOf(prefix, 0)!==-1)
                    pars.splice(i, 1);
            url = urlBase+'?'+pars.join('&');
            window.history.replaceState({},document.title,url); // added this line to push the new url directly to url bar .
        }
        //return url;
    }

    handleShowPassword(){
        if(this.state.buttonType === 'text'){
            this.setState({buttonType: 'password'})
        }else{
            this.setState({buttonType: 'text'})
        }
    }

    handleNameChange = (e) => {
        this.setState({name: e.target.value})
    };

    handlePhoneChange = (e) => {
        this.setState({phone: e.target.value})
    };

    handleCompanyChange = (e) => {
        this.setState({company: e.target.value})
    };


    handleChangeGender = (e) =>  {
        this.setState({
            gender: e.target.value
        })
    };

    render () {
        return (
            <>
                <Modal
                    show={this.props.show}
                    className={'password-modal'}
                >
                    <ModalBody>
                        <ModalTitle>Angaben zur Angebotskalkulation</ModalTitle>
                        <span className={'modal-text d-block mt-3 custom-border-bottom'}>Damit wir für Sie individuelle Angebote berechnen können, geben Sie bitte an, für wen diese ermittelt werden sollen und wählen Sie ein Passwort mit mind. 6 Zeichen.</span>

                        <form onSubmit={this.handleSubmit} className={'eless-form'}>
                            <ModalTitle>Kontakt</ModalTitle>
                            <div className='col-md-12 control-group gender p-0'>
                                <label className="control control-radio mr-4">
                                    Herr
                                    <input className='radio' type='radio' defaultChecked={true} onClick={this.handleChangeGender} name='gender' value='male'/>
                                    <div className="control_indicator" />
                                </label>
                                <label className="control control-radio">
                                    Frau
                                    <input className='radio' type='radio' onClick={this.handleChangeGender} name='gender' value='female'/>
                                    <div className="control_indicator" />
                                </label>
                            </div>
                            <input type='text' id='name' onChange={this.handleNameChange} placeholder='Nachname' className='mb-2' />
                            <input type='text' id='phone' onChange={this.handlePhoneChange} placeholder='Telefonnummer' className='mb-2'  />
                            <input type='text' id='company' onChange={this.handleCompanyChange} placeholder='Firmenname' className='mb-4'  />
                            <ModalTitle>Passwort</ModalTitle>
                            <Alert id="error-handling" className={'mb-1 d-none eless-error'}>
                                <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                <span id={"invalidPassword"} className={"ml-1"}>Bitte geben Sie ein valides Passwort (mind. 6 Zeichen) ein.</span>
                            </Alert>
                            <div className={'password-wrapper'}>
                                <input autoComplete="new-password" type={this.state.buttonType} id={'password'} className={'password-field'} placeholder='Passwort' />
                                <span className={'o-visible-1'} onClick={this.handleShowPassword} />
                            </div>
                            <button type={'submit'} className={'eless-weiter-btn'}>Weiter</button>
                        </form>
                    </ModalBody>
                </Modal>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
            </>
        );
    }
}