import React from 'react';
import elesslogo from '../media/energydeal-blue.png';
import Alert from 'react-bootstrap/Alert';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
// import ReCAPTCHA from "react-google-recaptcha";
import verifyPasswordByEmail from "../fetches/verifyPasswordByEmail";
const AES = require("crypto-js/aes");
const ENC = require("crypto-js/enc-utf8");

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     captcha: '',
        //     captchaValue: '',
        // };
        // this.captchaValue = "";
        // this.captcha = "";

        this.validateEmail = this.validateEmail.bind(this);
        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
        this.handleFormKeydown = this.handleFormKeydown.bind(this);
        this.changeCaptcha = this.changeCaptcha.bind(this);
    }

    componentDidMount() {
        document.getElementById('root').classList.add('background-image'); //Add background Image on this page
        var sessionCustomerID = 0;
        console.log("asdasd", localStorage['securityToken']);
        if (typeof localStorage['securityToken'] !== "undefined") {
            console.log("hier");
            sessionCustomerID = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
        }
        if (sessionCustomerID !== 0) {
            console.log("weiterleitung");
            // window.location.href = global.HomeURL;
        }

        // this.captcha = <ReCAPTCHA sitekey={global.reCapture} onChange={this.changeCaptcha} />;

        setTimeout(function () {
            var wrapperElement = document.getElementById('login-wrapper');
            wrapperElement.classList.add('show-login-wrapper');
        }, 100);
    }

    changeCaptcha(value){
        // this.captchaValue = value;
        //this.setState({captchaValue: value});
    }

    validateEmail(email) {
        //eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static checkCaptchaStatus(recaptcha, captchaVal){
        //check if captcha exists
        // if(recaptcha) {
            // has captcha
            // if (captchaVal.length === 0) return false; //not checked
        // }
        //has no captcha / captcha is checked
        // return true;
    }

    // static submitUserForm() {
    //     var response = grecaptcha.getResponse();
    //     if(response.length == 0) {
    //
    //         return false;
    //     }
    //     return true;
    // }

    handleSubmitLogin(e){
        var subBtn = e;
        var email = document.getElementById("email");
        var password = document.getElementById("password");
        var errorHandling = document.getElementById("error-handling");
        var errorSpanInvalidAccess = document.getElementById("invalidAccess");
        var errorSpanInvalidEmail = document.getElementById("invalidEmail");
        var errorSpanInvalidPassword = document.getElementById("invalidPassword");
        var errorSpanInvalidValues = document.getElementById("invalidValues");
        var spinner = document.getElementById("spinner");
        var error = 0;
        var attemptsEmail = 0; //attempts attached to the email account
        var attemptsToken = 0; //attempts attached to the device's browser
        var attemptsCurrent = 0; //attempts which count to display captcha

        subBtn.disabled = true;
        spinner.classList.remove("d-none");
        // error = this.checkLoginAttempts(error);

        //validation
        if (email.value.length === 0 && password.value.length === 0){
            errorSpanInvalidPassword.classList.add("d-none");
            error = 1;
            email.classList.add("alert-border");
            password.classList.add("alert-border");
            errorSpanInvalidValues.classList.remove("d-none");
            errorSpanInvalidEmail.classList.add("d-none");
            errorSpanInvalidAccess.classList.add("d-none");
            // this.setState({captcha: this.captcha });
        } else{
            errorSpanInvalidValues.classList.add("d-none");
            errorSpanInvalidAccess.classList.add("d-none");
            if (!this.validateEmail(email.value)){
                error = 1;
                email.classList.add("alert-border");
                errorSpanInvalidEmail.classList.remove("d-none");
                // this.setState({captcha: this.captcha });
            }else {
                errorSpanInvalidEmail.classList.add("d-none");
                email.classList.remove("alert-border");
            }

            if (password.value.length === 0){
                error = 1;
                errorSpanInvalidPassword.classList.remove("d-none");
                password.classList.add("alert-border");
            }else {
                errorSpanInvalidPassword.classList.add("d-none");
                password.classList.remove("alert-border");
            }
        }
       
        if(error === 0){
            verifyPasswordByEmail(email.value, password.value).then((result) => {  //Get LoadProfile ID's add it to the Consumption Point Data from prev fetch
                var resultContent = result.result;
                // var $recaptcha = document.querySelector('#g-recaptcha-response');
                // var captchaResult = Login.checkCaptchaStatus($recaptcha, this.captchaValue);
                if (result.status === "ok"){ //  && captchaResult === true
                    localStorage['securityToken'] = AES.encrypt(resultContent.customer_id+'', global.encriptKey);
                    errorHandling.classList.add("d-none");
                    var redirect;
                    var getParamas =  window.location.href.split(global.defaultURL + global.LoginURL);
                    if (typeof getParamas[1] === "undefined" || getParamas[1] === "" || getParamas[1] === "/") redirect = global.HomeURL;
                    else redirect = getParamas[1];
                    localStorage.removeItem('token');
                    console.log(redirect);
                    window.location.href = redirect;
                    subBtn.disabled = false;
                }
                // else if(!captchaResult){
                    // document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">Bitte lösen Sie das Captcha.</span>';
                // }
                else{
                    var encryptedAttempts = localStorage['token'];
                    if(typeof encryptedAttempts !== 'undefined'){
                        attemptsToken = AES.decrypt(encryptedAttempts, global.encriptKey).toString(ENC);
                    }
                    attemptsEmail = resultContent.attempts;

                    // if((typeof encryptedAttempts !== 'undefined' && typeof attemptsEmail === 'undefined') || attemptsToken > attemptsEmail){ //Check if the user got already too many attempts either same email or in the same browser
                    //     attemptsCurrent = attemptsToken;
                    //     //This is a new attempt so..
                    //     attemptsCurrent++;
                    // }else if((typeof encryptedAttempts === 'undefined' && typeof attemptsEmail !== 'undefined') || attemptsEmail >= attemptsToken){
                    //     attemptsCurrent = attemptsEmail;
                    //     localStorage['token'] = AES.encrypt(attemptsCurrent+'', global.encriptKey);
                    // }else if(typeof encryptedAttempts === 'undefined' && typeof attemptsEmail === 'undefined'){
                    //     localStorage['token'] = AES.encrypt(1+'', global.encriptKey);
                    //     attemptsCurrent = 1;
                    // }
                    //
                    // localStorage['token'] = AES.encrypt(attemptsCurrent+'', global.encriptKey);
                    //
                    // if (attemptsCurrent >= global.maxAttemts){
                    //     this.setState({captcha: this.captcha });
                    // }
                }
                setTimeout(function () {
                    errorSpanInvalidAccess.classList.remove("d-none");
                    errorHandling.classList.remove("d-none");
                    spinner.classList.add("d-none");
                    subBtn.disabled = false;
                }, 500);
            })
        }else{
            setTimeout(function () {
                errorHandling.classList.remove("d-none");
                spinner.classList.add("d-none");
                subBtn.disabled = false;
            }, 1000);
        }
    }

    handleFormKeydown(event){
        event.preventDefault();
        var submitBtn = document.getElementById('submitLogin');
        this.handleSubmitLogin(submitBtn);
    }

    render () {
        const logo_style={width: '75%'};
        return (
            <div>
                <div id='login-wrapper'>
                    <div className={"row d-none d-sm-none d-md-block"}>
                        <div className={"col-md-12"}>
                            <div id={"login-header"}>
                                <a href={'https://www.eless.de/'}><img alt='' src={elesslogo} style={logo_style}/></a>
                             </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <form onSubmit={this.handleFormKeydown}>
                            <div className={"col-md-12"} >
                                <div id={"login-body"} className={"eless-form"}>
                                    <h2 className={'login-title'}>Willkommen zurück!</h2>
                                    <Alert id="error-handling" className={'mb-1 d-none eless-error '}>
                                        <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                        <span id={"invalidEmail"} className={"d-none ml-1"}>Bitte geben Sie eine valide E-Mail an.</span>
                                        <span id={"invalidAccess"} className={"d-none ml-1"}>Die Kombination aus E-Mail und Passwort ist falsch. Bitte versuchen Sie es noch einmal oder Registrieren Sie sich.</span>
                                        <span id={"invalidPassword"} className={"d-none ml-1"}>Bitte geben Sie Ihr Passwort ein.</span>
                                        <span id={"invalidValues"} className={"d-none ml-1"}>Bitte geben Sie Ihre Logindaten in gekennzeichneten Felder ein.</span>
                                        {/*<span id={"invalidCaptcha"} className={"d-none ml-1"}>Bitte bestätigen Sie, dass Sie kein Roboter sind.</span>*/}
                                    </Alert>
                                    <input className={'mb-2'} autoComplete="new-password" type='text' id='email' placeholder='E-Mail' />
                                    <input className={'mb-2'} autoComplete="new-password" type='password' id='password' placeholder='Passwort' />
                                    {/*<div className={'captcha-wrapper'}>*/}
                                    {/*    {this.state.captcha}*/}
                                    {/*    <span id={"g-recaptcha-error"}/>*/}
                                    {/*</div>*/}
                                    <button type={'submit'} id={'submitLogin'} className='eless-weiter-btn eless-btn w-100 login-cta position-relative'>
                                        Anmelden
                                        <span id={'spinner'} className={'d-none login-spinner'}><FontAwesomeIcon className={'fa-spin fa-2x'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/></span>
                                    </button>
                                </div>
                            </div>
                            <div className={"col-md-12 text-center mt-1 mb-2"} >
                                <div id={"login-footer"} className={"eless-form"}>
                                    <a href={global.PasswordForgottenMail} className={"mb-2 link"}> Passwort vergessen?</a>
                                    <br/>
                                    <a className={"link"} href={"https://eless.de/"}>Neuer Kunde? Starten Sie hier</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}