import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import Alert from 'react-bootstrap/Alert';
import getUrlParam from "../../functions/getUrlParams";
// import checkSession from "../functions/checkSessionStatus";
registerLocale('de', de);
setDefaultLocale('de', de);

/* Hier wird der Liefrzeitraum berechnet und gerendert */

export default class Duration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            durationError: <></>,
            submittedError: <></>
        };
    }

    componentWillReceiveProps(nextProps) {
        this.getDurationError();
        this.getSubmittedError();
    }

    getDurationError(){
        var expired = getUrlParam()["expired"];

        if (typeof  expired !== "undefined"){
            window.history.replaceState && window.history.replaceState(
                null, '', window.location.pathname + window.location.search.replace(/[\?&]expired=[^&]+/, '').replace(/^&/, '?')
            );
            this.setState({
                durationError: <Alert id={'checkboxError'} className={'eless-info'}>
                    <span className={'o-info-help-1 medium-icon'}/><b>Fehler! </b>
                    <span id={"checkboxErrorSpan"}>Ihr gewählter Lieferbegin ist nicht mehr möglich, bitte wählen Sie ein anderen Angebotszeitraum aus.</span>
                </Alert>
            });
        }
    }

    getSubmittedError(){
        var submitted = getUrlParam()["submitted"];

        if (typeof  submitted !== "undefined"){
            window.history.replaceState && window.history.replaceState(
                null, '', window.location.pathname + window.location.search.replace(/[\?&]submitted=[^&]+/, '').replace(/^&/, '?')
            );
            this.setState({
                submittedError: <Alert id={'checkboxError'} className={'eless-info'}>
                    <span className={'o-info-help-1 medium-icon'}/><b>Info! </b>
                    <span id={"checkboxErrorSpan"}>Das Angebot, welches Sie bearbeiten wollten, wurde bereits abgeschlossen. Bitte wählen Sie eine andere Abnahmestelle.</span>
                </Alert>
            });
        }
    }


    render () {
        return (
            <div id='duration-react'>
                {this.state.durationError}
                {this.state.submittedError}
            </div>
        );
    }
}