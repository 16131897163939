function handleFormatNumber(number){
    let str = number;
    str = str.replace(" kWh", "");
    str = str.replace(/[^0-9]/g, "");
    str = str.replace(/[a-zA-Z]/g, "");
    str = str.replace(/\./g, "");
    let str_a = [];
    let i = 0;
    while (str.length - i * 3 > 0) {
        i++;
        if (str.length - i * 3 > 0) {
            str_a.push(str.substr(-3 * i, 3));
        } else {
            str_a.push(str.substr(0, str.length - (i - 1) * 3));
        }
    }
    str = "";
    while (str_a.length > 0) {
        str += str_a.pop();
        if (str_a.length > 0)
            str += ".";
    }

    return str;
}

export default handleFormatNumber;