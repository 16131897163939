import React from "react";
import getDurationTranslation from "./translateDurationCriteria";

function translateCancellationPeriod(cancellation) {
    var split = cancellation.split(' ');
    if (split[0] === "none") {
        return <div>
            Festes Vertragsende
        </div>
    } else {
        var translated = getDurationTranslation(split[1]);
        return <div>
            {split[0] + " " + translated}
        </div>
    }
}
export default translateCancellationPeriod;