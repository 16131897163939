import React from "react";
import Header from "../Header";
import Footer from "../Footer";

export default class EmailSentMessage extends React.Component {
    render (){
        var date = new Date();
        var day = String(date.getDate()).padStart(2, '0');
        var month = String(date.getMonth() + 1).padStart(2, '0');
        var year = date.getFullYear();
        var offlineText = '';

        if(this.props.match.params.tariffType === 'offline'){
            offlineText = <span> Bitte senden Sie uns die Vertragsformulare unterschrieben <b>innerhalb
                einer Stunde</b> per Mail an <a href="mailto:office@eless.de">office@eless.de</a> zurück, damit wir Ihren Antrag weiter bearbeiten können.</span>
        }

        return (
            <>
                <Header />
                <div className='max-width-content'>
                    <div className={'email-sent-wrapper'}>
                        <h2>Vielen Dank und herzlichen Glückwunsch!</h2>
                        <div>
                            Ihre Anfrage war erfolgreich und wurde am {day+'.'+month+'.'+year} entgegengenommen. In Kürze erhalten
                            Sie von uns eine E-Mail mit allen weiteren Informationen und Vertragsunterlagen.
                            {offlineText}
                        </div>
                        <span className={'o-mail-1'} />
                        <div className={'mb-4'}>
                            Hinweis: Diese Eingangsbestätigung stellt noch keine Annahme Ihres Angebots auf Abschluss eines
                            Vertrages dar. Der Versorgungsvertrag kommt erst durch gesonderte Annahmeerklärung des
                            Energieversorgers zustande. Eine Annahme/Ablehnung des Vertragesangebots wird Ihnen
                            innerhalb von zwei Werktagen per Mail erklärt.
                        </div>
                        <button onClick={() => window.location.href='/abnahmestellen'} className='eless-cta-btn eless-btn' id='consumptionPointsInlineEditBtn'>
                            Zurück zur Startseite
                        </button>
                    </div>
                </div>
                <Footer />
            </>)
    }
}