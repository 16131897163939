import "whatwg-fetch";

export default async function deleteConsumptionPoint(deleteCpID, customerID) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionDeleteConsumptionPoint,
            param: {
                consumption_point_id: deleteCpID,
                customer_id: customerID
            }
        })
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.status === "ok" && result.result !== null) {
                    return result.result;
                }else if(result.result === "Cannot delete consumption point due to contract connected with it.") {
                    return "Contract bound";
                }else{
                    return "ERR";
                }
            }
        ).catch(rejected => {
            console.log('Request failed', rejected);
        });
}