import React from "react";
import $ from "jquery";
import '../../global';
import getOfferByToken from "../../functions/getOfferByToken";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import refreshOffer from "../../functions/refreshOfferBtnClick";
import Breadcrumb from "../../benutzereingabe/Breadcrumb";
import validateMaloId from "../../functions/validateMaloId";
import toggleIcon from "../../functions/toggleIcon";
import Alert from "react-bootstrap/Alert";
import getStreetsArray from '../../functions/getStreetsArray';
import LoadingAnimationDotted from "../../functions/loadingAnimationsDotted";
import getCities from '../../fetches/getCities';
import getAllSuppliers from '../../fetches/getAllSuppliers';
import createCitySelectOptions from "../../functions/createCitySelectOptions";
import getStreets from "../../fetches/getStreets";
import SpeechBubble from "../../benutzereingabe/SpeechBubble";
import InfoIcon from "../../media/Information.png";
import getCustomer from "../../fetches/getCustomer";
import APIErrorAlert from "../../errorpage/APIErrorAlert";
import getCspointsByOffer from "../../fetches/getCspointsByOffer";
import modifyCustomer from "../../fetches/modifyCustomer";
import modifyConsumptionPoint2 from "../../fetches/modifyConsumptionPoint2";
import modifyCustomer2 from "../../fetches/modifyCustomer2";
import getContract from "../../fetches/getContract";
import generateContract from "../../fetches/generateContract";
import Footer from "../../Footer";
import Header from "../../Header";

export default class ConsumptionPointsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayInvoiceAdress: false,
            displayBankAccount: false,
            allConsumptionPoints: [],
            company: '',
            streets: [],
            postalCodes: [],
            citys: [],
            houseNumbers: [],
            currentFocus: -1,
            offerID: null,
            customerID: null,
            postalCode: "",
            showConsumptionpointsInfo: "testHide",
            showLoadingAnimation: "",
            defaultPaymentMethodOne: true,
            defaultPaymentMethodTwo: false,
            customerData: ''
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.swapIcons = this.swapIcons.bind(this);
        this.revertSwapIcons = this.revertSwapIcons.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleChangePostalCode = this.handleChangePostalCode.bind(this);
        this.createStreetAutocompleteBox = this.createStreetAutocompleteBox.bind(this);
        //Personal Data Form functions
        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.personalDataFormChange = this.personalDataFormChange.bind(this);
        this.setDynamicState = this.setDynamicState.bind(this);
        this.setDynamicStreets = this.setDynamicStreets.bind(this);
        this.displayInfo = this.displayInfo.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);

        //GET ALL SUPPLIERS FOR PREPROVIDER AUTOCOMPLETE
        getAllSuppliers().then(res => {
            this.setState({
                suppliers: res
            });
        });
    }

    componentDidMount() {
        $(".radioPayment").on("change", function () {
            let inputBtns = $("input[type=radio]");
            let loadingAnimation = $("#payment-loading-circle");
            loadingAnimation.removeClass("d-none");
            inputBtns.attr('disabled', true);
            var form = $('#paymentMethodForm');
            form.slideToggle();
            setTimeout(function() {
                inputBtns.attr('disabled', false);
                loadingAnimation.addClass("d-none");
            }, 500)
        });

        getOfferByToken().then(firstResult => {
            this.setState({
                offerID: firstResult.result.id,
                customerID: firstResult.result.customer_id,
            });
            getCustomer(firstResult.result.customer_id).then((secondResult) => {
                if(secondResult !== "ERR"){
                    //Get LoadProfile ID's add it to the Consumption Point Data from prev fetch
                    let customerInformation = secondResult.customer_information;

                    //If values exist, autofill them instantly
                    if (customerInformation.hasOwnProperty("billing_address")) {
                        var billingAddress = customerInformation.billing_address;

                        //Preset Vetragspartner form with values
                        getCities(billingAddress.zip).then(res => {
                            var arrayCities =  createCitySelectOptions(res);
                            this.setState(prevState => {
                                let city = Object.assign({}, prevState.city); // creating copy of state variable jasper
                                city[0] = arrayCities; // update the name property, assign a new value
                                return {city}; // return new object jasper object
                            });
                        });

                        document.getElementById('company0').value = billingAddress.company;
                        document.getElementById('firstname0').value = billingAddress.first_name;
                        document.getElementById('lastname0').value = billingAddress.last_name;
                        document.getElementById('postalCode0').value = billingAddress.zip;
                        document.getElementById('city0').value = billingAddress.city;
                        document.getElementById('street0').value = billingAddress.line1;
                        document.getElementById('houseNumber0').value = billingAddress.line2;
                    }
                }else{
                    this.apiErrorOccured('getCustomer', secondResult);
                }
            });


            let allConsumptionPoints = [];
            let streets = [];
            let postalCode = [];
            let houseNumber = [];
            let city = [];

            //Get Consumption Points without their LoadProfile ID's
            getCspointsByOffer(firstResult.result.id).then((result) => {
                    //Get LoadProfile ID's add it to the Consumption Point Data from prev fetch
                result.map(single => {
                    allConsumptionPoints.push(single);
                    if (streets.indexOf(single.street) === -1) streets.push(single.street);
                    if (city.indexOf(single.city) === -1) city.push(single.city);
                    if (houseNumber.indexOf(single.house_number) === -1) houseNumber.push(single.house_number);
                    if (postalCode.indexOf(single.postal_code) === -1) postalCode.push(single.postal_code);
                    if (single.invoice_address != null){  //check if invoice address exists
                        // fill invoice address city select field with options
                        getCities(single.invoice_address.postal_code).then(cityRes => {
                            if (typeof cityRes !== "undefined"){
                                var arrayCities =  createCitySelectOptions(cityRes);
                                this.setState(prevState => {
                                    let city = Object.assign({}, prevState.city); // creating copy of state variable jasper
                                    city[single.id] = arrayCities; // update the name property, assign a new value
                                    return {city}; // return new object jasper object
                                });
                            }
                        });
                    }
                    var that = this;
                    setTimeout(function () {
                        document.getElementById("submitBtn").disabled = false;
                        that.setState({showLoadingAnimation: 'd-none', showConsumptionpointsInfo: ""});
                    }, 4000);

                    //expected return
                    return 0;
                });
            });

            this.setState({streets: streets});
            this.setState({citys: city});
            this.setState({houseNumbers: houseNumber});
            this.setState({postalCodes: postalCode});
            this.setState({allConsumptionPoints: allConsumptionPoints});
        });
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    setDynamicState(arrayCities, state, id){
        this.setState(prevState => {
            let city = Object.assign({}, prevState.city);  // creating copy of state variable jasper
            city[id] = arrayCities;                     // update the name property, assign a new value
            let csPointState = Object.assign({}, prevState.csPointState);  // creating copy of state variable jasper
            csPointState[id] = state;
            return { csPointState, city};                                 // return new object jasper object
        });
    }

    setDynamicStreets(streets, id){
        this.setState(prevState => {
            let streetsArray = Object.assign({}, prevState.streetsArray);  // creating copy of state variable jasper
            streetsArray[id] = streets;                     // update the name property, assign a new value
            return { streetsArray };                                 // return new object jasper object
        })
    }

    //GET CITY AND STREETS FOR POSTALCODE INSERT
    handleChangePostalCode(e) {
        var postalCode =  e.target;
        var id = postalCode.dataset.value;
        var postalCodeVal = postalCode.value;
        var street = document.getElementById('street'+id);
        var houseNumber = document.getElementById('houseNumber'+id);
        var city = document.getElementById("city"+id);
        var loadingSpinner = document.getElementById("loadingSpinner"+id);
        document.getElementById('submitBtn').disabled = false;

        this.setState({
            cities: <option selected data-state={"BY"}/>
        });
        //On postal code length 5, fetch cities and streets, else delete every character after 5 length

        if(postalCodeVal.length <= 5){
            this.setState({
                postalCode: postalCodeVal,
            });
        }

        //disable street and housenumber if postalcode is wrong
        if (postalCodeVal.length  < 5){
            street.disabled = true;
            houseNumber.disabled = true;
        }else if(postalCodeVal.length > 5){
            postalCode.value = this.state.postalCode;
        }else if(postalCodeVal.length === 5) {
            var errorField = $("#invoiceAddressError-" + id);
            var errorFieldSpan = $("#invoiceAddressErrorSpan-" + id);
            var errorText = errorFieldSpan.html();
            loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x";

            getCities(postalCodeVal).then(citiesRes => {
                if (citiesRes.result === "[]") {
                    //no PLZ found
                    setTimeout(function () {
                        loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                        postalCode.classList.add("alert-border");
                        if (!errorText.includes('<br> Diese Postleitzahl existiert nicht')) {
                            errorFieldSpan.html(errorText + '<br/> Diese Postleitzahl existiert nicht');
                        }
                        errorField.slideDown();
                    }, 1000);
                } else {
                    //close all lists because the city got updated
                    this.closeAllLists(street);
                    errorText = errorText.replace('<br> Diese Postleitzahl existiert nicht', '');
                    if (errorText === '') {
                        errorField.slideUp();
                    }
                    //error handling
                    errorFieldSpan.html(errorText);
                    postalCode.classList.remove("alert-border");

                    //options for city selectfield
                    var cityOptions = createCitySelectOptions(citiesRes);
                    //set the state for the input field (via cpID)
                    this.setDynamicState(cityOptions, citiesRes[0].state, id);

                    //get streets for plz and city
                    getStreets(postalCodeVal, citiesRes[0].city).then(streetsRes => {
                        if(streetsRes !== "ERR"){
                            if (streetsRes === "[]") {
                                this.apiErrorOccured('getStreets', 'empty streets')
                            } else {
                                street.value = "";
                                var streets = [];
                                for (var i = 0; i < streetsRes.length; i++) {  //set new Vlaues for state Street array
                                    if (streets.indexOf(streetsRes[i].streetName) === -1) {
                                        streets.push(streetsRes[i].streetName);
                                    }
                                }
                                this.setDynamicStreets(streets, id);
                                streets = []; //set array empty
                                setTimeout(function () {
                                    city.classList.remove("d-none");
                                    loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                                    if (city) {
                                        street.removeAttribute('disabled');
                                        houseNumber.removeAttribute('disabled');
                                    }
                                }, 1000);
                            }
                        }else{
                            this.apiErrorOccured('getStreets', streetsRes);
                        }
                    });
                }
            });
        }else city.classList.add("d-none");
    }

    submitPersonalData(){
        var company = document.getElementById('company0').value;
        var firstname = document.getElementById('firstname0').value;
        var lastname = document.getElementById('lastname0').value;
        var postal_code = document.getElementById('postalCode0').value;
        var city = document.getElementById('city0').value;
        var street = document.getElementById('street0').value;
        var house_number = document.getElementById('houseNumber0').value;
        //Quickfix fields
        var companyField = $('#company0');
        var firstnameField = $('#firstname0');
        var lastnameField = $('#lastname0');
        var postal_codeField = $('#postalCode0');
        var cityField = $('#city0');
        var streetField = $('#street0');
        var house_numberField = $('#houseNumber0');

        var errorText = "";
        var error = 0;
        var alertPersonalDataEdit = $("#invoiceAddressError");
        var errorSpan =  $("#invoiceAddressErrorSpan");
        var firstErrorField = '';

        if (company.length === 0){
            errorText = errorText + "<br> Firmenname darf nicht leer sein.";
            companyField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = companyField;
            }
        }else if (company.length <= 2){
            errorText = errorText + "<br> Firmenname muss mindestens 2 Ziffern enthalten.";
            companyField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = companyField;
            }
        }

        if (firstname.length === 0){
            errorText = errorText + "<br> Vorname darf nicht leer sein.";
            firstnameField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = firstnameField;
            }
        }else if (firstname.length === 1){
            errorText = errorText + "<br> Bitte gültigen Vornamen angeben.";
            firstnameField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = firstnameField;
            }
        }

        if (lastname.length === 0){
            errorText = errorText + "<br> Nachname darf nicht leer sein.";
            lastnameField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = lastnameField;
            }
        }else if (lastname.length === 1){
            errorText = errorText + "<br> Bitte gültigen Nachnamen angeben.";
            lastnameField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = lastnameField;
            }
        }

        if (postal_code.length === 0){
            errorText = errorText + "<br> Postleitzahl darf nicht leer sein.";
            postal_codeField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = postal_codeField;
            }
        }else if (postal_code.length !== 5){
            errorText = errorText + "<br> Bitte gültige PLZ angeben (5 Ziffern).";
            postal_codeField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = postal_codeField;
            }
        }

        if (city.length === 0){
            errorText = errorText + "<br> Stadt darf nicht leer sein.";
            cityField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = cityField;
            }
        }else if (city.length === 1){
            errorText = errorText + "<br> Bitte gültige Stadt angeben.";
            cityField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = cityField;
            }
        }

        if (street.length === 0){
            errorText = errorText + "<br> Strasse darf nicht leer sein.";
            streetField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = streetField;
            }
        }else if (street.length === 1){
            errorText = errorText + "<br> Bitte gültige Strasse angeben.";
            streetField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = streetField;
            }
        }

        if (house_number.length === 0){
            errorText = errorText + "<br> Hausnummer darf nicht leer sein.";
            house_numberField.addClass('alert-border');
            if(firstErrorField === ''){
                firstErrorField = house_numberField;
            }
        }

        if (errorText.length > 0) error = 1;
        if (error === 1){
            //show Alert
            errorSpan.html(errorText);
            alertPersonalDataEdit.slideDown();
            return [1, firstErrorField];
        }else{
            //-> no error -> do fetch
            alertPersonalDataEdit.slideUp();

            return [
                company,
                firstname,
                lastname,
                postal_code,
                city,
                street,
                house_number
            ];
        }
    }

    hideErrorMessage(fieldID){
        var element = $('#'+fieldID);
        element.slideToggle();
    }

    swapIcons(e1, e2){
        var elementOne = $('#'+e1);
        var elementTwo = $('#'+e2);
        elementOne.hide();
        elementTwo.show();
    }

    revertSwapIcons(e1, e2){
        var elementOne = $('#'+e1);
        var elementTwo = $('#'+e2);
        elementTwo.hide();
        elementOne.show();
    }

    validateMaloIDFocus = (e) => {
        var maloField = e.target;
        var id = maloField.dataset.value;
        var errorMalo = document.getElementById('errorMalo-' + id);
        var successMalo = document.getElementById('successMalo-' + id);
        var maloVal = maloField.value;
        if (maloVal.length === 0){

        }
        else if (maloVal.length < 11) {
            successMalo.classList.add('d-none');
            errorMalo.classList.remove('d-none');
            maloField.classList.add('alert-border');
        }
    };

    handleStreetChange(e){
        if(e.target.classList.contains('alert-border')){
            e.target.classList.remove('alert-border');
        }

        this.createStreetAutocompleteBox(e.target, this.state.streetsArray);
    }

    handleCheckboxChange(id, name){
        var checkbox = document.getElementById(name + id);
        var formName = checkbox.dataset.target;
        document.getElementById('submitBtn').disabled = false;

        var form = $("#" + formName +  id);
        if (!form.is(':animated')){
            if (checkbox.classList.contains("o-layout-1")) toggleIcon(checkbox, "o-layout-1", 'o-checkbox-checked-square-1');
            else toggleIcon(checkbox, 'o-checkbox-checked-square-1',"o-layout-1");
            form.slideToggle();
        }
    };

    handleSubmit(event){
        event.preventDefault();

        //disable submit Btn
        var submitBtn =  document.getElementById('submitBtn');
        submitBtn.disabled = true;

        //Show Spinner
        var errorElements =  document.getElementsByClassName('alert-border');
        errorElements = Array.from(errorElements);
        if(errorElements.length > 0){
            Array.from(errorElements).map((field, index) => {
                field.classList.remove('alert-border');
                return 0;
            });
        }
        var error = 0;
        var firstErrorField = '';
        //Funktion die nach oben Scrollt im Fall von "error === 1"

        var loadingSpinnerSubmit =  document.getElementById('loadingLoadProfileSpinnerIcon');
        loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x";

        //Payment Methods
        // var paymentMethod2 = document.getElementById('paymentMethod-2');
        var paymentMethod = 1;
        // var bankOwner = "";
        // var iban = "";
        // var bankOwnerInp = document.getElementById('kontoinhaber');
        // var ibanInp = document.getElementById('iban');

        // if(paymentMethod2.checked){
        //     paymentMethod = 2;
        //     var errorField = $("#bankAccountError");
        //     var errorFieldSpan = $("#bankAccountErrorSpan");
        //     var errorText = "";
        //     if (bankOwnerInp.value.length < 2){
        //         errorText = "Bitte geben Sie einen Kontoinhaber an.";
        //         bankOwnerInp.classList.add('alert-border');
        //         error = 1;
        //     }
        //     if (ibanInp.value.length !== 22){
        //         if (errorText.length > 0) errorText = errorText + "<br/> Bitte geben Sie eine gültige IBAN (22 Zeichen) an.";
        //         else errorText = "Bitte geben Sie eine gültige IBAN (22 Zeichen) an.";
        //         error = 1;
        //         ibanInp.classList.add('alert-border');
        //     }
        //     if (error === 1){
        //         errorField.slideDown();
        //         if (firstErrorField === "")  firstErrorField = errorField;
        //         errorFieldSpan.html(errorText);
        //     }else{
        //         errorField.slideUp();
        //     }
        // }

        var checkIfEmpty = [];

        this.state.allConsumptionPoints.map((csPoint, index) => {
            checkIfEmpty = [];
            let maloID = document.getElementById('maloID-' + csPoint.id);
            var successMaloID = document.getElementById('successMalo-' + csPoint.id);
            var maloError = $('#maloIdError-' + csPoint.id);

            //no check if malo is empty
           // checkIfEmpty.push(maloID);
            if (maloID.value.length > 0){
                validateMaloId(csPoint.id);
                if(successMaloID.classList.contains('d-none')){
                    maloID.classList.add('alert-border');
                    if (firstErrorField === "")  firstErrorField = maloError;
                    maloError.slideDown();
                    error = 1;
                    loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x";
                }else{
                    maloError.slideUp();
                    maloID.classList.remove('alert-border');
                }
            }

            // VALIDATION
            //Pre Provider
            var preProviderCheckbox = document.getElementById('preProvider-' + csPoint.id);
            var customerNumberInp = document.getElementById('customerNumber-' + csPoint.id);
            var preProviderNameInp = document.getElementById('preProviderName-' + csPoint.id);
            var preProviderName = "";
            var customerNumber = "";


            if(preProviderCheckbox.classList.contains('o-checkbox-checked-square-1')){
                checkIfEmpty.push(customerNumberInp, preProviderNameInp);
                preProviderName = preProviderNameInp.value;
                customerNumber = customerNumberInp.value;
            }

            //Invoice Address
            var company = document.getElementById('company'  +csPoint.id);
            var postalCode = document.getElementById('postalCode' +csPoint.id);
            var city = document.getElementById('city' +csPoint.id);
            var street = document.getElementById('street' +csPoint.id);
            var houseNumber = document.getElementById('houseNumber' +csPoint.id);
            var companyVal = "";
            var cityVal = "";
            var streetVal = "";
            var houseNumberVal = "";
            var postalCodeVal = "";
            var stateVal = "";

            var invoiceAddressCheckbox = document.getElementById('invoiceAdress-' + csPoint.id);
            if(invoiceAddressCheckbox.classList.contains('o-checkbox-checked-square-1')){
                checkIfEmpty.push(company, postalCode, street, houseNumber);
                companyVal = company.value;
                postalCodeVal = postalCode.value;
                cityVal = city.value;
                streetVal = street.value;
                houseNumberVal = houseNumber.value;
                if (typeof this.state.csPointState !== "undefined"){
                    if (typeof this.state.csPointState[csPoint.id] !== "undefined") stateVal = this.state.csPointState[csPoint.id];
                }
            }

            //set bankaccount if isset
            // if (paymentMethod === 2){
                // bankOwner = bankOwnerInp.value;
                // iban = ibanInp.value;
            // }

            var errorText = [];
            checkIfEmpty.map((field, index) => {
                //invoiceAddressError
                var errorFieldInp =  field.dataset.errorfield;
                var errorField = $("#" + errorFieldInp + "Error-" + csPoint.id);
                var errorFieldSpan = $("#" + errorFieldInp + "ErrorSpan-" + csPoint.id);
                errorText = errorFieldSpan.html();

                if(field.value === ''){

                    if(errorText.length > 0 ){
                        if (!errorFieldSpan.html().includes(field.dataset.errortext))
                            errorFieldSpan.html(errorText + '<br/>' + field.dataset.errortext);
                    }else{
                        errorFieldSpan.html("<br/>" + field.dataset.errortext);
                    }
                    errorField.slideDown();
                    field.classList.add('alert-border');
                    loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                    error = 1;
                    if(firstErrorField === ''){
                        firstErrorField = errorField;
                    }
                }else{
                    if (errorFieldSpan.html().includes(field.dataset.errortext)){
                        var  newErrorText = errorFieldSpan.html().replace("<br>" + field.dataset.errortext, "");

                        if (newErrorText.length === 0)  errorField.slideUp();
                        else errorFieldSpan.html(newErrorText);
                    }
                }

                return error;
            });

            //save counsumptionPoint infos
            if (error !== 1){
                modifyConsumptionPoint2(csPoint.id, this.state.customerID, this.state.offerID, companyVal, cityVal,
                postalCodeVal, streetVal, houseNumberVal, stateVal, customerNumber, preProviderName, maloID.value).then((result) => {
                    if(result !== "ERR"){
                        modifyCustomer2(this.state.customerID, this.state.offerID, paymentMethod).then((result) => {
                            if(result !== "ERR"){
                                //Submit Personal Data form values
                                var personalDataError = this.submitPersonalData();

                                if(typeof personalDataError !== 'undefined'){
                                    if(personalDataError[0] === 1){
                                        error = 1;
                                        firstErrorField = personalDataError[1];
                                    }

                                    if(error === 1){
                                        //firstErrorField.scrollIntoView({behavior: 'smooth'});
                                        $('html,body').animate({
                                            scrollTop: firstErrorField.offset().top
                                        }, 'slow');
                                        setTimeout(function() {
                                            submitBtn.disabled = false;
                                            loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                                        }, 2500);
                                    }else{
                                        /*
                                            params for modidyCustomer
                                            customerID, company => 0, firstname => 1, lastname => 2, postal_code => 3, city => 4, street => 5, house_number => 6
                                         */
                                        modifyCustomer(this.state.customerID, personalDataError[0], personalDataError[1] , personalDataError[2], personalDataError[3], personalDataError[4], personalDataError[5], personalDataError[6]).then(result => {
                                            var loadingSpinnerSubmit =  document.getElementById('loadingLoadProfileSpinnerIcon');

                                            if(result !== "ERR"){

                                                //get contract Data for the contract ID
                                                getContract(this.state.customerID, this.state.offerID).then((contractResult) => {
                                                    if (contractResult !== 'ERR') {
                                                        //generate the contract we want to display the customer on the next page (with contract ID from contractGet)
                                                        generateContract(this.state.customerID, contractResult.id).then((contractResult) => {
                                                            if (contractResult !== 'ERR') {
                                                                setTimeout(function() {
                                                                    loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                                                                    window.location.href = global.ContractAddFinishedPage + '/' + this.props.match.params.key;
                                                                }.bind(this), 2500);
                                                            }else{
                                                                /* error while contract was generated -> redirect with parameter set so the contract will be not displayed later on */
                                                                window.location.href = global.ContractAddFinishedPage + '/' + this.props.match.params.key + "/?x=1";
                                                                //this.apiErrorOccured('generateContract');
                                                            }
                                                        });
                                                    } else {
                                                        this.apiErrorOccured('getContract', contractResult);
                                                    }
                                                });




                                            }else{
                                                this.apiErrorOccured('modifyCustomer', result);
                                                loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                                            }
                                        });








                                    }
                                }
                            }else{
                                error = 1;
                                submitBtn.disabled = false;
                                this.apiErrorOccured('modifyCustomer2', result, loadingSpinnerSubmit);
                            }
                        });
                    }else{
                        submitBtn.disabled = false;
                        error = 1;
                        this.apiErrorOccured('modifyConsumptionPoint2', result, loadingSpinnerSubmit);
                    }
                });
            }

            return 0;
        });


    }

    createStreetAutocompleteBox(inp, arr){
        var test_string = function(a, b)   {
            a = a.split('');
            b = b.split('');
            for(var i = 0; i < b.length; i++)   {
                if($.isNumeric(a[i]) && $.isNumeric(b[i]))    {
                    if(a[i] !== b[i])
                        return false;
                } else {
                    if(typeof a[i] !== 'undefined'){
                        if($.isNumeric(a[i]) || $.isNumeric(b[i]))
                            return false;
                        if(a[i].toUpperCase() !== b[i].toUpperCase())
                            return false;
                    } else return false;
                }
            }
            return true;
        };

        /*the autocomplete function takes two arguments,
                                the text field element and an array of possible autocompleted values:*/

        /*execute a function when someone writes in the text field:*/
        var a, b, i, val = inp.value;

        this.closeAllLists(inp);

        if (!val) {
            return false;
        }

        var count = 0;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", inp.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        inp.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            arr[i] = arr[i] + "";
            /*check if the item starts with the same letters as the text field value:*/
            if (test_string(arr[i], val)) {
                count++;
                if(count === 8) break;
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                b.innerHTML += arr[i].substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener("click", function (e) {

                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;

                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    // closeAllLists();

                    //close all lists
                    var x = document.getElementsByClassName("autocomplete-items");
                    for (var i = 0; i < x.length; i++) {
                        if (inp !== x[i] && inp !== inp.value) {
                            x[i].parentNode.removeChild(x[i]);
                        }
                    }
                });
                a.appendChild(b);
            }
        }
    }

    handleChangeInput = (e) => {
        let inp = e.target;
        var cpId = inp.dataset.id;
        if(inp.classList.contains('alert-border')){
            inp.classList.remove('alert-border');
        }

        document.getElementById('submitBtn').disabled = false;

        if (inp.classList.contains('autofill')){
            switch (inp.dataset.value) {
                case "streets":
                    if(typeof this.state.streetsArray === 'undefined' || typeof this.state.streetsArray[cpId] === 'undefined'){
                        var postalCodeValue = document.getElementById('postalCode'+cpId).value;
                        var cityValue = document.getElementById('city'+cpId).value;
                        getStreetsArray(postalCodeValue, cityValue).then(res =>{
                            this.setState({streetsArray: {[cpId]: res}});
                            this.createStreetAutocompleteBox(inp, res);
                        });
                    }else{
                        this.createStreetAutocompleteBox(inp, this.state.streetsArray[cpId]);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    handleKeydown = (e) =>{
        var x = document.getElementById(e.target.id + "autocomplete-list");
        var currentFocus = this.state.currentFocus;
        if (x) x = x.getElementsByTagName("div");
        if (e.key === 'ArrowDown') {
            currentFocus++;
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (e.key === 'ArrowUp') { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (e.key === 'Enter') {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                var div = x[currentFocus];
                e.target.value = div.getElementsByTagName('input')[0].value;
                this.closeAllLists(e.target);
            }
        }
        this.setState({currentFocus: currentFocus});
    };

    addActive(x, currentFocus) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        this.removeActive(x);
        var arr = Array.from(x);
        if (currentFocus >= arr.length){
            currentFocus = 0;
        } else if (currentFocus < 0) {
            currentFocus = 0;
        }
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
        return currentFocus;
    }

    removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    closeAllLists(inp){
        /*close any already open lists of autocompleted values*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (inp !== x[i] && inp !== inp.value) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    RefreshClick() {
        refreshOffer()
    }

    personalDataFormChange(e){
        let element = e.target;
        if(element.classList.contains('alert-border')){
            element.classList.remove('alert-border');
        }
    }

    displayInfo (e) {
        var arr = e.target.id.split('-');
        var elementName = arr[0];
        var infoBox = $('#' + elementName + '-info');

        if (infoBox.is(":visible")){
            infoBox.fadeOut();
        }else{
            infoBox.fadeIn();
        }
    }

    apiErrorOccured(fetchName, result, loadingSpinnerSubmit = ''){
        this.setState({apiError: true});
        console.log(fetchName, result);
        if(loadingSpinnerSubmit !== ''){
            loadingSpinnerSubmit.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
        }
    }

    render() {
        let allAdress = [];
        var that = this;
        var customerData = this.state.customerData;
        var radios;

        if(this.state.defaultPaymentMethodTwo === true){
            radios = <div>
                <label className="control control-radio radioPayment">
                    Überweisung
                    <input name='paymentMethod' data-value={1}  id='paymentMethod-1' className='radio paymentMethod' type='radio' value={'1'}/>
                    <div className="control_indicator" />
                </label>

                <label className="control control-radio radioPayment d-none">
                    Firmen-Lastschrift
                    <input name='paymentMethod' data-value={2} id='paymentMethod-2' disabled={true}  className='radio paymentMethod' type='radio' defaultChecked value={'2'}/>
                    <div className="control_indicator" />
                </label>
            </div>;

            var form = $('#paymentMethodForm');
            if(!form.is(":visible")){
                let inputBtns = $("input[type=radio]");
                inputBtns.attr('disabled', true);
                form.slideToggle();
                setTimeout(function() {
                    inputBtns.attr('disabled', false);
                }, 500)
            }
        }else{
            radios = <div>
                <label className="control control-radio radioPayment">
                    Überweisung
                    <input name='paymentMethod' data-value={1}  id='paymentMethod-1' className='radio paymentMethod' type='radio' defaultChecked value={'1'}/>
                    <div className="control_indicator" />
                </label>

                <label className="control control-radio radioPayment d-none">
                    Firmen-Lastschrift
                    <input name='paymentMethod' data-value={2} id='paymentMethod-2' disabled={true} className='radio paymentMethod' type='radio' value={'2'}/>
                    <div className="control_indicator" />
                </label>
            </div>
        }

        function hasPreProvider(key) {
            var checkboxClass = "o-layout-1";
            var formClass = "hide-form";

            //contract will be empty when we get it from fetch for the first time
            if (key.extended_information.hasOwnProperty('contract_cancellation')){

            }else{
                key.extended_information.contract_cancellation = {internal_customer_id: "", supplier_name: ""};
            }
            if (key.extended_information.contract_cancellation.internal_customer_id === "" && key.extended_information.contract_cancellation.supplier_name === "") {
            }else{
                checkboxClass = "o-checkbox-checked-square-1";
                formClass = '';
            }
            return <div className={"row mr-0 ml-0 mt-3"}>
                <div className='col-md-12 col-lg-12 col-sm-12 different-deliver-adress-radio-style control-group p-0'>
                    <div className='control-group'>

                        <label className="mb-0 eless-checkbox" onClick={() => that.handleCheckboxChange(key.id, "preProvider-")}>
                            <span id={'preProvider-'+key.id}  data-target={"preProviderForm-"} className={''+checkboxClass} /> Altversorger kündigen
                        </label>
                    </div>

                    <div id={'preProviderForm-'+key.id} className={"mt-2 "+formClass}>
                        <Alert id={'preProviderError-'+key.id} className={'eless-dynamic-error'} onClose={() => that.hideErrorMessage('preProviderError-'+key.id)} >
                            <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                            <span id={"preProviderErrorSpan-" +key.id}/>
                        </Alert>

                        <div className={"col-md-12 col-sm-12 col-lg-12 pr-0 pl-0"}>
                            <input id={'customerNumber-'+key.id} type={'text'}  placeholder={'Kundennummer'} data-errorfield={'preProvider'} data-errortext={'Bitte geben Sie Ihre Kundennummer an.'} defaultValue={key.extended_information.contract_cancellation.internal_customer_id} onChange={that.handleChangeInput} />
                        </div>

                        <div className={"col-md-12 col-sm-12 col-lg-12 pr-0 pl-0"}>
                            <input id={'preProviderName-'+key.id} type={'text'} data-value={"suppliers"} data-errorfield={'preProvider'} data-errortext={'Bitte geben Sie den Namen des Altversorgers an.'} autoComplete="new-password" placeholder={'Name Altversorger'} defaultValue={key.extended_information.contract_cancellation.supplier_name} onChange={that.handleChangeInput} />
                        </div>
                    </div>
                </div>
            </div>
        }

        function showInvoiceAdressForm(key) {
            var invoiceAdressForm = $("#invoiceAdressForm-" +key.id);
            if(key.invoice_address.city !== '' && key.invoice_address.city !== null){
                invoiceAdressForm.show();
            }

        }

        function hasInvoiceAddress(key) {
            var checkboxClass = "o-layout-1";
            var formClass = "hide-form";
            var disabled = true;

            if (key.invoice_address === null) {
                key.invoice_address = {
                    city: "",
                    company: "",
                    email: "",
                    first_name: "",
                    last_name: "",
                    postal_code: "",
                    house_number: "",
                    street: "",
                };
            }

            if(key.invoice_address.city !== null && key.invoice_address.city !== ""){
                checkboxClass = "o-checkbox-checked-square-1";
                disabled = false;
                formClass = '';
            }
            return <div className={"row ml-0 mr-0 mt-3 pt-0"}>
                <div className='col-md-12 col-lg-12 col-sm-12 different-deliver-adress-radio-style control-group p-0 mb-3'>
                    <div className='control-group'>
                        <label className="mb-0 eless-checkbox" onClick={() => that.handleCheckboxChange(key.id, "invoiceAdress-")}>
                            <span id={'invoiceAdress-'+key.id}  data-target={"invoiceAdressForm-"} className={checkboxClass} /> Abweichende Rechnungsadresse angeben
                        </label>
                    </div>

                    <div id={'invoiceAdressForm-' +key.id}  className={formClass}>
                        <div>
                            <Alert id={'invoiceAddressError-'+key.id} className={'eless-dynamic-error'} onClose={() => that.hideErrorMessage('invoiceAddressError-'+key.id)}>
                                <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                <span id={"invoiceAddressErrorSpan-" +key.id}/>
                            </Alert>
                            <input type={'text'}  id={'company'+key.id} placeholder={'Firma'} data-errorfield={'invoiceAddress'}
                                   data-errortext={'Geben Sie bitte den Firmennamen ein.'} autoComplete="new-password"
                                   defaultValue={key.invoice_address.company} onChange={that.handleChangeInput}/>
                            <div className={'col-md-3 col-sm-12 col-lg-3 p-0 input-spinner d-inline-block'}>
                                <input type='number' id={'postalCode' + key.id} maxLength="5" data-errorfield={'invoiceAddress'}  data-errortext={'Geben Sie bitte die Postleitzahl ein.'} data-value={key.id}
                                       defaultValue={key.invoice_address.postal_code} onChange={that.handleChangePostalCode}
                                       placeholder='PLZ' className={'postal-code'}/>
                                <FontAwesomeIcon className={'fa-spin fa-2x d-none'} id={"loadingSpinner" + key.id} icon={faSpinner}/>
                            </div>
                            <div className="col-md-1 hidden-mobile p-0 zipcity-separator"/>

                            <div className={"col-md-8 col-lg-8 col-sm-12 d-inline-block p-0"}>
                                <select id={"city"+ key.id} className={'city-select font-inherit'}>
                                    {handleCity(key.id)}
                                </select>
                            </div>

                            <div className={"col-md-9 autocomplete p-0 popup d-inline-block"}>
                                <input type='text' id={'street'+key.id} placeholder='Straße' data-value={"streets"} data-id={key.id}
                                       onKeyDown={that.handleKeydown}  data-errorfield={'invoiceAddress'} data-errortext={'Geben Sie bitte die Straße an.'} autoComplete="new-password" className={"autofill"}
                                       defaultValue={key.invoice_address.street} onChange={that.handleChangeInput} disabled={disabled}/>
                            </div>

                            <div className="col-md-1 hidden-mobile p-0 zipcity-separator"/>
                            <input id={'houseNumber'+key.id} autoComplete="new-password" className="col-md-2 popup d-inline-block" type='text' defaultValue={key.invoice_address.house_number}
                                   onChange={that.handleChangeInput} data-errorfield={'invoiceAddress'} data-errortext={'Geben Sie bitte die Hausnummer an.'} placeholder='Hausnr.'/>
                            <div className={'col-md-12 alert-text d-none popupErrorMessage'} id={'streetMessage'+key.id}>Geben Sie bitte eine Straße mit einer validen Hausnummer an.</div>
                        </div>
                    </div>
                </div>
            </div>
        }

        function handleCity(id) {
            if(typeof that.state.city !== 'undefined'){ // render city options into select input
                if(typeof that.state.city[id] !== 'undefined'){
                    if(typeof that.state.city[id][0].props.value !== 'undefined'){
                        return that.state.city[id];
                    }else{
                        return <option value={'Ort'}>Ort</option>;
                    }
                }else{
                    return <option value={'Ort'}>Ort</option>;
                }
            }else{ // render default city option into select input
                return <option value={'Ort'}>Ort</option>;
            }
        }

        //Hier
        var consumptionPointsInfo = <span/>;
        if (this.state.allConsumptionPoints !== []){
            consumptionPointsInfo =
                <div className={"row "+this.state.showConsumptionpointsInfo} >
                    {this.state.allConsumptionPoints.map((key, value) =>
                        <div key={value} className={'csPoint col-lg-12 col-md-12 col-sm-12 mb-5'}>
                            <h4>{value+1}. {key.street} {key.house_number}, {key.postal_code} {key.city} </h4>
                            <Alert id={'maloIdError-'+key.id} className={'eless-dynamic-error'} onClose={() => that.hideErrorMessage('maloIdError-'+key.id)}>
                                <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b> <br/>
                                    Bitte geben Sie eine valide MaloID ein. <br/>
                                    Sollten Sie diese nicht zur Hand haben fahren Sie bitte ohne Eingabe in diesem Feld fort und lassen Sie die MarktlokationsID beim Energieversorger nachtragen.
                                <span id={"maloIdErrorSpan-" +key.id}/>
                            </Alert>
                            <div className={'row'}>
                                <div className={'validable col-md-11 col-sm-11 col-11'}>
                                    <input id={'maloID-' +key.id}  type={'text'} data-value={key.id} onChange={() => validateMaloId(key.id)}
                                           defaultValue={key.extended_information.market_identifier} onBlur={this.validateMaloIDFocus}
                                           className={'mdWidthFontSize'} placeholder={'Marktlokations - ID (Kann nachgetragen werden)'}
                                    />
                                    <span id={'successMalo-'+key.id} className={'validationIcon d-none o-checked-circle-1'} />
                                    <span id={'errorMalo-'+key.id} className={'validationIcon d-none o-delete-circle-1'} />
                                </div>
                                <div className={'col-md-1 col-sm-1 col-1 maloid-info-wrapper'}>
                                    <img src={InfoIcon} onClick={this.displayInfo} alt='info-icon' id='maloid-info-icon' className='info-icon'/>
                                    <SpeechBubble id={'maloid-info'} content={'Die Marktlokations-ID (oder auch Malo-ID) finden Sie seit dem 01.02.2018 auf Ihrer Rechnung. Sie besteht aus 11 Ziffern und ist eine eindeutige Bezeichnung für Ihre Abnahmestelle.\n' +
                                    'Beispielhaft: 52984412357'} />
                                </div>
                            </div>
                            <span className={'d-none'}>{allAdress.push({id: key.id,street: key.street, houseNumber: key.house_number, city: key.city,postalCode: key.postal_code, company: this.state.company})}</span>
                            {hasPreProvider(key)}

                            {hasInvoiceAddress(key)}
                            {showInvoiceAdressForm(key)}
                        </div>
                    )}
                </div>;
        }
            return(
                <>
                    <Header />
                    <div className='max-width-content'>
                    <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                    <Breadcrumb active={3} match={this.props.match} />

                    <form className={'eless-form pb-8'} onSubmit={this.handleSubmit}>

                        <div id={"personalDataEditWrapper"} className={'eless-form mb-4'}>
                            <div  className={"row"}>
                                <div className={"col-lg-12"}>
                                    <div>
                                        <h2>Vertragspartner</h2>
                                    </div>
                                </div>

                                {/* Firma */}
                                <div className={"col-lg-12"}>
                                    <Alert id={'invoiceAddressError'} className={'eless-dynamic-error'} >
                                        <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                        <span id={"invoiceAddressErrorSpan"}/>
                                    </Alert>
                                    <input type={'text'}  defaultValue={customerData.company} onChange={(e) => this.personalDataFormChange(e)} id={'company0'} placeholder={'Firma'}/>
                                </div>

                                {/* Name */}
                                <div className={"col-lg-6 pr-0"}>
                                    <input defaultValue={customerData.firstname} onChange={(e) => this.personalDataFormChange(e)} type={'text'}  id={'firstname0'} placeholder={'Vorname'}/>
                                </div>
                                <div className={"col-lg-6"}>
                                    <input defaultValue={customerData.lastname} onChange={(e) => this.personalDataFormChange(e)} type={'text'}  id={'lastname0'} placeholder={'Name'}/>
                                </div>

                                {/* PLZ */}
                                <div className={"col-lg-6 input-spinner pr-0"}>
                                    <input  defaultValue={customerData.postalCode} data-value={0} onChange={this.handleChangePostalCode} className={'postal-code'} type={'text'}  id={'postalCode0'} placeholder={'PLZ'}/>
                                    <FontAwesomeIcon className={'fa-spin fa-2x d-none'} id={"loadingSpinner0"} icon={faSpinner}/>
                                </div>
                                <span id={'invoiceAddressErrorSpan-0'} />

                                {/* Ort */}
                                <div className={"col-lg-6"}>
                                    <select id={"city0"} className={'city-select city-select-popupbox'}>
                                        {handleCity(0)}
                                    </select>
                                </div>

                                {/* Strasse */}
                                <div className={"col-lg-6 pr-0"}>
                                    <input className={"autofill"} defaultValue={customerData.street} onKeyDown={this.handleKeydown} data-value={"streets"}  data-id={0} autoComplete={'new-password'} type={'text'} onChange={this.handleChangeInput} id={'street0'} placeholder={'Strasse'}/>
                                </div>

                                {/* Hausnummer */}
                                <div className={"col-lg-6"}>
                                    <input type={'text'}  defaultValue={customerData.houseNumber} onChange={(e) => this.personalDataFormChange(e)}  id={'houseNumber0'} placeholder={'Hausnummer'}/>
                                </div>

                            </div>
                        </div>

                        <div className={"row m-0"}>
                            <div className='col-lg-12 p-0'>
                                <h2>Zahlungsart</h2>
                            </div>
                        </div>

                        <div className={"row m-0"}>
                            <div className='col-md-12 col-lg-12 col-sm-12 different-deliver-adress-radio-style control-group p-0 mb-4'>
                                <div>
                                    {radios}
                                    <div id={'payment-loading-circle'} className="lds-ring d-none">
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </div>

                                <div id={'paymentMethodForm'}>
                                    <h4>Bankkonto</h4>
                                    <div className='col-md-12 col-lg-12 col-sm-12 p-0'>
                                        <Alert id={'bankAccountError'} className={'eless-dynamic-error'} onClose={() => that.hideErrorMessage('bankAccountError')}>
                                            <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b><br/>
                                            <span id={"bankAccountErrorSpan"} />
                                        </Alert>
                                    </div>
                                    <input className={"validate"} type={'text'} name={'kontoinhaber'} id={'kontoinhaber'} placeholder={'Kontoinhaber'} onChange={this.handleChangeInput}/>
                                    <input className={"validate"} type={'text'} name={'iban'} id={'iban'} placeholder={'IBAN'} onChange={this.handleChangeInput}/>
                                </div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-lg-12"}>
                                <h2 className={"mt-2"}>Lieferstellen</h2>
                            </div>
                        </div>

                        <LoadingAnimationDotted text={'Lieferstellen werden geladen'} hideclass={this.state.showLoadingAnimation} />
                            {consumptionPointsInfo}
                        <button type='submit' className='eless-weiter-btn' id='submitBtn' disabled={true}>
                            Weiter
                            <span id={'submitAnimation'}>
                                <FontAwesomeIcon className={'fa-spin fa-2x d-none'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/>
                            </span>
                        </button>
                    </form>
                    </div>
                    <Footer />
                </>);
    }
}