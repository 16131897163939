import React from 'react';
import getEnergyType from "../functions/getEnergyType";

/* Hier wird der Liefrzeitraum berechnet und gerendert */

export default class Energytype extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingDisplayNone: 'd-none'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        this.setState({loadingDisplayNone: ''});
        this.setState({loadingDisplayNone: 'd-none'});

        //callback to DurationAndConsumptionPoints
        this.props.callbackUpdateEnergyType(e.target.value);
    }

    static getDerivedStateFromProps(props, state){
        if(props.cspointsAreLoading){
           return({loadingDisplayNone: ''});
        }else{
            return({loadingDisplayNone: 'd-none'});
        }
    }

    render () {
        var energy = true;
        var gas = false;

        var mediumTypeParam = getEnergyType(); //check if we tracked last klick
        if (mediumTypeParam !== null){
            if (mediumTypeParam === "GAS") {
                energy = false;
                gas = true;
            }
        }else{
            if (this.props.energyType === "GAS") { //default
                energy = false;
                gas = true;
            }
        }



        return (
            <div id='energytype' className={'eless-form'}>
                <label className="control control-radio">
                    Strom
                    <input id='energy' data-value={1} className='radio lastType clickable-icon' type='radio' defaultChecked={energy} onClick={this.handleChange} name='energytype' disabled={this.props.cspointsAreLoading} value='ELECTRICITY'/>
                    <div className="control_indicator" />
                </label>
                <label className="control control-radio">
                    Gas
                    <input id='gas' data-value={0} className='radio lastType clickable-icon' type='radio' defaultChecked={gas} onClick={this.handleChange} name='energytype' disabled={this.props.cspointsAreLoading} value='GAS'/>
                    <div className="control_indicator" />
                </label>
                <div id={'energytype-loading'} className={"lds-ring "+this.state.loadingDisplayNone}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }
}