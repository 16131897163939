import "whatwg-fetch";

export default async function getCspointsByOffer(offerID) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionGetCspointsByOffer,
            param: {
                offer_id: offerID,
                type: "extended"
            }
        })
    }).then(res => res.json())
        .then(result => {
            if (result.status === "ok") {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(rejected => {
            console.log('Request failed', rejected);
        });
}