import calculateAllTariffs from "../fetches/calculateAllTariffs";
var calculated = false;

function getCountDown(res) {
    //set Date the offer will expire
    var offerID = res.result.id;
    var token = res.result.token;
    var checkDate = offerMustRefresh(res.result.expires_at);
    var updatedAt = createIeCompatibleTime(res.result.expires_at);
    var timeUpdated = new Date(updatedAt[0], updatedAt[1], updatedAt[2], updatedAt[3], updatedAt[4], updatedAt[5]);
    timeUpdated.setHours( timeUpdated.getHours() + 1 );
    var countDownDate = new Date(timeUpdated).getTime();
    var timeSpan = document.getElementById("time-span");
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now ;
    if (checkDate === true){
        if (timeSpan !== null)
            timeSpan.innerHTML = "<i>Ihre Angebote werden in Kürze aktualisiert</i>";
    }

    // Time calculations minutes
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    // Display the result in the element with id="demo"
    var time = document.getElementById("time");
    if (time !== null)
        time.innerHTML = minutes +" ";

    distance = countDownDate - now ;
    // Update the count down every 10 second
    var countDown = setInterval(function() {
        checkDate = offerMustRefresh(res.result.expires_at);
        now = new Date().getTime();

        // Find the distance between now and the count down date
        distance = countDownDate - now ;

        // Time calculations minutes
        minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        // seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is finished, write some text
        if (checkDate === true && calculated === false) {
            // timeSpan.innerHTML = "<i>Ihre Angebote werden in kürze aktualisiert</i>";
            clearInterval(countDown);
            refreshOffer();
            calculated = true;
        }else{
            // Display the result in the element with id="demo"
            time = document.getElementById("time");
            if (time !== null)
                time.innerHTML = minutes + " ";
        }
    }, 10000);

    function createIeCompatibleTime(uncompatibleTime){
        var splitted = uncompatibleTime.split(" ");
        var date = splitted[0].split("-");
        var time = splitted[1].split(":");
        var compatibleTime = [];
        compatibleTime[0] = date[0];
        compatibleTime[1] = date[1];
        compatibleTime[2] = date[2];
        compatibleTime[3] = time[0];
        compatibleTime[4] = time[1];
        compatibleTime[5] = time[2];
        return compatibleTime;
    }

    function refreshOffer(){
        calculateAllTariffs(offerID).then((result) => {
            if (result !== "ERR") {
                //on success
                window.location.href = global.TariffsPage + "/" + token;
            }else{
                //error
            }
        })
    }

    function offerMustRefresh(offerExpiry){
        var timeNow = new Date();
        var  valid_until = new Date(offerExpiry);
        valid_until.setHours( valid_until.getHours());
        if (valid_until < timeNow) return true;
        return false;
    }
}

export default getCountDown;