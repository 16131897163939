function loadingAnimationFirstSet(loadingBar) {
    let width = 1;
    let loadFirstSetOfPercent = global.loadingFirstSet;
    let triggerLoadFirst = setInterval(loadFirstSetPercent, global.loadingFirstSetInterval);
    return triggerLoadFirst;
    function loadFirstSetPercent() {
        // hold if width reaches a defined limit
        if (width >= loadFirstSetOfPercent) {
            //stop looping
            clearInterval(triggerLoadFirst);
        } else {
            loadingBar.style.backgroundColor = "#041133";
            if (width === 2){
                //set trasition for smooth loading animation (after width = 2 because it will look buggy on 1)
                loadingBar.style.transitionDuration = "1.2s";
            }
            //count width up and set value to the loading bar
            width++;
            //progressValue.textContent = width + "%";
            loadingBar.style.width = width + '%';
        }
    }
}

export default loadingAnimationFirstSet;
