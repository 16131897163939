
/*
    This function checks if the selected date is still within a valid range
    valid range is > 1 month in the future,
    since we give the energy provider at least 4 weeks to manage everything before the offer can start

    Also check if the status is not submitted or accepted
    submitted means that the offer was submitted by the customer and there is a binding contract between him and the provider
    he should not be able to submit twice with the same consumptionpoint(s)
    -> redirect to the consumptionPoint selection where if cps will be disabled after the submit

    The Error will be handled and displayed in ErrorHandlerCP.js
 */
function checkOffer(offerBegin, status = "checkout") {
    //check if offer Date is still valid
    var now = new Date();
    now.setMonth(now.getMonth() +1);
    now.setDate(now.getDate() - 1);
    offerBegin = new Date(offerBegin);
    //redirect with get parameter for error message
    if (now > offerBegin) window.location.href = global.HomeURL + "/?expired=1";

    //check if the offer status is valid
    //redirect if status is in the blacklist array
    var blacklist = [
        "submitted",
        "accepted"
    ];
    //redirect to consumptionpoint selection with get parameter for error message
    if (blacklist.includes(status)){
        window.location.href = global.HomeURL + "/?submitted=1";
    }
}
export default checkOffer;