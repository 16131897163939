import React from 'react';
import Header from "../Header";
import Footer from "../Footer";

class WrongUrlMessage extends React.Component {
    render() {
        return <>
                <Header />
                    <div className='max-width-content'>
                       <div><h2>Diese Seite existiert nicht</h2>Überprüfen Sie bitte ob der Link richtig kopiert/abgetippt wurde oder kontaktieren Sie uns unter 0821 89 99 98-0</div>
                    </div>
                <Footer />
            </>
    }
}

export default WrongUrlMessage;