import React from 'react';
import PieChart from "./PieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faCircle} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import handleFormatNumber from "../../../functions/handleFormatNumber";

class PriceInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            netfeeResult: '',
            piechart: <PieChart />,
            ['arrowUp'+this.props.tarifID]: <FontAwesomeIcon icon={faAngleUp} id={"inner-arrow-down" + this.props.tarifID} />,
            ['arrowDown'+this.props.tarifID]: '',
        };

        this.handleFormatNumber = this.handleFormatNumber.bind(this);
        this.handleInnerClick = this.handleInnerClick.bind(this);
        this.getPriceSum = this.getPriceSum.bind(this);
        this.getResultSum = this.getResultSum.bind(this);
        this.getDescriptionText = this.getDescriptionText.bind(this);
    }

    componentDidMount() {
        var priceRate = this.props.priceRate + '';
        if(priceRate.includes('ct/kWh')){
            priceRate = priceRate.split(" "); // Diese Zeile und die nachfolgende if hizugefügt wegen einem Bug mit Gas Energiepreis
            if(priceRate[0].length < 5){
                var kWhSplit = priceRate[0].split(',');
                if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 1) this.priceRate =  kWhSplit[0] + "," + kWhSplit[1] + "00 ct/kWh";
                else if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 2) this.priceRate =  kWhSplit[0] + "," + kWhSplit[1] + "0 ct/kWh";
            }else{
                this.priceRate = this.props.priceRate; // Original line
            }
        }else{
            this.priceRate = this.handleFormatNumber(this.props.priceRate, 3) + ' ct/kWh';
        }
        if (this.props.netFeeResults !== "N/A")this.calculateAndFormatNetFee(this.props.netFeeResults);
    }

    calculateAndFormatNetFee(netFeeResult){
        let arrayHeadline = [];
        let arrayNetFee = [];
        let arrayNetFeeYearly = [];
        let totalConsumption;
        let umlage;
        let umlageFormated;
        let formattedValue;
        let header;
        let sumNetFee = 0;
        let sumNetFeeInCt = 0;
        let count = 0;
        let translation_object;
        var energyType = 'ELECTRICITY';
        //If these are gas tariffs, send some information to Tariffinformation to display
        if('gas_quality' in netFeeResult){
            energyType = 'GAS';
            this.props.getGasValues(netFeeResult.gas_quality, netFeeResult.control_area);
        }

        for (var key in netFeeResult) {
            if(netFeeResult[key] === 0)
                continue;

            if(energyType === 'GAS')
                translation_object = global.arrayWithGasTranslations;
            else {
                translation_object = global.arrayWithElectricityTranslations;
            }

            if (translation_object.hasOwnProperty(key)) {
                let inner = translation_object[key];
                header = inner.name;
                let unit = inner.unit;

                sumNetFee = sumNetFee + netFeeResult[key];
                formattedValue = this.handleFormatNumber(netFeeResult[key], 2);

                if(unit === "EUR_PER_MONTH")    {
                    umlage = netFeeResult[key] / 12;
                    sumNetFeeInCt = sumNetFeeInCt + umlage/100;
                    umlageFormated = this.handleFormatNumber(umlage, 2);
                    arrayNetFee[count] = <td className={'text-right'}>{umlageFormated + " €/Monat"}</td>;
                }else if(unit === "EUR_PER_KW") {
                    umlage = netFeeResult[key] / netFeeResult.peak_load;
                    sumNetFeeInCt = sumNetFeeInCt + umlage/100;
                    umlageFormated = this.handleFormatNumber(umlage, 2);
                    arrayNetFee[count] = <td className={'text-right'}>{umlageFormated + " €/kW"}</td>;
                }else if(unit === "CT_PER_KWH") {
                    umlage = netFeeResult[key] * 100 / netFeeResult.mean_consumption;
                    sumNetFeeInCt = sumNetFeeInCt + umlage;
                    umlageFormated = this.handleFormatNumber(umlage, 3);
                    arrayNetFee[count] = <td className={'text-right'}>{umlageFormated + " ct/kWh"}</td>;
                }

                arrayHeadline[count] =  <th scope="row">{header}</th>;
                arrayNetFeeYearly[count] = <td className={'text-right'}>{formattedValue +" €/a"}</td>;
            }
            count++;
        }

        totalConsumption = netFeeResult.mean_consumption;

        let resSumNetFeeInCt;
        resSumNetFeeInCt = this.handleFormatNumber(parseFloat(sumNetFeeInCt), 3);

        //get price from netfee and energy price  (IN €)
        let sumEnergyPrice = this.props.yearlyPrice +"";
        sumEnergyPrice = sumEnergyPrice.replace(' €', '');
        sumEnergyPrice = sumEnergyPrice.replace(/\./g, "");
        sumEnergyPrice = sumEnergyPrice.replace(',', '.');
        sumEnergyPrice = parseFloat(sumEnergyPrice);

        //sum in €
        let resultSum = sumNetFee+ sumEnergyPrice;
        sumNetFeeInCt =  resultSum * 100 / totalConsumption;
        resultSum = this.handleFormatNumber(resultSum, 2);
        var resultSumInCt = this.handleFormatNumber(sumNetFeeInCt, 3);


        let sumNetFeeFormatted = this.handleFormatNumber(sumNetFee, 2);
        this.setState({
            isLoaded: true,
            netfeeResult: netFeeResult.result,
            headline: arrayHeadline,
            yearly: arrayNetFeeYearly,
            netfee: arrayNetFee,
            sumNetFeeFormatted: sumNetFeeFormatted,
            sumNetFee: sumNetFee,
            resultSum: resultSum,
            netFeeSumInCt: resSumNetFeeInCt,
            resultSumInCt: resultSumInCt,
            totalConsumption: handleFormatNumber(totalConsumption+"")
        });
    }

    handleFormatNumber(number, decimal){
        let stringValue;
        let intValue;
        let commaValue;
        let formattedValue;
        let valueLen;

        if(typeof number === 'string') {//Prüfen ob die zahl bereits richtig formatiert ist, wenn ja -> return
            if (number.includes('ct/kWh')) {
                return number
            }
        }

        stringValue = number.toFixed(decimal);             //Den Preis auf 2 / 3 Nachkommastellen formatieren.

        if(stringValue.includes('.')){
            valueLen = stringValue.length;
            // remove .xx / .xxx value from the number
            intValue = stringValue.substring(0, valueLen - (decimal +1));
            commaValue = stringValue.substring(stringValue.length - (decimal +1));
            commaValue = commaValue.replace('.', ',');
        }else{
            intValue = stringValue;
        }

        let str = intValue;
        // str = str.replace(" kWh", "");
        // str = str.replace(/[^0-9]/g, "");
        // str = str.replace(/[a-zA-Z]/g, "");
        // str = str.replace(/\./g, "");
        let str_a = [];
        let i = 0;


        while (str.length - i * 3 > 0) {
            i++;
            if (str.length - i * 3 > 0) {
                str_a.push(str.substr(-3 * i, 3));
            } else {
                str_a.push(str.substr(0, str.length - (i - 1) * 3));
            }
        }
        str = "";

        while (str_a.length > 0) {
            str += str_a.pop();
            if (str_a.length > 0)
                str += ".";
        }

        formattedValue = str + commaValue;

        return formattedValue;
    }

    handleInnerClick(tariffID){
        var dropdownElement = $('#netFeeBody'+tariffID);

        let spanElement = document.getElementById('moreDetailsInnerSpan'+tariffID);

        if(this.state['arrowUp'+this.props.tarifID] === ''){
            spanElement.innerHTML=  "<b>Mehr Informationen zu dem Netzentgelten und dem Energiepreis ausblenden </b>" ;
            this.setState({['arrowUp'+this.props.tarifID]: <FontAwesomeIcon icon={faAngleUp} />});
            this.setState({['arrowDown'+this.props.tarifID]: ''});
        }else{
            spanElement.innerHTML=  "<b>Mehr Informationen zu dem Netzentgelten und dem Energiepreis anzeigen </b>" ;
            this.setState({['arrowUp'+this.props.tarifID]: ''});
            this.setState({['arrowDown'+this.props.tarifID]: <FontAwesomeIcon icon={faAngleDown} />});
        }

        //dropdownElement.slideToggle().animate({"margin-bottom": "toggle"});
        dropdownElement.slideToggle();
    };

    getResultSum(resSum, resSumCt, netFeeSum, netFeeSumCt){
        if (this.props.spot === "SPOT"){
            return <tr>
                <th scope="row">Summe Gesamt</th>
                <td className={'text-right'}>{netFeeSum + " €"}</td>
                <td className={'text-right'}>{netFeeSumCt + " ct/kWh"}</td>
            </tr>
        } else{
            return  <tr>
                <th scope="row">Summe Gesamt</th>
                <td className={'text-right'}>{resSum + " €"}</td>
                <td className={'text-right'}>{resSumCt + " ct/kWh"}</td>
            </tr>
        }
    }

    getPriceSum(yearlyPrice, priceRate){
        if (this.props.spot === "SPOT"){
            return <tr>
                <th scope="row"><FontAwesomeIcon icon={faCircle}  className={"iconEnergySum"}/> Energiepreis</th>
                <td className={'text-right'}>Spotpreis</td>
                <td className={'text-right'}>Spotpreis</td>
            </tr>
        } else{
            return <tr>
                <th scope="row"><FontAwesomeIcon icon={faCircle}  className={"iconEnergySum"}/> Energiepreis</th>
                <td className={'text-right'}>{yearlyPrice}</td>
                <td className={'text-right'}>{priceRate}</td>
            </tr>
        }
    }

    getDescriptionText(){
        if (this.props.spot === 1) return "Als Alternative zu den Terminmärkten kann Strom auch direkt am Spotmarkt der EEX bezogen werden. Ihr individueller Verbrauch wird dann 1:1 mit dem stündlichen Spotpreis zum Lieferzeitpunkt abgerechnet. Für diesen Service verlangt der Energieversorger eine Servicegebühr, welche im Auftrag ausgewiesen ist. Sie sind als Kunde damit hochflexibel, profitieren von den Spotkonditionen und können jederzeit in andere Beschaffungsmodelle, sollte sich der Markt nicht nach Ihren Wünschen entwickeln."
        else return " Die Gesamtkosten setzten sich aus zwei Komponenten zusammen.\n" +
            "                            Die erste Komponente ist der Energiepreis, der je nach Anbieter variabel ausfällt.\n" +
            "                            Die genauen Werte können Sie unter \"Energiepreis\" einsehen. Die zweite Komponente sind Netztengelte,\n" +
            "                            Umlagen und Steuern, die für den Energiepreisvergleich aber nicht relevant sind,\n" +
            "                            da sie für alle Anbieter gleich hoch sind. Diese sehen Sie im Abschnitt \"Netzentgelte, Umlagen, Steuern\".";
    }

    getPiechart(){
        if (this.props.spot === "SPOT"){
            return (<div className={"row"}>
                <div className={"col-md-12 col-lg-12 col-sm-12"}>
                    <p>
                        {this.getDescriptionText()}
                    </p>
                </div>
            </div>);
        }else{
            return (<div className={"row"}>
                <div className={"col-md-3 col-lg-3 col-sm-12"}>
                    <PieChart spot={this.props.spot} yearlyPrice={this.props.yearlyPrice} sumNetFee={this.state.sumNetFee} />
                </div>
                <div className={"col-md-9 col-lg-9 col-sm-12"}>
                    <p>
                        {this.getDescriptionText()}
                    </p>
                </div>
            </div>);
        }
    }

    render() {
        let headline = this.state.headline;
        let yearly = this.state.yearly;
        let netfee = this.state.netfee;
        let tableNetFee;
        var sumNetFeeFormatted = this.state.sumNetFeeFormatted;
        var netFeeSumInCt = this.state.netFeeSumInCt;
        var yearlyPrice = this.props.yearlyPrice;
        var resSum = this.state.resultSum;
        var resSumCt = this.state.resultSumInCt;

        if(typeof headline !== 'undefined'){
            tableNetFee = headline.map(function(headline, count){
                    return <tr key={count}>
                        {headline}
                        {yearly[count]}
                        {netfee[count]}
                    </tr>
            });
        }

        const thStyle = {width: '25%'};
        var detailDiv = <div/>;
        if (this.props.mixedPrice === false) detailDiv =
            <div onClick={() => this.handleInnerClick(this.props.tarifID)}>
                <span id={'moreDetailsInnerSpan'+this.props.tarifID}><b>Mehr Informationen zu dem Netzentgelten und dem Energiepreis anzeigen </b></span>
                {this.state['arrowUp'+this.props.tarifID]}
                {this.state['arrowDown'+this.props.tarifID]}
        </div>;
        var content;
        if (this.props.netFeeResults === "N/A"){
            content = <div className={"col-md-12 col-lg-12 col-sm-12"}>
                <h4>Es tut uns leid, leider liegen unserem Dienstleister zu dieser Postleitzahl keine Preisbestandteile vor.</h4>
            </div>
        } else{
            content =  <div className={"col-md-12 col-lg-12 col-sm-12"}>
                <h4>Gesamtkosten für Energie & Netz</h4>
                {this.getPiechart()}
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Preisbestandteil</th>
                        <th scope="col" className={'text-right'} style={thStyle}>Betrag pro Jahr</th>
                        <th scope="col" className={'text-right'} style={thStyle}>Preis Bestandteil</th>
                    </tr>
                    </thead>
                    <tbody id={'netFeeBody'+this.props.tarifID} className={"netFeeBody"}>
                    {tableNetFee}
                    </tbody>
                    <tbody>
                    {this.getPriceSum(yearlyPrice, this.priceRate)}
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} className={"iconNetFee eless-orange-color"}/> Netzentgelte, Umlagen, Steuern</th>
                        <td className={'text-right'}>{sumNetFeeFormatted + " €"}</td>
                        <td className={'text-right'}>{netFeeSumInCt + " ct/kWh"}</td>
                    </tr>
                    {this.getResultSum(resSum,resSumCt,  sumNetFeeFormatted, netFeeSumInCt)}

                    <tr>
                        <td colSpan={3}>
                            <i>* Bei diesen Preisen sind die Jahrespreise auf einen Gesamtjahresverbrauch von {this.state.totalConsumption} kWh umgelegt.
                            Bei mehreren Abnahmestellen können die einzelnen Netzentgelte pro Abnahmestelle variieren.
                            Sämtliche Preise verstehen sich zzgl. ges. MwSt.</i>
                        </td>
                    </tr>
                    {detailDiv}
                    </tbody>
                </table>
             </div>
        }

        return(
            <div>
                {content}

            </div>

        );
    }
}

export default PriceInformation;