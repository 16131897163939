import "whatwg-fetch";
import checkCustomerID from "./checkMatchingCustomerID";
import checkOffer from "./checkOfferValidations";
async function getofferByToken() {
    // GET TOKEN

    var offer;
         // Token.
            let path = window.location.pathname.split("/");
            let params = {};
            for(let i = path.length-1; i >= 0; --i) {
                if(path[i].length === 10)  {
                    params.token = path[i];
                    break;
                }
            }
            // GET Parameter.
            let search = window.location.search.substr(1).split("&");
            for(let i = 0; i < search.length; i++)  {
                let param = search[i].split("=");
                params[param[0]] = param[1];
            }

        const response = await fetch(global.apiURL, {
            method: 'POST',
            headers: {
                'Authorization': global.apiBearer,
                'Content-Type': global.apiContentType
            },
            http_errors: false,
            body: JSON.stringify({
                version: 1.0,
                user: global.apiUser,
                auth_token: global.apiUserAuthToken,
                service: global.apiCalculationManagementService,
                function: global.apiFunctionGetOfferByToken,
                param: {
                    token: params.token, // später params.token
                }
            })
        }).then(res => res.json())
            .then((results) => {
                /* check if offerbegin and status is still valid */
                checkOffer(results.result.offer_begin, results.result.status);
                checkCustomerID(results.result.customer_id);
                if (results.status === "ok"){
                    results.result.token = params.token;
                    return results;
                }else{
                    var errorMessageAPI = results.result;
                    var expiredCheck = errorMessageAPI.includes("Expired");
                    if (expiredCheck){  // link to expired site
                        window.location.href = global.ExpiredTokenPage;
                    }else{
                        window.location.href = global.InvalidTokenPage;
                    }
                }
            });
        offer =  response;
    return offer;
}

export default getofferByToken;