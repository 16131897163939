import "whatwg-fetch";

async function getCities(postalCode) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': 'application/json'
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiServiceNetfee,
            function: global.apiFunctionsGetCity,
            param: {
                postal_code: postalCode
            }
        })
    })
        .then(res => res.json())
        .then((result) => {
            if (result.status === "ok" && result.result !== null) {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(function (error) {                        // catch
        console.log('Request failed', error);
    });
}

export default getCities;