import getUrlParam from "./getUrlParams";
import activateCustomer from "../fetches/activateNewCustomer";
import getEmailByID from "../fetches/getEmailById";
const AES = require("crypto-js/aes");
const ENC = require("crypto-js/enc-utf8");

function checkSession() {
    //check if id is in login
    var sessionCustomerID;
    if (typeof localStorage['securityToken'] !== "undefined") sessionCustomerID = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
    else sessionCustomerID = localStorage['securityToken'];
    var offerActivationTokenParam = getUrlParam()["activation"];

    //check if user is entering the site with a 1 time token (first registration)
    if(typeof offerActivationTokenParam !== "undefined" && typeof sessionCustomerID === "undefined"){
        activateCustomer(offerActivationTokenParam).then(result => {
            if (result !== "ERR") {  // set customerID as securityToken on success
                localStorage['securityToken'] = AES.encrypt(result.result.customer_id + '', global.encriptKey);
                getEmailByID(result.result.customer_id); //get user email and start recording user interaction
            } else { // redirect to login
                window.location.replace(global.LoginURL);
            }
        });
    }else {  //no id Set - check user-location on site and redirect to login (with saved location)
        if (typeof sessionCustomerID === "undefined" || sessionCustomerID == null) {
            var getParamas = window.location.href.split("/");
            var i = 2;
            var paramCount;
            var redirect = "";

            for (i = 2; i<10; i++){
                paramCount = i + 1;
                if (typeof getParamas[paramCount] === "undefined") {
                    for (var v = 2; v < paramCount-1; v++){
                         redirect = redirect + "/" + getParamas[v+1];
                    }
                    break;
                }
            }
          window.location.href = global.LoginURL + redirect;
        }else{ // session ID has id
            getEmailByID(sessionCustomerID);  //get email and start recording user interaction
        }
    }
}

export default checkSession;