import React from 'react';
import $ from 'jquery';
import PopupBox from "./PopupBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import '../global';
import unixToDate from "../functions/unixToDate";
import toggleIcon from "../functions/toggleIcon";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import LoadingAnimationDotted from "../functions/loadingAnimationsDotted";
import CustomMessageAlert from "../errorpage/CustomMessageAlert";
import deleteConsumptionPoint from "../fetches/deleteConsumptionPoint";
import createOffer from "../fetches/createOffer";
import calculateAllTariffs from "../fetches/calculateAllTariffs";
import VerifyActionPopup from "./VerifyActionPopup";

/* Das ist die Index der Abnahmestellenerstellung. Hier werden die Abnahmestellen gerdendert und die PopUp Box ist
* hiervon eine Child-Komponente, sie gibt immer eine Abnahmestelle beim erstellen zurück und diese werden dann in der
* state Variable consumptionPoints gespeichert und angezeigt. */

export default class CSPointsIndex extends React.Component {
    constructor () {
        super();
        this.state = {
            showModal: false,               // false = popup off
            consumptionPoints: [],          // Array with Abnahmestellen
            editedCsPoint: '',               // pass edit values to child
            showWarning: false,
            csPointDeleteID: '',
            cities: '',
            isLoaded: false,
            selectedConsumptionPoints: [],
            apiError: false,
            showLoadingAnimation: '',
            hideCsPointsWrapper: 'd-none',
            displayEmptyFilterResultMessage: 'd-none',
            countSelected: 0,
            totalCountCspoints: 0,
            cantDeleteCspoint: false,
            cantCalculate: false
        };
        this.deleteBox = this.deleteBox.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.getCsPoints = this.getCsPoints.bind(this);
        this.unsetEditedCsPoint = this.unsetEditedCsPoint.bind(this);
        this.handleSubmitCsPoints = this.handleSubmitCsPoints.bind(this);
        this.showFilters = this.showFilters.bind(this);
        this.chooseFilter = this.chooseFilter.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.getSlpType = this.getSlpType.bind(this);
        this.getVoltageLevel = this.getVoltageLevel.bind(this);
        this.hasContract = this.hasContract.bind(this);
        this.selectNewCsPoint = this.selectNewCsPoint.bind(this);
        this.closeErrorPopup = this.closeErrorPopup.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
    }

    //Class Functions
    componentDidMount() {
        this.disableAddNewConsumptionpoint = false;
        if(this.state.consumptionPoints.length > 0){
            // document.getElementById("consumptionPointWrapper").style.backgroundColor = 'transparent';
            document.getElementById("consumptionPoints").classList.remove('addBackground');
        }else{
            // document.getElementById("consumptionPointWrapper").style.backgroundColor = '#f6f6f6';
            document.getElementById("consumptionPoints").classList.add('addBackground');
        }
    }

    componentWillReceiveProps(nextProps) {
        this.customerID = nextProps.customerID;

        this.setState({
            totalCountCspoints: nextProps.cspointsResult.length,
        });

        //Activate loading when energy type changed
        if(nextProps.displayCspointsLoading === 'active')
        {
            //uncheck the set all checkbox checkbox
            var selectAllCpsCheckbox = document.getElementById("selectAllCps");
            toggleIcon(selectAllCpsCheckbox, 'o-checkbox-checked-square-1', 'o-layout-1');
            this.setState({
                showLoadingAnimation: '', consumptionPoints: [],
                hideCsPointsWrapper: 'd-none',
                countSelected: 0 //reset count selected when switchen energyType
            });
        }
        //if begin date changes uncheck "select all"  checkbox
        if ( nextProps.changeDate === true) {
            this.selectAll(true)
        }

        //Display message if no results for filter
        if(nextProps.cspfilters.length !== 0 && nextProps.cspointsResult.length === 0){
            setTimeout(function(){
                this.setState({displayEmptyFilterResultMessage: ''});
            }.bind(this), 2000)
        }else{
            this.setState({displayEmptyFilterResultMessage: 'd-none'});
        }

        //If no cspoints exist & filters are not set, display empty box
        if(nextProps.cspointsResult.length === 0 && nextProps.cspfilters.length === 0 && !nextProps.cspointsAreLoading){
            this.setState({
                consumptionPoints: [],
                showLoadingAnimation: 'd-none',
                hideCsPointsWrapper: '',
                displayEmptyFilterResultMessage: 'd-none'
            });
        }
        //When cspoints received from request, hide loading, show cspoints
        //The Results array can be empty if filters are set
        if(nextProps.cspointsResult.length > 0 || nextProps.cspfilters.length > 0){
            this.setState({
                consumptionPoints: nextProps.cspointsResult,
                showLoadingAnimation: 'd-none',
                hideCsPointsWrapper: ''
            });
        }
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    closeErrorPopup = () => {
        this.setState({cantDeleteCspoint: false, cantCalculate: false});
    };

    //Callbacks
    selectNewCsPoint = (newCsPointID) => {
        this.selectConsumptionPoint(newCsPointID);
    };

    getCsPoints = (mdArray) => {
        //Popupbox Submit was clicked
        var totalCsPoints = this.state.totalCountCspoints;
        this.setState({
            totalCountCspoints: totalCsPoints +1,
            consumptionPoints: mdArray
        });

        if(mdArray.length > 0){
            document.getElementById("consumptionPoints").classList.remove('addBackground');
        }else{
            document.getElementById("consumptionPoints").classList.add('addBackground');
        }
    };

    //Custom Functions
    showWarning (id) {
        this.setState({showWarning: true, csPointDeleteID: id, showModal: false});
    }

    deleteBox () {
        let allCpsArray = this.state.consumptionPoints;
        let deleteCpID = this.state.csPointDeleteID;
        let newCpsArray = [];

        this.selectAll(true);

        //Uncheck cspoint if checked before delete
        //Short waiting time beofre unckecking for the api delete request
        setTimeout(function(){
            var checkbox = document.getElementById("selectBoxCp-" +deleteCpID);
            if(checkbox.classList.contains('o-checkbox-checked-square-1')){
                var consumptionPointBox = document.getElementById("consumptionPointBox-" + deleteCpID);
                toggleIcon(checkbox, 'o-checkbox-checked-square-1', 'o-layout-1');
                consumptionPointBox.classList.remove("checked");
                var selectedCpsArr = this.state.selectedConsumptionPoints;
                var positionOfID = selectedCpsArr.indexOf(deleteCpID); // get the position of value in array for delte
                selectedCpsArr.splice(positionOfID, 1); // delte id out of array
                this.setState({countSelected: this.state.countSelected - 1, selectedConsumptionPoints: selectedCpsArr});

                if(selectedCpsArr.length === 0){
                    document.getElementById("consumptionPoints").classList.add('addBackground');
                }
            }
        }.bind(this), 50);

        deleteConsumptionPoint(deleteCpID, this.customerID).then(result => {
            if(result !== "ERR" && result !== "Contract bound"){
                allCpsArray.map((singleCp) => {
                    if(singleCp.id !== deleteCpID){
                        newCpsArray.push(singleCp);
                    }
                    return 0;
                });
                this.setState({consumptionPoints: newCpsArray, scsPointDeleteID: '', showWarning: false});
                this.props.callbackUpdateCspointsArray(newCpsArray);
            }else if(result === "Contract bound"){
                this.setState({cantDeleteCspoint: true, showWarning: false});
            }else{
                this.setState({showWarning: false});
                this.apiErrorOccured('deleteConsumptionPoint', result);
            }
        })
    }

    closeModal(){
        this.setState({
            showModal: false,
            editedCsPoint: '',
        });
    }

    handleOpenModal(value) {
        if(!this.disableAddNewConsumptionpoint){
            this.setState({showModal: true});
            //Unterscheidung ob neue Abnahmestelle oder der Edit-Modus
            if (value === 'new') {
                this.setState({
                    editedCsPoint: '',
                });
            }else{
                this.setState({
                    editedCsPoint:  value.id,
                });
            }
        }
    }

    unsetEditedCsPoint(){
        this.setState({editedCsPoint: ''})
    }

    handleSubmitCsPoints(){
        var consumptionPoints = this.state.selectedConsumptionPoints;
        if(this.state.consumptionPoints.length === 0){
            this.props.callbackDisplayError('Bitte legen Sie mindestens eine Abnahmestelle an.'+
                ' Dies können Sie tuen, indem Sie auf den unterstehenden Schriftzug "Lieferstelle hinzufügen" klicken und dort die geforderten Informationen zu Ihrer Lieferstelle eintragen.');
        }
        else if (consumptionPoints.length === 0){
            this.props.callbackDisplayError('Bitte wählen Sie mindestens eine Abnahmestelle aus. ' +
                'Dies können Sie tuen, indem Sie auf einer angelegten Abnahmstelle auf die Auswahlbox mit der Beschreibung "Kalkulieren" klicken.');
        }
        else{
            //get begin and end and
            var begin = document.getElementById("duration-start").value;
            var end = document.getElementById("duration-end").value;
            //format date into y-m-d from d.m.y (for api request)
            begin = unixToDate(begin, "fix");
            end = unixToDate(end, "fix");
            //medium type (GAS/ ELECTRICITY)
            var mediumType = "ELECTRICITY";
            var gasBtnMediumType = document.getElementById("gas");

            if (gasBtnMediumType.checked === true) mediumType = "GAS";
            var submitBtn = document.getElementsByClassName("eless-cta-btn");
            //loading spinner in button
            var loadingLoadProfileSpinner = document.getElementById("loadingLoadProfileSpinner");

            //Disable all user actions during calculating process
            this.disableAllUserActions(submitBtn, loadingLoadProfileSpinner);

            // CREATE OFFER FETCH
            createOffer(this.customerID, begin, end, mediumType, consumptionPoints).then(firstResult => {
                        if (firstResult !== "ERR") {
                            //on success
                            var offerInfo = firstResult.offer_information;
                            var offerID = offerInfo.offer_id;
                            var token = offerInfo.token;
                            //calculate tariffs in offer and redirect to tariffs
                            calculateAllTariffs(offerID).then(secondResult => {
                                        if (secondResult !== "ERR") { //success
                                            window.location = global.TariffsPage + '/' + token; //redirect to tariff page
                                        } else { //error
                                            this.setState({cantCalculate: true});
                                            this.enableAllUserActions(submitBtn, loadingLoadProfileSpinner);
                                        }
                                    }
                                )
                        }else{
                            //Enable all user actions after calculating process on error
                            this.enableAllUserActions();
                            this.apiErrorOccured('createOffer', firstResult);
                        }
                    }
                )
        }
    }

    enableAllUserActions(submitBtn, loadingLoadProfileSpinner){
        $( ".clickable-icon" ).each(function( icon ) {
            var $this = $(this);
            $this.show();
        });
        this.disableAddNewConsumptionpoint = false;
        submitBtn[0].disabled = false;
        loadingLoadProfileSpinner.classList.add('d-none');
    }

    disableAllUserActions(submitBtn, loadingLoadProfileSpinner){
        $( ".clickable-icon" ).each(function( icon ) {
            var $this = $(this);
            $this.hide();
        });
        this.disableAddNewConsumptionpoint = true;
        submitBtn[0].disabled = true;
        loadingLoadProfileSpinner.classList.remove('d-none');
    }

    selectAll(reset = false) {
        var checkbox = document.getElementById("selectAllCps");
        var cps = this.state.consumptionPoints;
        var selectedCps = this.state.selectedConsumptionPoints;
        var i = 0;
        var contracts = [];
        var selectable = 1;
        var selected = []; //set state selectedConsumptionPoints later
        var checkboxCP;
        var consumptionPointBox;
        var begin = document.getElementById("duration-start").value;
        //format it so we can make a js date format out of it (to get unix time for compare reasons)
        var beginNew = unixToDate(begin, "fix");
        beginNew = new Date(beginNew);
        // var end = "";
        var countSelected = this.state.countSelected;

        if (checkbox.classList.contains('o-checkbox-checked-square-1') || reset === true) {
            toggleIcon(checkbox, 'o-checkbox-checked-square-1', 'o-layout-1');
            for (i; i < cps.length; i++) {
                if (selectedCps.includes(cps[i].id)){
                    checkboxCP = document.getElementById("selectBoxCp-" +cps[i].id);
                    if(checkboxCP.classList.contains('o-checkbox-checked-square-1')) countSelected = countSelected -1;
                    consumptionPointBox = document.getElementById("consumptionPointBox-" + cps[i].id);
                    toggleIcon(checkboxCP, 'o-checkbox-checked-square-1', 'o-layout-1');
                    consumptionPointBox.classList.remove("checked"); // remove selected border
                }
            }
        }else{ //select all consumptionpoints
            //switch icon of checkbox to checked
            toggleIcon(checkbox,'o-layout-1', 'o-checkbox-checked-square-1');
            for (i; i < cps.length; i++) {
                //var to check if the cp must be saved
                selectable = 1;
                //check if cp has contract(s)
                if (cps[i].extended_information.hasOwnProperty("contracts")) {
                    if (cps[i].extended_information.contracts.length > 0) {
                        contracts = cps[i].extended_information.contracts;
                        //loop through all contracts to see if the cp was submitted already -> if yes not selectable
                        for (var property in contracts) {
                            if (contracts[property].status === "submitted" || contracts[property].status === "confirmed") {
                                //check if offer begin is in range of contract duration
                                // end = unixToDate(contracts[property].contract_end, "dot");
                                var contractEnd = new Date(contracts[property].contract_end);
                                //if start date is after the end date of the contract enable it
                                if (beginNew < contractEnd) {
                                    selectable = 0;
                                }
                            }
                        }
                    }
                }
                //save function for selectable cps
                if (selectable === 1){
                    selected.push(cps[i].id);
                    checkboxCP = document.getElementById("selectBoxCp-" +cps[i].id);
                    consumptionPointBox = document.getElementById("consumptionPointBox-" + cps[i].id);
                    if(checkboxCP.classList.contains('o-layout-1')) countSelected = countSelected +1;
                    toggleIcon(checkboxCP, 'o-layout-1', 'o-checkbox-checked-square-1');
                    consumptionPointBox.classList.add("checked"); //add border
                }
            }
        }
        this.setState({
            selectedConsumptionPoints: selected,
            countSelected: countSelected
        });
    }

    selectConsumptionPoint(cpID){
        var checkbox = document.getElementById("selectBoxCp-" +cpID);
        var consumptionPointBox = document.getElementById("consumptionPointBox-" + cpID);
        var selectAllCheckbox = document.getElementById("selectAllCps");
        if (selectAllCheckbox.classList.contains("o-checkbox-checked-square-1")) {
            toggleIcon(selectAllCheckbox, 'o-checkbox-checked-square-1', 'o-layout-1' );
        }

        if(checkbox.classList.contains('o-layout-1')){ //if unchecked
            toggleIcon(checkbox, 'o-layout-1', 'o-checkbox-checked-square-1');
            consumptionPointBox.classList.add("checked"); //add border
            var joined = this.state.selectedConsumptionPoints.concat(cpID); //add cp id to state array
            this.setState({ selectedConsumptionPoints: joined});
            this.setState({countSelected: this.state.countSelected + 1});
        }else{
            toggleIcon(checkbox, 'o-checkbox-checked-square-1', 'o-layout-1');
            this.setState({countSelected: this.state.countSelected - 1});
            consumptionPointBox.classList.remove("checked"); // remove selected border
            if (this.state.countSelected === 0) {
                this.setState({ selectedConsumptionPoints: []});
            }else{
                 var selectedCpsArr = this.state.selectedConsumptionPoints;
                var positionOfID = selectedCpsArr.indexOf(cpID); // get the position of value in array for delte
                selectedCpsArr.splice(positionOfID, 1); // delete id out of array
                this.setState({ selectedConsumptionPoints: selectedCpsArr});
            }
        }
    };

    showFilters(e){
        var element = e.target;
        var filtersElement = document.getElementById('cspoint-filters');
        var filterItems = document.getElementsByClassName('filter-btn');
        var i = 0;
        var resetingFilter = 0;

        //Check if filter items active to know if the user will have to wait & to display loading animation
        for ( i = 0; i < filterItems.length; i++) {
            if(filterItems[i].classList.contains('active'))
            {
                resetingFilter = 1;
                filterItems[i].classList.add('inactive');
            }
        }

        if(filtersElement.classList.contains('active')){
            filtersElement.classList.remove('active');
            element.classList.remove('active');

            //Deactivate filter message if filters are beeing deactivated
            this.setState({displayEmptyFilterResultMessage: 'd-none'});

            //if any of the items is active, display the loading animation to reset the filter
            if(resetingFilter === 1){
                //loading animation components
                var ldsSpinner = document.getElementById('filter-loading');
                var filterTriggerer = document.getElementById('filterTriggerer');

                filterTriggerer.classList.add('inactive');
                ldsSpinner.classList.remove('inactive');

                //Hide loading after some defined time
                setTimeout(function() {
                    filterTriggerer.classList.remove('inactive');
                    ldsSpinner.classList.add('inactive');
                }, 2000);
            }
        }else{
            filtersElement.classList.add('active');
            element.classList.add('active');
        }

        //deactivate filters on close
        var elements = document.getElementsByClassName('filter-btn');
        for (i = 0; i < elements.length; i++) {
            if(elements[i].classList.contains('active')){
                elements[i].classList.remove('active');
            }
        }

        this.props.callbackFilterConsumptionpoints('', 'clear');
    }

    chooseFilter(e){
        var element = e.target;
        var elementID = element.id;
        var filterValue = element.dataset.value;
        var filterButtons = document.getElementsByClassName('filter-btn');
        var sibling = '';

        //loading animation components
        var ldsSpinner = document.getElementById('filter-loading');
        var filterTriggerer = document.getElementById('filterTriggerer');

        filterTriggerer.classList.add('inactive');
        ldsSpinner.classList.remove('inactive');

        //function here
        this.selectAll(true);

        for (var i = 0; i < filterButtons.length; i++) {
            filterButtons[i].disabled = true;
        }

        //Deactivate sibling if active & remove from filters
        if(elementID === 'lastTypeRLM'){
            sibling = document.getElementById('lastTypeSLP');
        }else if(elementID === 'lastTypeSLP'){
            sibling = document.getElementById('lastTypeRLM');
        }else if(elementID === 'hasContract'){
            sibling = document.getElementById('hasNoContract');
        }else if(elementID === 'hasNoContract'){
            sibling = document.getElementById('hasContract');
        }
        if(sibling.classList.contains('active')){
            sibling.classList.remove('active');
            sibling.classList.add('inactive');
        }

        //Activate / Deactivate animation
        //Also Pass filter Data to parent
        if(element.classList.contains('inactive')){
            element.classList.remove('inactive');
            element.classList.add('active');

            //Save filter option
            this.props.callbackFilterConsumptionpoints(filterValue, 'add', sibling.dataset.value);
        }else{
            element.classList.add('inactive');
            element.classList.remove('active');

            //Remove filter option
            this.props.callbackFilterConsumptionpoints(filterValue, 'remove');
        }

        setTimeout(function() {
            for (i = 0; i < filterButtons.length; i++) {
                filterButtons[i].disabled = false;
            }
            filterTriggerer.classList.remove('inactive');
            ldsSpinner.classList.add('inactive');
        }, 2000);
    }

    hasContract(consumptionPoint, id){
        var hasContract = 0;
        var end = "";
        var cpInfo = consumptionPoint.extended_information;
        //begin from datepicker
        var begin = document.getElementById("duration-start").value;
        //format it so we can make a js date format out of it (to get unix time for compare reasons)

        //Auf grund eines fehlers im Wordpress kann die funktion hier nicht aufgerufen werden
        // var beginNew = unixToDate(begin, "fix");
        //Die folgenden Zeilen stammen aus der unixToDate funktion
        var date = begin.split('.');
        var day = date[0];
        var month = date[1];
        var year = date[2];
        var beginNew = year+'-'+month+'-'+day;
        beginNew = new Date(beginNew);

        //check if contracts exitsts because you cant delete or edit cps with contracts
        if (cpInfo.hasOwnProperty("contracts")) {
            if (cpInfo.contracts.length > 0){
                var contracts = cpInfo.contracts;
                Object.keys(contracts).forEach(function(key) {
                    if (contracts[key].status === "submitted" || contracts[key].status === "confirmed") {
                        //disable contract if status is submitted or the provider accepted it
                        hasContract = 1;
                        end = unixToDate(contracts[key].contract_end, "dot");
                        var newEnd = new Date(contracts[key].contract_end);
                        //if start date is after the end date of the contract enable it
                        if (beginNew > newEnd) {
                            hasContract = 0;
                        }
                    }
                });
                if (hasContract === 1) {
                    //return icon "locked" here (contracts inserted)
                    return <div className="row consumptionPointDelete mb-2 ml-0 mr-0" data-value={id}>
                        <div className={'col-md-12 col-sm-12 col-12 hasContractHeadline'}>
                            <span className={'eless-icon o-lock-1 mr-1'}/> Unter Vertrag <small>(bis {end})</small>
                        </div>
                    </div>
                }
            }
        }
        //no icon -> contracts property does not exist (on create)
        return <div className="row consumptionPointDelete mb-2 ml-0 mr-0" data-value={id}>
            <div className={'text-left col-md-5 col-sm-5 col-5 position-relative'}>
                <label className="mb-0 eless-checkbox consumption-point-checkbox clickable-icon" onClick={() => this.selectConsumptionPoint(consumptionPoint.id)}>
                    <span id={"selectBoxCp-" +consumptionPoint.id} className={'o-layout-1 mr-1 clickable-icon'} /> Kalkulieren
                </label>
            </div>
            <div className={'col-md-4 col-sm-4 col-4'}>
            </div>

            <div className={'col-md-1 col-sm-1 col-1 clickable-icon pencil-icon position-relative'} onClick={() => this.handleOpenModal({id})}><span className={'eless-icon o-edit-1 cspoint-action'} /></div>
            <div className={'col-md-1 col-sm-1 col-1 text-right clickable-icon position-relative'}  onClick={() => this.showWarning(consumptionPoint.id)}><span className={'eless-icon o-bin-1 cspoint-action'} /></div>
        </div>;
    }

    getSlpType(consumptionPoint){
        if(consumptionPoint !== []){
            if(consumptionPoint.consumption_type === 'RLM' && consumptionPoint.extended_information.business_type !== null && consumptionPoint.extended_information.business_type !== ""){ // RLM but no loadProfile
                return 'RLM ' + global.rlmCodes[consumptionPoint.extended_information.business_type];
            }else if(consumptionPoint.consumption_type === 'RLM'){ // with loadProfile
                return 'RLM mit Lastgang';
            }else{ // slp
                return 'SLP ' + global.slpProfiles[consumptionPoint.extended_information.business_type];
            }
        }
        return '';
    }

    getVoltageLevel(consumptionPoint){
        if(consumptionPoint) {
            if (consumptionPoint.consumption_type === "RLM") {
                if (this.props.energyType === "GAS"){ // GAS
                    if(consumptionPoint.extended_information.specification === 0){
                        return 'Niederdruck';
                    }else if(consumptionPoint.extended_information.specification === 1){
                        return 'Mitteldruck';
                    }else if(consumptionPoint.extended_information.specification === 2){
                        return 'Hochdruck';
                    }
                } else{ // STROM
                    if(consumptionPoint.extended_information.specification === 0){
                        return 'Niederspannung';
                    }else if(consumptionPoint.extended_information.specification === 1){
                        return 'Mittelspannung';
                    }else if(consumptionPoint.extended_information.specification === 2){
                        return 'Hochspannung';
                    }else if(consumptionPoint.extended_information.specification === 3){
                        return 'Höchstspannung';
                    }
                }
            }
        }
        return "";
    }

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    render () {
        let consumptionPoints = this.state.consumptionPoints;
        let consumptionPointMessage;
        let disableSubmit;
        let wrapperBackground='';

        if (this.state.totalCountCspoints === 0) {
            consumptionPointMessage = <i>Bitte legen Sie eine Abnahmestelle an.</i>;
            disableSubmit = true;
            wrapperBackground='addBackground';
        }else if (this.state.countSelected === 0) {
            consumptionPointMessage = <i>Bitte wählen Sie eine Abnahmestelle aus.</i>;
            disableSubmit = true;
        }else {
            consumptionPointMessage = <i>Sie haben {this.state.countSelected} von {this.state.totalCountCspoints} Abnahmestellen ausgewählt.</i>;
            disableSubmit = false;
        }

        return (
            <div id={'consumptionPoints'} className={wrapperBackground}>
                <div className={"row"}>
                    <div className={"col-md-3 col-sm-12 col-12"}>
                        <label className={"ml-2 clickable-icon"} onClick={() => this.selectAll()}>
                            <span id={"selectAllCps"}  className={'o-layout-1 mr-1'} />Alle auswählen
                        </label>
                    </div>
                    <div className={"col-md-9 col-sm-12 col-12"}>
                        <div className={"mb-3 ml-2 filter-icon"}>
                            <div id={'cspoint-filters'} className={''} >
                                <button id={'lastTypeRLM'} className={'filter-btn inactive'} onClick={(e) => this.chooseFilter(e)} data-value={'RLM'}>RLM</button>
                                <button id={'lastTypeSLP'} className={'filter-btn inactive'} onClick={(e) => this.chooseFilter(e)} data-value={'SLP'}>SLP</button>
                                <button id={'hasContract'} className={'filter-btn inactive'} onClick={(e) => this.chooseFilter(e)} data-value={'Contract'}>Unter Vertrag</button>
                                <button id={'hasNoContract'} className={'filter-btn inactive'} onClick={(e) => this.chooseFilter(e)} data-value={'NoContract'}>Ohne Vertrag</button>
                            </div>
                            <span id={'filterTriggerer'} onClick={(e) => this.showFilters(e)} className={"o-sliders-1"}/>
                            <div id={'filter-loading'} className="lds-ring filter-lds inactive">
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </div>
                    </div>
                </div>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                <LoadingAnimationDotted text={'Lieferstellen werden geladen'} hideclass={this.state.showLoadingAnimation} />
                <div id={'consumptionPointWrapper'} className={'row mr-0 '+this.state.hideCsPointsWrapper}>

                    {consumptionPoints.map( (consumptionPoint, id) =>
                        <div key={id}  className='col-lg-4 col-md-6 col-sm-12 col-md-offset-2 csPointBoxWrapper'>
                            <div id={"consumptionPointBox-" + consumptionPoint.id} className='consumptionPointBox'>
                                {this.hasContract(consumptionPoint, id)}

                                <div className={"row ml-0 mr-0"}>
                                    <div className={'street-title col-lg-12'}><b>{consumptionPoint.street} {consumptionPoint.house_number}</b></div>
                                    <div className={'col-lg-12'}>{consumptionPoint.postal_code} {consumptionPoint.city}</div>
                                    <div className={'col-lg-12'}>{consumptionPoint.mean_consumption}</div>
                                    <div className={'col-lg-12'}>{this.getSlpType(consumptionPoint)}</div>
                                    <div className={'col-lg-12'}>{this.getVoltageLevel(consumptionPoint)}</div>
                                </div>
                            </div>
                        </div>)}

                    <div className={'no-filters-result '+this.state.displayEmptyFilterResultMessage}>Für diese Filterkriterien liegen keine Ergebnisse vor. Versuchen Sie bitte mit anderem Filter oder fügen Sie die fehlende Lieferstelle hinzu.</div>
                    <div id='consumptionPointAdd' onClick={() => this.handleOpenModal('new')} className='cp-modal-trigger col-md-3 col-sm-12'>
                        <div className="vertical-center" >
                            <span className={'o-add-circle-1 medium-icon'} />
                            <span className={'add-consumptionpoint'}> Lieferstelle hinzufügen</span>
                        </div>
                    </div>
                    <PopupBox energyType={this.props.energyType} callbackCloseModal={this.closeModal} customerID={this.customerID} unsetEditedCsPoint={this.unsetEditedCsPoint} csPoints={this.state.consumptionPoints} editedCsPoint={this.state.editedCsPoint} showModal={this.state.showModal} selectNewCsPoint={this.selectNewCsPoint} setCsPoints={this.getCsPoints} closeModal={this.closeModal}/>
                </div>
                <div className={'row bottom-row'}>
                    <div className={"col-md-8 col-lg-8 col-sm-12 pr-0 cspoint-message"}>
                            {consumptionPointMessage}
                    </div>
                    <div className={"col-md-4 col-lg-4 col-sm-12 pl-0"}>
                        <button type='submit' onClick={this.handleSubmitCsPoints} className='eless-cta-btn eless-btn' id='consumptionPointsInlineEditBtn' disabled={disableSubmit}>
                            Angebote vergleichen
                            <span id={'loadingLoadProfileSpinner'} className={'d-none'}><FontAwesomeIcon className={'fa-spin fa-2x'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/></span>
                        </button>
                    </div>
                </div>
                <VerifyActionPopup show={this.state.showWarning} content={'Sind Sie sicher das Sie diese Abnahmestelle löschen möchten?'} handleClose={() => this.setState({showWarning: false})} delete={() => this.deleteBox} />
                <CustomMessageAlert closeModal={this.closeErrorPopup} show={this.state.cantDeleteCspoint} title={'Fehler'} content={'Aufgrund eines Fehlers kann diese Abnahmestelle momentan nicht gelöscht werden'} />
                <CustomMessageAlert closeModal={this.closeErrorPopup} show={this.state.cantCalculate} title={'Fehler'} content={"Es ist ein Fehler in der Kalkulation aufgetreten. Bitte aktualisieren Sie die Seite und versuchen es noch einmal. Sollte der Fehler weiterhin auftreten, rufen Sie uns an: (+49)821 899-998-0 Wir helfen Ihnen gerne weiter."} />
            </div>
        );
    }
}