import React from 'react';
import getOfferByToken from "./getOfferByToken";
import getCountDown from "./refreshOfferCountDown";
import refreshOffer from "./refreshOfferBtnClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import {OverlayTrigger} from 'react-bootstrap';

/* Titel halt */

export default class RefreshOfferFunction extends React.Component {

    //refresh the prices (reload page -> redirect to benefits)
    RefreshClick(e) {
        document.getElementById('spinIt').classList.add('spin');
        e.target.style.pointerEvents = 'none'; //disable on click
        refreshOffer()
    }

    componentDidMount() {
        getOfferByToken().then(res =>{
            //if offer is expired refresh offer automaticly (-> redirect to benefits)
            getCountDown(res);
        });
    }

    render(){
        //function for spinning icon
        $(".refreshIconWrapper").hover(
            function() {
                $(".refreshIcon").addClass( "fa-spin" );
            }, function() {
                $(".refreshIcon").removeClass( "fa-spin" );
            }
        );

        const renderTooltip = props => (
            <div
                {...props}
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    padding: '5px 5px',
                    color: 'white',
                    borderRadius: 3,
                    margin: "0px 5px",
                    ...props.style,
                }}
            >
               <span>
                    Klicken Sie hier um Ihre Angebote zu aktualisieren. <br/>
                    Achtung Sie werden ggf. einige Seiten zurück versetzt. <br/>
                    Ihre bisherigen Eingaben bleiben vorhanden
               </span>
            </div>
        );

        const RefreshBtn = () => (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: "200", hide: "200" }}
                overlay={renderTooltip}
            >
                    <div id={'spinIt'} className={'d-inline-block'}><FontAwesomeIcon onClick={(e) => this.RefreshClick(e)} icon={faSyncAlt} size={"sm"} className={"refreshIcon"} /></div>
            </OverlayTrigger>
        );


        return (
            <div>
                <div className='row ml-0 mr-0 pr-0 pt-0 mt-0 text-right'>
                    <div className={"refresh-block-text col-lg-12 col-md-12 col-sm-12 pl-4 pr-0"}>
                        <span id={"time-span"} className={'pr-2'}>
                            <i>Ihre Angebote sind noch </i>
                            <b className={"ml-2"} id={"time"}></b>
                             <i> Minute(n) gültig.</i>
                        </span>
                        <RefreshBtn />
                    </div>
                </div>
            </div>
        );
    }
}