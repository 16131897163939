import React from "react";
import Filter from './tariffs/Filter';
import getOfferByToken from "../functions/getOfferByToken";
import getCalculatedTariffs from "../fetches/getCalculatedTariffs";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import Header from "../Header";
import Footer from "../Footer";

/* Das ist die Index Datei der Angebote. Hier wird der Request für die entsprechenden
*  Angebote gemacht, in die Child Komponenten weitergegeben, gefiltert und ausgegeben. */

export default class TariffsApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            items: [],
            isLoggedIn: false,
            orderSubmited: false,
            offerID: null,
            customerID: null,
            voucher: null
        };

        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);
        this.handleFormatNumber = this.handleFormatNumber.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    handleLoginClick() {
        if(this.state.isLoggedIn){
            this.setState({isLoggedIn: false});
        }else{
            this.setState({isLoggedIn: true});
        }
    }

    handleOrderClick() {
        if(this.state.orderSubmited){
            this.setState({orderSubmited: false});
        }else{
            this.setState({orderSubmited: true});
        }
    }

    handleFormatNumber(number){
        let str = number;
        str = str.replace(" kWh", "");
        str = str.replace(/[^0-9]/g, "");
        str = str.replace(/[a-zA-Z]/g, "");
        str = str.replace(/\./g, "");
        let str_a = [];
        let i = 0;
        while (str.length - i * 3 > 0) {
            i++;
            if (str.length - i * 3 > 0) {
                str_a.push(str.substr(-3 * i, 3));
            } else {
                str_a.push(str.substr(0, str.length - (i - 1) * 3));
            }
        }
        str = "";
        while (str_a.length > 0) {
            str += str_a.pop();
            if (str_a.length > 0)
                str += ".";
        }

        return str;
    }

    /* Sobald die Komponente in dem DOM geladen wurde, wird der request für die Tarife gemacht. */
    componentDidMount() {
        // let currentPage = this.props.match.params.page;
        const that = this;
        //get offerID and customerID
        getOfferByToken().then(firstResult =>{
            var offerID = firstResult.result.id;
            var customerID = firstResult.result.customer_id;
            that.setState({offerID: offerID, customerID: customerID});
            getCalculatedTariffs(offerID).then((result) => {
                if (result !== "ERR") { //success
                    that.setState({isLoaded: true, items: result});
                }else{
                    that.setState({isLoaded: true, items: []});
                    this.apiErrorOccured('getCalculatedTariffs', result);
                }
            })
        });
    }

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    render() {
        const {error, isLoaded, items} = this.state;

        let content=<></>;
        /* Abhängend davon was der request zurückgibt, wird der ensprechende Content angezeigt */
        if (error) {
            content = <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            content = <div className={'full-page-spinner-wrapper'}>
                <div id={'energytype-loading'} className="lds-ring full-page-spinner">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>;
        }else if (items === "[]"){
            content = <div>Keine Ergebnisse</div>
        } else {
            content = <div>
                    <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                    <Filter match={this.props.match} offerID={this.state.offerID} customerID={this.state.customerID} tariffs={items} isLoggedIn={this.state.isLoggedIn} orderSubmited={this.state.orderSubmited} showComponent={this.props.showComponent}/>
                </div>
        }

        console.log(items);

        return (
            <>
                <Header />
                <div className='max-width-content'>
                    {content}
                </div>
                <Footer />
            </>
        )
    }
}