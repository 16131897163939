import React from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import '../../global';
import PriceInformation from './tariffInformation/PriceInformation'
import TariffInformation from './tariffInformation/TariffInformation'
import ProviderInformation from './tariffInformation/ProviderInformation'
import MoreInformation from './tariffInformation/MoreInformation'
import getOfferByToken from "../../functions/getOfferByToken";
import toggleIcon from "../../functions/toggleIcon";
import LoadingAnimationDotted from "../../functions/loadingAnimationsDotted";
import createContract from "../../fetches/createContract";
import APIErrorAlert from "../../errorpage/APIErrorAlert";
import calculateNetFee from "../../fetches/calculateNetFee";

const listItemClass = 'o-info-help-2';

class Tariffs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gasQuality: '',
            controlArea: '',
            mediumType: "ELECTRICITY",
            isLoaded: false,
        };

        this.checkSocket = this.checkSocket.bind(this);
        this.getTariffName = this.getTariffName.bind(this);
        this.getTariffPrices = this.getTariffPrices.bind(this);
        this.getGasValues = this.getGasValues.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
    }

    componentDidMount() {
        getOfferByToken().then(res => {
            var mixedPrice = false;
            if (res.result.is_rlm === "YES" && res.result.is_slp === "YES") mixedPrice = true;

            /* get netFee Prices */
            calculateNetFee(res.result.customer_id, res.result.id).then((result) => {
                if(result !== 'ERR'){
                    this.setState({
                        mixed_price: mixedPrice,
                        mediumType: res.result.medium_type,
                        offerID: res.result.id,
                        customerID: res.result.customer_id,
                        netFeeResult: result,
                        isLoaded: true
                    });
                }else{
                    this.apiErrorOccured('calculateNetFee', result);
                }
            });
        });
    }

    getGasValues = (gasQuality, controlArea) => {
        this.setState({
            gasQuality: gasQuality,
            controlArea: controlArea
        });
    };

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    getTariffPrices(tariff){
        if (tariff.tariff_type === "SPOT") {
            return <div>
                <div className={'font-s-18'} />
                <div className="tarifpreis preisgross">Spotpreis</div>
                <div className={'font-s-14'}> Siehe Preisbestandteile für mehr Informationen.</div>
                <div className="tarifpreis preisklein">
                    <br/>
                    <i className={"zzg"} />
                </div>
            </div>;
        } else{
            var kWhRate = tariff.price.price_rate +"";
            kWhRate = kWhRate.replace(" ct/kWh", ""); //Hizugefügt wegen einem Bug mit Gas Energiepreis
            kWhRate = kWhRate.replace(".", ",");
            var kWhSplit = kWhRate.split(",");
            if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 1) kWhRate =  kWhSplit[0] + "," + kWhSplit[1] + "00 ct/kWh";
            else if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 2) kWhRate =  kWhSplit[0] + "," + kWhSplit[1] + "0 ct/kWh";
            else if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length > 2) {
                var subString = kWhSplit[1].substring(0, 3);
                kWhRate  =  kWhSplit[0] + "," + subString +" ct/kWh";
            }else{
                kWhRate = kWhRate +" ct/kWh";
            }

            return <div>
                <div className={'font-s-18'}>Energiepreis:</div>
                <div className="tarifpreis preisgross">{kWhRate}</div>
                <div className={'font-s-18'}>Preis pro Jahr ca.:</div>
                <div className="tarifpreis preisklein">{tariff.price.yearly_price}
                    <br/>
                    <i className={"zzg"}>zzgl. Netzentgelte</i>
                </div>
            </div>;
        }
    }

    checkSocket(tariff){
        var socket = tariff.price.socket;
        //Die funktion checkSocket wird beim auswählen von Filter nochmal aufgerufen. Da der socket dann nicht mehr
        //int ist sondern string, muss die Variable nochmal darauf geprüft werden ob es ein string ist sonst würde
        //bei jedem tariff "keine Grundgebühr" dran stehen.
        if(socket > 0 || typeof socket === 'string'){
            // BASE VALUE /  SOCKET VALUE
            socket = socket+"";
            socket = socket.replace(".", ",");

            var socketSplit = socket.split(",");
            if (typeof socketSplit[1] === "undefined") {
                tariff.price.socket = socket +",00";
            } else if (socketSplit[1].length === 1){
                tariff.price.socket =  socketSplit[0] + "," + socketSplit[1] + "0";
            }else if (socketSplit[1].length > 2){
                var subString = socketSplit[1].substring(0, 2);
                tariff.price.socket   =  socketSplit[0] + "," + subString;
            }else{
                tariff.price.socket = socket + " ";
            }

            return <div>
                <span className={listItemClass + " mr-3"} key="v1" />Grundgebühr: {tariff.price.socket} €/Monat
            </div>
        }else{

            return <div>
                <span className={listItemClass + " mr-3"} key="v1" />Keine Grundgebühr
                </div>;
        }
    }

    getTranslation(translate){
        if (translate.includes("months"))return "Monate";
        else if (translate.includes("weeks"))return "Wochen";
        else if (translate.includes("month"))return "Monat";
        else if (translate.includes("week"))return "Woche";
        else return "N/A";
    }

    translateCancellationPeriod (cancellation) {
        var split = cancellation.split(' ');
        if (split[0] === "none") {
            return <div>
                <span className={listItemClass + " mr-3"} key="v2" />Kündigungsfrist: Festes Vertragsende
            </div>
        }else{
            var translated = this.getTranslation(split[1]);
            return <div>
                <span className={listItemClass + " mr-3"} key="v2" />Kündigungsfrist: {split[0] + " " + translated}
            </div>
        }
    }

    translateContractExtension (extension) {
        var split = extension.split(' ');
        if (split[0] === "none") {
            return <div>
                <span className={listItemClass + " mr-3"} key="v3" />Vertragsverlängerung: Keine Verlängerung
            </div>;
        }else{
            var translated = this.getTranslation(split[1]);
            return <div>
                <span className={listItemClass + " mr-3"} key="v3" />Vertragsverlängerung: {split[0] + " " + translated}
            </div>
        }

    }

    handleClick(tariffID){
        var dropdownElement = $('#tariffDetail'+tariffID);
        let spanElement = document.getElementById('moreDetailsSpan'+tariffID);
        let iconSpan = document.getElementById('details-icon'+tariffID);

        if (!dropdownElement.is(':animated')){
            if (iconSpan.classList.contains("o-angle-down-1")){
                toggleIcon(iconSpan,"o-angle-down-1", "o-angle-up-1");
                spanElement.innerHTML=  "Details ausblenden ";
            }else{
                toggleIcon(iconSpan, "o-angle-up-1", "o-angle-down-1");
                spanElement.innerHTML=  "Details einblenden ";
            }
            dropdownElement.slideToggle();
        }
    };

    handleSubmit(resID){
        createContract(resID, this.state.offerID, this.state.customerID).then(result => {
            if(result !== "ERR"){
                //redirect
                window.location.href = global.ConsumptionPointsInfoPage+'/'+this.props.match.key;
            }else{
                this.apiErrorOccured('calculationManagementService', result);
            }
        })
    };

    getTariffName(tariff){
        if (tariff.tariff_type === "SPOT") {
            return tariff.name;
        }else{
            return tariff.name;
        }
    }

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    render(){
        const tariffs = this.props.tariffs;
        var hideSubmitBtn = this.props.hideSubmitBtn;
        var filters = this.props.allFilter;
        var filterIsSet = 0;


        for(var prop in filters) {
            if (filters.hasOwnProperty(prop)) {
                filterIsSet = 1;
            }
        }

        var tariffElements;
        if(tariffs.length > 0 && tariffs !== "[]" && this.state.isLoaded === true){
            tariffElements = tariffs.map((tariff, id) =>
                <div key={id} className={'mb-3'}>
                    <div>
                        {/* Desktop Ansicht */}
                        <div id={'desktop'+tariff.id} key={tariff.id} className="tarifblock position-relative">
                            <div className={'row'}>
                                <div className={'col-md-3 d-none d-md-block pr-0'}>
                                    <div id={'tariffWrapper'+tariff.id} className={'tariff-img-wrapper'}>
                                        <img alt="logo" className={"elessLogo"} src={"https://api2.eless.de/static_file?file_id=" + tariff.supplier.information.logo_file_id}/>
                                    </div>
                                </div>
                                <div className={'col-md-5 col-sm-6 tariff-basic-info'}>
                                    <div className={'row'}>
                                        <div className={'col-md-12 tariff-name'}><h3>{this.getTariffName(tariff)}</h3></div>
                                        <div className={'col-md-12 eless-ul'}>
                                            {this.checkSocket(tariff)}
                                            {this.translateCancellationPeriod(tariff.tariff_condition.cancellation_period)}
                                            {this.translateContractExtension(tariff.tariff_condition.contract_extension)}
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-4 col-sm-6 tariff-price-info'}>
                                    {this.getTariffPrices(tariff)}
                                    {hideSubmitBtn ? (
                                            <span/>
                                        ) : (
                                        <button type='submit' className={'eless-btn eless-cta-btn tarif-btn'} onClick={this.handleSubmit.bind(this, tariff.offer_result_id)}
                                                id='consumptionPointsCreate'>Vertrag abschließen
                                            <FontAwesomeIcon className={'fa-spin fa-2x d-none'} id={"loadingLoadProfileSpinner"} icon={faSpinner}/></button>
                                        )}
                                </div>
                            </div>
                            <div className={'row mt-1'}>
                                <div className={'col-md-3'} />
                                <div onClick={this.handleClick.bind(this, tariff.id)} className={'col-lg-12 col-md-12 show-details d-none d-md-block'}>
                                    <span id={'moreDetailsSpan'+tariff.id}>Details einblenden </span>
                                    <span className={"o-angle-down-1 details-icon"} id={"details-icon" + tariff.id}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={'tariffDetail' + tariff.id} className={'tariffDetailContainer'}>
                        <Tabs id="detailTabs">
                            <Tab eventKey="allgemeines" title="Allgemeines">
                                <TariffInformation offerID={this.props.offerID} customerID={this.props.customerID} tariff={tariff} gasQuality={this.state.gasQuality} controlArea={this.state.controlArea} />
                            </Tab>
                            <Tab eventKey="preisbestandteile" title="Preisbestandteile">
                                <PriceInformation page={this.props.page} apiErrorOccured={this.apiErrorOccured} mixedPrice={this.state.mixed_price} netFeeResults={this.state.netFeeResult} getGasValues={this.getGasValues} spot={tariff.tariff_type} offerID={this.props.offerID} customerID={this.props.customerID} priceRate={tariff.price.price_rate} tarifID={tariff.id} yearlyPrice={tariff.price.yearly_price} socket={tariff.price.socket} />
                            </Tab>
                            <Tab eventKey="ueberDenAnbieter" title="Über den Anbieter">
                                <ProviderInformation offerID={this.props.offerID} customerID={this.props.customerID}  tariff={tariff}  />
                            </Tab>
                            <Tab eventKey="mehrInformationen" title="Mehr Informationen">
                                <MoreInformation offerID={this.props.offerID} mediumType={this.state.mediumType} customerID={this.props.customerID}  tariff={tariff}  />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            )
        }
        else if(filterIsSet === 1 && (tariffs.length === 0 || tariffs === "[]")){
            tariffElements =
                <div>
                    <div className="tarifblock rounded-tarif no-tariff">
                        Es wurden keine passenden Tarife für Sie gefunden.<br/>
                        Rufen Sie uns gerne unter <u>0821 · 89 99 98-0</u> an und lassen Sie sich ein persönliches Angebot machen.
                    </div>
                </div>
        }else{
            tariffElements = <LoadingAnimationDotted text={'Tarife werden geladen'} hideclass={this.state.showLoadingAnimation} />
        }

        return(
            <div>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                {tariffElements}
            </div>
        )
    }
}

export default Tariffs;