import React from "react";
import InfoIcon from "../media/Information.png";
import $ from "jquery";
import SpeechBubble from "./SpeechBubble";
import '../global';

export default class SlpType extends React.Component {
    constructor () {
        super();
        this.state = {
            showSlpType: false,
            electricityProfiles: [],
            selectedValue: '',
            handleChange: '',
        };
        this.displayInfo = this.displayInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
        this.setState({selectedValue: event.target.value});
    }

    componentDidMount() {
        this.setState({selectedValue: this.props.slpType});

        if(this.props.display !== 'hide'){
            this.setState({showSlpType: true})
        }

        var options = [];

        if(this.props.lastType === "SLP")   {
            let count = 0;
            for(let key in global.slpProfiles) {
                if(global.slpProfiles.hasOwnProperty(key)) {
                    if (global.slpProfiles[key] === this.props.slpType)
                        options.push(<option value={key} key={count} selected>{global.slpProfiles[key]}</option>);
                    else
                        options.push(<option value={key} key={count}>{global.slpProfiles[key]}</option>);
                    count++;
                }
            }
        }

        else if(this.props.lastType === "RLM")   {
            let count = 0;

            for(let key in global.rlmCodes) {
                if(global.rlmCodes.hasOwnProperty(key)) {
                    if (global.rlmCodes[key] === this.props.slpType)
                        options.push(<option value={key} key={count} selected>{global.rlmCodes[key]}</option>);
                    else
                        options.push(<option value={key} key={count}>{global.rlmCodes[key]}</option>);
                    count++;
                }
            }
        }

        this.setState({electricityProfiles: options});
    }

    displayInfo (e) {
        var arr = e.target.id.split('-');
        var elementName = arr[0];
        var infoBox = $('#' + elementName + '-info');
        if (infoBox.is(":visible")){
            infoBox.fadeOut();
        }else{
            infoBox.hide().fadeIn();
            infoBox.show();
        }
    }

    render() {
        let infoBoxText;

        if(this.props.lastType === 'SLP'){
            if(this.props.energyType === 'GAS'){
                infoBoxText = 'Bitte wählen Sie eine der aufgeführten Branchen aus, die Ihrer am nächsten kommt. Sofern keine zutreffende Auswahl möglich ist, wählen Sie bitte "Sonstige".';
            }else{
                infoBoxText = 'Bitte wählen Sie eine der aufgeführten Branchen aus, die Ihrer am nächsten kommt. Sofern keine zutreffende Auswahl möglich ist, wählen Sie bitte "Sonstige".';
            }
        }else{
            if(this.props.energyType === 'GAS'){
                infoBoxText = 'Falls Sie über keinen Lastgang für Ihre RLM-Abnahmestelle verfügen, wählen Sie bitte eine der aufgeführten Branchen aus, die Ihrer am nächsten kommt. Sofern keine zutreffende Auswahl möglich ist, wählen Sie bitte "Sonstige".';
            }else{
                infoBoxText = 'Falls Sie über keinen Lastgang für Ihre RLM-Abnahmestelle verfügen, wählen Sie bitte eine der aufgeführten Branchen aus, die Ihrer am nächsten kommt. Sofern keine zutreffende Auswahl möglich ist, wählen Sie bitte "Sonstige".';
            }
        }

        return (
            <div id='abnahmeprofil-slp' className={'col-md-12 p-0 ' + this.props.display}>
                <h4 className='select-label mb-0'>Abnahmeprofil (Branche)
                    <span className={'info-wrapper'} >
                        <img src={InfoIcon} onClick={this.displayInfo} alt='info-icon' id={'type'+ this.props.lastType + '-info-icon'} className='info-icon' />
                        <SpeechBubble id={'type'+ this.props.lastType + '-info'} content={infoBoxText} />
                    </span>
                </h4>

                <div id="myProgress">
                    <div id={this.props.myBarID} className={'myBar'}>
                        <span id={"progressValue"}/>
                    </div>
                </div>
                <select className='slpProfileSelect custom-select' id={this.props.id} name='abnahmeprofil-slp' onChange={this.handleChange} value={this.state.selectedValue}>
                    {this.state.electricityProfiles}
                </select>
                <div className={'col-md-12 alert-text d-none popupErrorMessage'} id={'profileMessage'+this.props.lastType}>Es ist ein Problem mit dem Abnahmeprofil aufgetreten. Versuchen Sie es bitte später noch einmal oder wählen Sie ein anderes Profil aus.</div>
            </div>
        );
    }
}