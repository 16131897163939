import React from 'react';
import LastType from "./LastType";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../global';
import $ from "jquery";
import InfoIcon from "../media/Information.png";
import SpeechBubble from "./SpeechBubble";
import loadingAnimationFirstSet from "../functions/loadingAnimationFirstSet";
import loadingAnimationLastSet from "../functions/loadingAnimationLastSet";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import getCities from "../fetches/getCities";
import createCitySelectOptions from "../functions/createCitySelectOptions";
import getStreetsArray from "../functions/getStreetsArray";
import predictStandardGasProfile from "../fetches/predictStandardGasProfile";
import predictStandardElectricityProfile from "../fetches/predictStandardElectricityProfile";
import getStreets from "../fetches/getStreets";
import createConsumptionPoint from "../fetches/createConsumptionPoint";
import modifyConsumptionPoint from "../fetches/modifyConsumptionPoint";

/* Das ist die PopUp Box mit einer externen Funktion in der berechnet wird ob der Button eine bestehende Abnahmestelle
* updaten oder eine neue abspeichern soll. Hier befindet sich die komplette create und edit logik der Abnahmestellen. */

function SubmitAddCP(props) {
    return <button type='submit' className='eless-weiter-btn' onClick={props.event}
                   id='consumptionPointsCreate'>Weiter <span className={'ml-4 o-arrow_right01-1'} /> <FontAwesomeIcon className={'fa-spin fa-2x d-none'} id={"loadingLoadProfileSpinner"} icon={faSpinner}/></button>;
}

export default class PopupBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,               // false = popup off
            kwhRate: '',                    // form fields  |
            postalCode: '',                 //              V
            cityName: '',                   // City string
            city: '',                       // City select options object
            houseNumber: '',
            street: '',
            csPointState: '',
            slpType: '',
            loadProfileCheck: false,
            voltageLevel: '',
            uploadedFileName: 'Lastgang hier platzieren oder auswählen',
            slp: true,
            rlm: false,
            url: "",
            streetsArray: [],
            currentFocus: -1,
            createCspFinishedLoading: true,
            loadProfileValidated: false,
            showLoadProfile: false,
            unnecessaryPropsRecieved: 0,
            apiError: false,
        };
        this.createBoxSubmit = this.createBoxSubmit.bind(this);
        this.editBoxSubmit = this.editBoxSubmit.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleKwhRate = this.handleKwhRate.bind(this);
        this.delkWhString = this.delkWhString.bind(this);
        this.addkWhString = this.addkWhString.bind(this);
        this.handleChangePostalCode = this.handleChangePostalCode.bind(this);
        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.closeAllLists = this.closeAllLists.bind(this);
        this.removeActive = this.removeActive.bind(this);
        this.addActive = this.addActive.bind(this);
        this.handleKeydownStreet = this.handleKeydownStreet.bind(this);
        this.callbackAboutCheckbox = this.callbackAboutCheckbox.bind(this);
        this.callbackAboutFileName = this.callbackAboutFileName.bind(this);
        this.callbackAboutLastType = this.callbackAboutLastType.bind(this);
        this.insertCspIntoDB = this.insertCspIntoDB.bind(this);
        this.checkRlmValues = this.checkRlmValues.bind(this);
        this.validateConsumptionPointUpdate = this.validateConsumptionPointUpdate.bind(this);
        this.displayInfo = this.displayInfo.bind(this);
        this.handleChangeHouseNumber = this.handleChangeHouseNumber.bind(this);
        this.handleChangeSLP = this.handleChangeSLP.bind(this);
        this.handleChangeRLM = this.handleChangeRLM.bind(this);
        this.afterInsertActions = this.afterInsertActions.bind(this);
        this.afterChanges = this.afterChanges.bind(this);
        this.fetchElectricityCspoints = this.fetchElectricityCspoints.bind(this);
        this.fetchGasCspoints = this.fetchGasCspoints.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
        this.resetFieldValues = this.resetFieldValues.bind(this);
    }

    editBoxSubmit (id){
        var kwhObj = document.getElementById('kwh');
        var kwh = kwhObj.value.replace(" kWh", "");
        kwh = kwh.replace(/\./g, "");

        var postalCode = document.getElementById('postalCode');
        var city = document.getElementById('city');
        var street = document.getElementById('street');
        var houseNumber = document.getElementById('houseNumber');
        var lasttypRadios = document.getElementsByName('lasttyp');
        var loadProfileFake = document.getElementById('fake-upload');

        let loadProfile = document.getElementById('upload');
        let slpRadio = document.getElementById('slp');
        let noLpCheck = document.getElementById('lastgangfile');
        var spannungsebene = document.getElementById('spannungsebene');

        var interval = '';
        const that = this;

        //Disable all error messages
        $('.alert-border').removeClass('alert-border');
        let elements = document.getElementsByClassName('popupErrorMessage');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('d-none');
        }

        //Validate Values
        let exit;
        exit = this.validateConsumptionPointUpdate(kwh, kwhObj, postalCode, city, street, houseNumber, lasttypRadios, loadProfileFake, loadProfile, slpRadio, noLpCheck, this.state.loadProfileID);
        if(exit){
            return 0;
        }

        //disable submit btn and show loading animation in btn
        var submitBtn = document.getElementById("consumptionPointsCreate");
        var loadingLoadProfileSpinner = document.getElementById("loadingLoadProfileSpinner");
        submitBtn.disabled = true;
        loadingLoadProfileSpinner.classList.remove('d-none');
        let progressBar = document.getElementById('myBar');

        //Create new Object in csPts array which will be updated to DB
        let csPts = this.props.csPoints;
        let fileID = this.state.loadProfileID;
        let predictionID = this.state.predictionID;
        var state =  csPts[id].state;
        csPts[id].load_profile_id = fileID;
        csPts[id].prediction_id = predictionID;
        csPts[id].mean_consumption = this.state.kwhRate;
        csPts[id].postal_code = parseInt(this.state.postalCode);
        csPts[id].city = this.state.cityName;
        csPts[id].street = street.value;
        csPts[id].house_number = this.state.houseNumber;

        var gasRadioBtn = document.getElementById('gas');
        var mediumType = "ELECTRICITY";
        if(gasRadioBtn.checked === true) mediumType = 'GAS';

        var rlm = document.getElementById('rlm');

        if(mediumType === "ELECTRICITY"){ // STROM
            if(rlm.checked){
                csPts[id].consumption_type = 'RLM';
                csPts[id].extended_information.specification = parseInt(spannungsebene.options[spannungsebene.selectedIndex].value); //0 / 1 / 2

                if(noLpCheck.checked){ //Electricity without loadProfile
                    var slpTypeRLM =  document.getElementById('slpTypeRLM');
                    var selectedBrancheRlm = slpTypeRLM.options[slpTypeRLM.selectedIndex].value;
                    csPts[id].extended_information = {business_type: selectedBrancheRlm};
                    csPts[id].load_profile_file_name = null;

                    //check if we need to update the business typ -> predict new load profile if true
                    //elements for loading animation
                    let progressBar = document.getElementById('myBar3');
                    slpTypeRLM.classList.add("add-white");
                    interval = loadingAnimationFirstSet(progressBar);
                    predictStandardElectricityProfile(this.customerID, kwh, selectedBrancheRlm, "RLM", state).then(result => {
                        if(result !== 'ERR'){
                            csPts[id].load_profile_id = result.load_profile_id;
                            csPts[id].prediction_id = result.prediction_id;

                            //timeout so the modal is not closing before the loading bar reaches the end
                            loadingAnimationLastSet(progressBar);
                            setTimeout(function() {
                                //set props / close modal / reset state vars
                                that.afterChanges(csPts, id, loadingLoadProfileSpinner);
                            }, 1000);
                        }else{
                            this.apiErrorOccured('predictStandardGasProfile', result);
                            clearInterval(interval);
                        }
                    });
                }else{ // ELECTRICITY with loadProfile
                    //file name is only set for the visible file name on consumptionPoint edititing
                    csPts[id].load_profile_file_name = this.state.uploadedFileName;
                    csPts[id].extended_information.business_type =  "";
                    csPts[id].load_profile_id = this.state.loadProfileID;
                    csPts[id].prediction_id = this.state.predictionID;
                    that.afterChanges(csPts, id, loadingLoadProfileSpinner);
                }
            }else{ // ELECTRICITY SLP
                csPts[id].consumption_type = 'SLP';
                csPts[id].extended_information.specification = 0;

                var slpTypeSLP =  document.getElementById('slpTypeSLP');
                var selectedBrancheSlp = slpTypeSLP.options[slpTypeSLP.selectedIndex].value;
                csPts[id].extended_information = {business_type: selectedBrancheSlp};
                csPts[id].load_profile_file_name = null;

                slpTypeSLP.classList.add("add-white");
                // LOADING BAR ANIMATION
                //elements for loading animation

                interval = loadingAnimationFirstSet(progressBar);
                // Predict Selected SLP Profile Function
                predictStandardElectricityProfile(this.customerID, kwh, selectedBrancheSlp, "SLP", state).then((result) => {
                    if(result !== "ERR"){
                        //load rest of loadingbar if profile is predicted (with less delay)
                        csPts[id].load_profile_id = result.load_profile_id;
                        csPts[id].prediction_id = result.prediction_id;
                        that.afterChanges(csPts, id, loadingLoadProfileSpinner);
                        loadingAnimationLastSet(progressBar);
                    }else{
                        this.apiErrorOccured('predictStandardGasProfile', result);
                        clearInterval(interval);
                    }
                });
            }
        }else{//mediumType = GAS
            if(rlm.checked){ // GAS RLM
                csPts[id].consumption_type = 'RLM';
                csPts[id].extended_information.specification = parseInt(spannungsebene.options[spannungsebene.selectedIndex].value); //0 / 1 /2
                //file name is only set for the visible file name on consumptionPoint edititing
                csPts[id].load_profile_file_name = this.state.uploadedFileName;
                csPts[id].extended_information.business_type =  "";
                csPts[id].load_profile_id = this.state.loadProfileID;
                csPts[id].prediction_id = this.state.predictionID;
                that.afterChanges(csPts, id, loadingLoadProfileSpinner);
            }else{ // GAS SLP
                var slpTypeSLPGas =  document.getElementById('slpTypeSLP');
                var selectedBrancheSlpGas = slpTypeSLPGas.options[slpTypeSLPGas.selectedIndex].value;
                csPts[id].consumption_type = 'SLP';
                csPts[id].extended_information.business_type = selectedBrancheSlpGas;
                csPts[id].extended_information.specification = 0;
                csPts[id].load_profile_file_name = null;

                slpTypeSLPGas.classList.add("add-white");
                // LOADING BAR ANIMATION
                //elements for loading animation
                let progressBar = document.getElementById('myBar');

                interval = loadingAnimationFirstSet(progressBar);
                // Predict Selected SLP Profile Function
                predictStandardGasProfile(this.customerID, kwh, selectedBrancheSlpGas, "SLP", state, csPts[id].postal_code).then((result) => {
                    if(result !== "ERR"){
                        //load rest of loadingbar if profile is predicted (with less delay)
                        csPts[id].load_profile_id = result.load_profile_id;
                        csPts[id].prediction_id = result.prediction_id;
                        that.afterChanges(csPts, id, loadingLoadProfileSpinner);
                        loadingAnimationLastSet(progressBar);
                    }else{
                        this.apiErrorOccured('predictStandardGasProfile', result);
                        clearInterval(interval);
                    }
                });
            }
        }
    }

    afterChanges(csPts, id, loadingLoadProfileSpinner){
        modifyConsumptionPoint(csPts[id], this.customerID, this.state.csPointState).then((result) => {
            if(result === "ERR"){
                this.apiErrorOccured('modifyConsumptionpoint', result);
            }

            loadingLoadProfileSpinner.classList.add('d-none');

            this.props.setCsPoints(csPts);
            this.props.unsetEditedCsPoint();
            this.props.closeModal();
        });

        //Unset State to prevent prefilled fields inside the popupbox on the next opening
        this.setState({
            kwhRate: '',
            postalCode: '',
            city: '',
            street: '',
            houseNumber: '',
            uploadedFileName: '',
            loadProfileID: '',
            predictionID: '',
            slp: true,
            rlm: false
        });
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    displayInfo (e) {
        var arr = e.target.id.split('-');
        var elementName = arr[0];
        var infoBox = $('#' + elementName + '-info');

        if (infoBox.is(":visible")){
            infoBox.fadeOut();
        }else{
            infoBox.fadeIn();
        }
    }

    delkWhString(e){
        if(e.target.value.indexOf(" kWh") >= 0){
            var kwh = e.target.value.replace(" kWh", "");
            this.setState({ kwhRate: kwh });
        }
    }

    addkWhString(e){
        var kwh;
        if(e.target.value === '' || e.target.value === "kWh") {
            kwh = e.target.value.replace("kWh", "");
            this.setState({kwhRate: kwh});
        }else{
            kwh = e.target.value + " kWh";
            this.setState({ kwhRate: kwh });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({showModal: nextProps.showModal});
        this.customerID = nextProps.customerID;
        // Wenn man auf "Abnahmestelle bearbeiten" gedrückt hat:
        if(nextProps.editedCsPoint !== ''){ // editing consumptionpoint
            var csPt = nextProps.csPoints[nextProps.editedCsPoint];
            this.handleChangePostalCode('', csPt.postal_code);

            var cityOptions;
            getCities(csPt.postal_code).then(result => {
                if(result !== "ERR"){
                    cityOptions = createCitySelectOptions(result);
                }else{
                    this.apiErrorOccured('getCities', result);
                }
            });

            this.setState({
                kwhRate: csPt.mean_consumption,
                postalCode: csPt.postal_code,
                city: cityOptions,
                cityName: csPt.city,
                streetOptions: <input autoComplete="new-password" onKeyDown={this.handleKeydownStreet} type='text' id='street' value={csPt.street}
                               onChange={this.handleStreetChange} placeholder='Straße'/>,
                street: csPt.street,
                houseNumber: csPt.house_number,
            });
            //Radio Buttons logik bezüglich der Anzeige der restlichen Felder
            if (csPt.consumption_type === 'RLM') { //RLM
                //slideDown rlm LG info
                this.setState({
                    slp: false,
                    rlm: true,
                    voltageLevel: csPt.extended_information.specification,
                    currentSLP: false
                });
                if (csPt.extended_information.business_type.length > 0) { // RLM without loadPorfile
                    this.setState({
                        loadProfileCheck: true,
                        slpType: csPt.extended_information.business_type,
                        currentBusinessType: csPt.extended_information.business_type
                    });
                } else { // RLM with loadProfile
                    this.setState({
                        loadProfileCheck: false,
                        loadProfileID: csPt.load_profile_id,
                        predictionID: csPt.prediction_id,
                        uploadedFileName: csPt.load_profile_file_name,
                        currentBusinessType: "",
                        voltageLevel: csPt.extended_information.specification
                    });
                }
            } else { // SLP
                this.setState({
                    slp: true,
                    rlm: false,
                    slpType: csPt.extended_information.business_type,
                    currentBusinessType: csPt.extended_information.business_type,
                    currentSLP: true,
                    currentLoadProfile: csPt.load_profile_id,
                    currentPrediction: csPt.prediction_id
                });
            }
        }else{ // creating new consumptionpoint
            if(!this.state.unnecessaryPropsRecieved){
                this.resetFieldValues();
            }
        }
    }

    resetFieldValues(){
        //Damit bei neuöffnen der Popubox die Werte von vorher nicht vorausgefüllt sind
        this.setState({
            kwhRate: '',
            postalCode: '',
            city: '',
            street: '',
            houseNumber: '',
            slpType: '',
            csPointState: '',
            loadProfileCheck: false,
            voltageLevel: '',
            uploadedFileName: 'Lastgang hier platzieren oder auswählen',
            slp: true,
            rlm: false,
        });
    }

    validateConsumptionPointUpdate(kwh, kwhObj, postalCode, city, street, houseNumber, lasttypRadios, loadProfileFake, loadProfile, slpRadio, noLpCheck, loadProfileID = ''){
        let kwhMessage = document.getElementById('kwhRateMessage');
        let kwhRateTooLowSlp = document.getElementById('kwhRateTooLowSlp');
        let kwhRateTooLowRlm = document.getElementById('kwhRateTooLowRlm');
        let kwhRateTooHighNoLp = document.getElementById('kwhRateTooHighNoLp');
        let kwhRateTooHighSlp = document.getElementById('kwhRateTooHighSlp');
        let postalCodeMessage = document.getElementById('postalCodeMessage');
        let streetMessage = document.getElementById('streetMessage');

        //Validation
        let exit = 0;
        if(kwh === ''){
            kwhObj.classList.add('alert-border');
            kwhMessage.classList.remove('d-none');
            exit = 1;
        }else{
            kwh = parseInt(kwh);

            if(this.props.energyType === 'ELECTRICITY'){
                if(slpRadio.checked){//SLP
                    if(kwh > 150000){
                        kwhObj.classList.add('alert-border');
                        kwhRateTooHighSlp.classList.remove('d-none');
                        exit = 1;
                    }else  if(kwh < 6000){
                        kwhObj.classList.add('alert-border');
                        kwhRateTooLowSlp.classList.remove('d-none');
                        exit = 1;
                    }
                }else{ // RLM
                    if(kwh < 30000){
                        kwhObj.classList.add('alert-border');
                        kwhRateTooLowRlm.classList.remove('d-none');
                        exit = 1;
                    }
                    else if (noLpCheck.checked){ //user has no Loadprofile
                        if(kwh > 500000){
                            kwhObj.classList.add('alert-border');
                            kwhRateTooHighNoLp.classList.remove('d-none');
                            exit = 1;
                        }
                    }
                }
            } else if(this.props.energyType === 'GAS'){
                if(slpRadio.checked){//SLP
                    if(kwh > 1500000){
                        kwhObj.classList.add('alert-border');
                        kwhRateTooHighSlp.classList.remove('d-none');
                        exit = 1;
                    }else  if(kwh < 10000){
                        kwhObj.classList.add('alert-border');
                        kwhRateTooLowSlp.classList.remove('d-none');
                        exit = 1;
                    }
                }else{ // RLM
                    if(kwh < 1500000){
                        kwhObj.classList.add('alert-border');
                        kwhRateTooLowRlm.classList.remove('d-none');
                        exit = 1;
                    }
                }
            }
        }

        if(postalCode.value.length !== 5){
            postalCode.classList.add('alert-border');
            postalCodeMessage.classList.remove('d-none');
            exit = 1;
        }else{
            if (city.classList.contains("d-none")){
                postalCode.classList.add('alert-border');
                postalCodeMessage.classList.remove('d-none');
                exit = 1;
            }
        }

        if(street.value === ''){
            street.classList.add('alert-border');
            streetMessage.classList.remove('d-none');
            exit = 1;
        }
        // If RLM selected and loadProfile checkbox is not checked, check if loadProfile uploaded
        // loadProfile checkbox: "Ich besitze keine Lastgang Datei"
        if(lasttypRadios[1].checked && !this.state.loadProfileCheck && this.state.showLoadProfile !== false){
            if(loadProfile.value === '' && loadProfileID === ''){
                var errorMessage = document.getElementById('error-message-fupload');
                var errorMessage2 = document.getElementById('error-message-no-loadprofile');
                if(!errorMessage.classList.contains('d-none')){
                    errorMessage.classList.add('d-none');
                }
                if(errorMessage2.classList.contains('d-none')){
                    errorMessage2.classList.remove('d-none');
                }

                if(!loadProfileFake.classList.contains('alert-border')){
                    loadProfileFake.classList.add('alert-border');
                }

                exit = 1;
            }
        }

        let houseNumberVal = houseNumber.value.replace(" ", "");
        if(houseNumberVal === '') {
            houseNumber.classList.add('alert-border');
            streetMessage.classList.remove('d-none');
            exit = 1;
            // }else if (!houseNumberVal.match(/^\d+[a-zA-Z]*$/)){
            //     houseNumber.classList.add('alert-border');
            //     streetMessage.classList.remove('d-none');
            //     exit = 1;
            // }
        }

        if(exit){
            return 1;
        }

        else return 0;
    }
    
    //close modal and delete old state values (delete because it can autofill new cps on create)
    afterInsertActions(loadingLoadProfileSpinner, cpID = 0){
        setTimeout(function(){
            this.props.selectNewCsPoint(cpID);
            this.props.closeModal();

            this.setState({
                kwhRate: '',
                postalCode: '',
                city: '',
                street: '',
                houseNumber: '',
                voltageLevel: '',
                uploadedFileName: 'Lastgang hier platzieren oder auswählen',
                slp: true,
                rlm: false,
            });
            loadingLoadProfileSpinner.classList.add('d-none');
        }.bind(this), 2000);
    }

    createBoxSubmit (){
        var kwhObj = document.getElementById('kwh');
        var postalCode = document.getElementById('postalCode');
        var city = document.getElementById('city');
        var street = document.getElementById('street');
        var houseNumber = document.getElementById('houseNumber');
        let lasttypRadios = document.getElementsByName('lasttyp');
        let loadProfileFake = document.getElementById('fake-upload');
        let loadProfile = document.getElementById('upload');
        let slpRadio = document.getElementById('slp');
        let noLpCheck = document.getElementById('lastgangfile');
        let fileID = this.state.loadProfileID;
        let predictionID = this.state.predictionID;

        let cspArray;
        let state = city.options[city.selectedIndex].dataset.state;
        let createdCsPointObject = {};
        let exit;
        var energyType = this.props.energyType;
        $('.alert-border').removeClass('alert-border');

        let elements = document.getElementsByClassName('popupErrorMessage');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('d-none');
        }

        var kwh = kwhObj.value.replace(" kWh", "");
        kwh = kwh.replace(/\./g, "");

        exit = this.validateConsumptionPointUpdate(kwh, kwhObj, postalCode, city, street, houseNumber, lasttypRadios, loadProfileFake, loadProfile, slpRadio, noLpCheck, predictionID);

        //If error 1, stop this function here
        if(exit){
            return 0;
        }

        //Insert csPoint into Array and Database with same ID
        var lastType;

        if(lasttypRadios[0].checked){
            lastType = lasttypRadios[0].value;
        }else{
            lastType = lasttypRadios[1].value;
        }

        //Get all CSPoints to add additional CSPoint into the array
        cspArray = this.props.csPoints;

        // TODO wird in der API unterschieden
        createdCsPointObject.mean_consumption = this.state.kwhRate;
        createdCsPointObject.postal_code = postalCode.value;
        createdCsPointObject.house_number = houseNumber.value;
        createdCsPointObject.state = state;
        createdCsPointObject.street = street.value;
        // LOADING WHEEL CODE
        //buttons and icons in button for loading wheel
        var submitBtn = document.getElementById("consumptionPointsCreate");
        //loading spinner in button
        var loadingLoadProfileSpinner = document.getElementById("loadingLoadProfileSpinner");

        //disable submit btn and show loading animation in btn
        submitBtn.disabled = true;
        loadingLoadProfileSpinner.classList.remove('d-none');
        //service for lp prediction
        if (energyType === "GAS") this.fetchGasCspoints(lastType, kwh, state, createdCsPointObject, city, street, energyType, cspArray, loadingLoadProfileSpinner, postalCode, houseNumber, fileID, predictionID);
        else this.fetchElectricityCspoints(lastType, kwh, state, createdCsPointObject, city, street, energyType, cspArray, loadingLoadProfileSpinner, postalCode, houseNumber, fileID, predictionID);
    }

    fetchGasCspoints(lastType, kwh, state, createdCsPointObject, city, street, energyType, cspArray, loadingLoadProfileSpinner, postalCode, houseNumber, fileID, predictionID){
        var cpID;
        const that = this;
        if(lastType === 'SLP'){
            //Create CSPoint SLP GAS
            var abnahmeprofilSLP =  document.getElementById('slpTypeSLP');
            var selectedSLP = abnahmeprofilSLP.options[abnahmeprofilSLP.selectedIndex].value;
            var profileMessage = document.getElementById('profileMessageSLP'); //error message

            // LOADING BAR ANIMATION
            //elements for loading animation
            let progressBar = document.getElementById('myBar');
            //vars for setting loading bar
            abnahmeprofilSLP.classList.add("add-white");
            var interval = loadingAnimationFirstSet(progressBar);

            // Predict Selected SLP Profile Function
            predictStandardGasProfile(this.customerID, kwh, selectedSLP, lastType, state, postalCode.value).then(firstResult => {
                if (firstResult !== "ERR") {
                    //remove error if displayed
                    if(abnahmeprofilSLP.classList.contains('alert-border')){
                        abnahmeprofilSLP.classList.remove('alert-border');
                        if(!profileMessage.classList.contains('d-none')){
                            profileMessage.classList.add('d-none');
                        }
                    }

                    this.insertCspIntoDB([createdCsPointObject.postal_code, city.value, street.value,
                        createdCsPointObject.house_number, lastType, 0, state, firstResult.load_profile_id,
                        firstResult.prediction_id, selectedSLP, energyType]).then((secondResult) => {
                       if(secondResult !== "ERR"){
                           cpID = secondResult.id;
                           createdCsPointObject.city = city.value;
                           createdCsPointObject.consumption_type = lastType;
                           createdCsPointObject.extended_information = {business_type: selectedSLP};
                           createdCsPointObject.id = cpID;
                           cspArray.push(createdCsPointObject);
                           loadingAnimationLastSet(progressBar);
                           that.props.setCsPoints(cspArray);
                           //timeout so the modal is not closing before the loading bar reaches the end
                           setTimeout(function() {
                               //set props / close modal / reset state vars
                               that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                           }, 1000);
                       }else{
                           this.apiErrorOccured('createConsumptionpoint', secondResult);
                           that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                       }
                    });
                }else{
                    var loadingBarBackground = document.getElementById("myBar");
                    loadingBarBackground.style.backgroundColor = 'transparent';
                    var submitBtn = document.getElementById("consumptionPointsCreate");
                    clearInterval(interval);
                    profileMessage.classList.remove('d-none');
                    abnahmeprofilSLP.classList.add("alert-border");
                    abnahmeprofilSLP.classList.remove("add-white");
                    submitBtn.disabled = false;
                    this.apiErrorOccured('predictStandardGasProfile', firstResult);
                }
            });
        }else{ //Create RLM GAS CP
            let voltageLevelSelect = document.getElementById('spannungsebene');
            let voltageLevel = voltageLevelSelect.options[voltageLevelSelect.selectedIndex].value;
            //Create CSPoint without loadProfile

             //Create CSPoint with loadProfile
            var file = this.state.uploadedFileName;
            this.insertCspIntoDB([postalCode.value, city.value, street.value, houseNumber.value, lastType, voltageLevel, state, fileID, predictionID, null, energyType]).then((result) => {
                if(result !== "ERR"){
                    cpID = result.id;
                    createdCsPointObject.city = city.value;
                    createdCsPointObject.consumption_type = lastType;
                    createdCsPointObject.id = cpID;
                    createdCsPointObject.extended_information = {business_type: ""};
                    createdCsPointObject.extended_information.specification = parseInt(voltageLevel);
                    createdCsPointObject.load_profile_file_name = file;
                    createdCsPointObject.fileID = fileID;
                    createdCsPointObject.noLpCheckbox = false;
                    cspArray.push(createdCsPointObject);
                    this.props.setCsPoints(cspArray);
                    this.afterInsertActions(loadingLoadProfileSpinner, cpID);
                }else{
                    this.apiErrorOccured('createConsumptionpoint', result);
                    that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                }
            });
        }
    }

   fetchElectricityCspoints(lastType, kwh, state, createdCsPointObject, city, street, energyType, cspArray, loadingLoadProfileSpinner, postalCode, houseNumber, fileID, predictionID){
        var cpID;
        const that = this;
        var interval;

        if(lastType === 'SLP'){
            //Create CSPoint SLP
            var abnahmeprofilSLP =  document.getElementById('slpTypeSLP');
            var selectedSLP = abnahmeprofilSLP.options[abnahmeprofilSLP.selectedIndex].value;

            // LOADING BAR ANIMATION
            //elements for loading animation
            let progressBar = document.getElementById('myBar');
            //loading animation
            abnahmeprofilSLP.classList.add("add-white");
            interval = loadingAnimationFirstSet(progressBar);
            var profileMessageSLP = document.getElementById('profileMessageSLP'); //error message

            // Predict Selected SLP Profile Function
            predictStandardElectricityProfile(this.customerID, kwh, selectedSLP, lastType, state).then(firstResult => {
                if (firstResult !== "ERR") {
                    //remove error if displayed
                    if(abnahmeprofilSLP.classList.contains('alert-border')){
                        abnahmeprofilSLP.classList.remove('alert-border');
                        if(!profileMessageSLP.classList.contains('d-none')){
                            profileMessageSLP.classList.add('d-none');
                        }
                    }

                    //insert
                    this.insertCspIntoDB([createdCsPointObject.postal_code, city.value, street.value, createdCsPointObject.house_number, lastType, 0, state, firstResult.load_profile_id, firstResult.prediction_id, selectedSLP, energyType]).then((secondResult) => {
                        if (secondResult !== "ERR") {
                            cpID = secondResult.id;
                            createdCsPointObject.city = city.value;
                            createdCsPointObject.consumption_type = lastType;
                            createdCsPointObject.extended_information = {business_type: selectedSLP};
                            createdCsPointObject.id = cpID;
                            cspArray.push(createdCsPointObject);

                            loadingAnimationLastSet(progressBar);
                            that.props.setCsPoints(cspArray);
                            //timeout so the modal is not closing before the loading bar reaches the end
                            setTimeout(function () {
                                //set props / close modal / reset state vars

                                that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                            }, 1000);
                        }else{
                            this.apiErrorOccured('createConsumptionpoint', secondResult);
                            that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                        }
                    });
                }else{
                    var loadingBarBackground = document.getElementById("myBar");
                    loadingBarBackground.style.backgroundColor = 'transparent';
                    clearInterval(interval);
                    profileMessageSLP.classList.remove('d-none');
                    abnahmeprofilSLP.classList.add("alert-border");
                    abnahmeprofilSLP.classList.remove("add-white");
                    var submitBtn = document.getElementById("consumptionPointsCreate");
                    submitBtn.disabled = false;
                    this.apiErrorOccured('predictStandardElectricityProfile', firstResult);
                }
            });
        }else{ //Create RLM CP
            var loadProfileCheck;
            let loadProfileCheckbox = document.getElementById('lastgangfile');
            if(loadProfileCheckbox !== null){
                loadProfileCheck = loadProfileCheckbox.checked
            }else{
                loadProfileCheck = true;
            }
            let voltageLevelSelect = document.getElementById('spannungsebene');
            let voltageLevel = voltageLevelSelect.options[voltageLevelSelect.selectedIndex].value;
            //Create CSPoint without loadProfile
            var abnahmeprofilRLM = document.getElementById('slpTypeRLM');
            var selectedRLM = abnahmeprofilRLM.options[abnahmeprofilRLM.selectedIndex].value;

            if(loadProfileCheck === true){ // checkbox === true => no loadProfile
                // LOADING BAR ANIMATION
                //elements for loading animation
                let progressBar = document.getElementById('myBar3');
                //loading animation
                abnahmeprofilRLM.classList.add("add-white");
                interval = loadingAnimationFirstSet(progressBar);
                var profileMessageRLM = document.getElementById('profileMessageRLM'); //error message

                predictStandardElectricityProfile(this.customerID, kwh, selectedRLM, lastType, state, postalCode.value).then(firstResult => {
                    if(firstResult !== "ERR") {
                        //remove error if displayed
                        if(abnahmeprofilRLM.classList.contains('alert-border')){
                            abnahmeprofilRLM.classList.remove('alert-border');
                            if(!profileMessageRLM.classList.contains('d-none')){
                                profileMessageRLM.classList.add('d-none');
                            }
                        }

                        this.insertCspIntoDB([postalCode.value, city.value, street.value, houseNumber.value, lastType, voltageLevel, state, firstResult.load_profile_id, firstResult.prediction_id, selectedRLM, energyType]).then((secondResult) => {
                            if(secondResult !== "ERR") {
                                cpID = secondResult.id;
                                createdCsPointObject.city = city.value;
                                createdCsPointObject.consumption_type = lastType;
                                createdCsPointObject.extended_information = {business_type: selectedRLM};
                                createdCsPointObject.extended_information.specification = parseInt(voltageLevel);
                                createdCsPointObject.noLpCheckbox = true;
                                createdCsPointObject.id = cpID;
                                cspArray.push(createdCsPointObject);
                                loadingAnimationLastSet(progressBar);
                                var that = this;
                                setTimeout(function() {
                                    //set props / close modal / reset state vars
                                    that.props.setCsPoints(cspArray);
                                    that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                                }, 1500);
                            }else{
                                this.apiErrorOccured('predictElectricityProfile', firstResult);
                                that.afterInsertActions(loadingLoadProfileSpinner, cpID);
                            }
                        });
                    }else{
                        var loadingBarBackground = document.getElementById("myBar3");
                        clearInterval(interval);
                        profileMessageRLM.classList.remove('d-none');
                        abnahmeprofilRLM.classList.add("alert-border");
                        abnahmeprofilRLM.classList.remove("add-white");
                        var submitBtn = document.getElementById("consumptionPointsCreate");
                        loadingBarBackground.style.backgroundColor = 'transparent';
                        submitBtn.disabled = false;
                        this.apiErrorOccured('predictStandardElectricityProfile', firstResult);
                    }
                });
            }else{   //Create CSPoint with loadProfile
                var file = this.state.uploadedFileName;
                this.insertCspIntoDB([postalCode.value, city.value, street.value, houseNumber.value, lastType, voltageLevel, state, fileID, predictionID, null, energyType]).then((result) => {
                    if(result !== "ERR"){
                        cpID = result.id;
                        createdCsPointObject.city = city.value;
                        createdCsPointObject.consumption_type = lastType;
                        createdCsPointObject.id = cpID;
                        createdCsPointObject.extended_information = {business_type: ""};
                        createdCsPointObject.extended_information.specification = parseInt(voltageLevel);
                        createdCsPointObject.load_profile_file_name = file;
                        createdCsPointObject.fileID = fileID;
                        createdCsPointObject.noLpCheckbox = false;
                        cspArray.push(createdCsPointObject);
                        this.props.setCsPoints(cspArray);
                        this.afterInsertActions(loadingLoadProfileSpinner, cpID);
                    }else{
                        this.apiErrorOccured('createConsumptionpoint', result);
                        this.afterInsertActions(loadingLoadProfileSpinner, cpID);
                    }
                });
            }
        }
    }

    insertCspIntoDB(consumptionPointData){
        this.setState({unnecessaryPropsRecieved: 1});
        //send request to api "create new ConsumptionPoint"
        return createConsumptionPoint(consumptionPointData, this.customerID);
    }

    handleCloseModal() {
        this.props.callbackCloseModal();
        this.resetFieldValues();
    }

    callbackAboutFileId = (fileID, predictionID) => {
        this.setState({loadProfileID: fileID, predictionID: predictionID});
    };

    callbackAboutLastType = (rlm, slp) => {
        this.setState({
            rlm: rlm,
            slp: slp
        });
        this.checkRlmValues(rlm, this.state.kwhRate, this.state.postalCode, this.state.city, this.state.street, this.state.houseNumber);
    };

    callbackAboutFileName = (fileName) => {
        this.setState({uploadedFileName: fileName});
    };

    callbackAboutCheckbox= (dataFromChild) => {
        this.setState({loadProfileCheck: dataFromChild});
    };

    handleKwhRate(e){
        let $this = $('#kwh');
        let str = $this.val();
        str = str.replace(" kWh", "");
        str = str.replace(/[^0-9]/g, "");
        str = str.replace(/[a-zA-Z]/g, "");
        str = str.replace(/\./g, "");
        this.checkRlmValues(this.state.rlm, str, this.state.postalCode, this.state.city, this.state.street, this.state.houseNumber);
        let str_a = [];
        let i = 0;
        while (str.length - i * 3 > 0) {
            i++;
            if (str.length - i * 3 > 0) {
                str_a.push(str.substr(-3 * i, 3));
            } else {
                str_a.push(str.substr(0, str.length - (i - 1) * 3));
            }
        }
        str = "";
        while (str_a.length > 0) {
            str += str_a.pop();
            if (str_a.length > 0)
                str += ".";
        }

        //Unset loadProfile field to force the new prediction
        this.setState({kwhRate: str, predictionID: '', loadProfileID: '', uploadedFileName: 'Lastgang hier platzieren oder auswählen'});
    }

    handleChangePostalCode(e, editValue) {
        let postalCodeVal;
        let postalCode;
        let street = document.getElementById('street');
        let houseNumber = document.getElementById('houseNumber');
        //e !== '' is here to be able to refill the street state array on edit of cspoint
        if(e !== ''){
            this.checkRlmValues(this.state.rlm, this.state.kwhRate, e.target.value, this.state.city, this.state.street, this.state.houseNumber);
            postalCodeVal =  e.target.value;
            postalCode = e.target;
            street.disabled = true;
            houseNumber.disabled = true;
        }else{
            postalCodeVal = editValue+'';
            postalCode = document.getElementById('postalCode');
        }

        let city = document.getElementById("city");
        let loadingSpinner = document.getElementById("loadingSpinnerPopupBox");

        let postalCodeMessage = document.getElementById('postalCodeMessage');

        this.setState({postalCode: postalCodeVal, cities: ''});
        //On postal code length 5, fetch cities and streets, else delete every character after 5 length
        if(postalCodeVal.length === 5){
            if(e !== '') {
                loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x";
            }
            getCities(postalCodeVal).then((result) => {
                    if (result !== "ERR") {
                        if ( result === "[]"){
                            //wrong postalcode no result found
                            setTimeout(function(){
                                postalCodeMessage.classList.remove('d-none');
                                loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                                postalCode.classList.add("alert-border");
                            }, 1000);
                        } else{
                            //if result found set state array and show city
                            if(e !== ''){
                                postalCodeMessage.classList.add('d-none');
                                postalCode.classList.remove("alert-border");
                            }

                            var cityOptions = createCitySelectOptions(result);
                            //set cities array frthis page with all citys for this postalCode
                            this.setState({
                                csPointState: result[0].state,
                                city: cityOptions,
                            });
                            if(e !== ''){
                                this.setState({cityName: result[0].city});
                            }
                            this.checkRlmValues(this.state.rlm, this.state.kwhRate, this.state.postalCode, true, this.state.street, this.state.houseNumber);

                            getStreets(postalCodeVal, result[0].city).then(result => {
                                if(result !== 'ERR'){
                                    if (result === "[]") {
                                        console.log("empty Streets");
                                    }else{
                                        //set new Vlaues for state Street array
                                        var streets = [];
                                        for (var i = 0; i < result.length; i++) {
                                            if (streets.indexOf(result[i].streetName) === -1){
                                                streets.push(result[i].streetName);
                                            }
                                        }

                                        this.setState({streetsArray: streets});
                                        streets = []; //set array empty
                                    }
                                }else{
                                    this.apiErrorOccured('getStreets', result);
                                }
                             });

                            if(e !== ''){
                                //show citys /hide loading Animation after delay (for getCity)
                                setTimeout(function(){
                                    city.classList.remove("d-none");
                                    loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                                    if(city){
                                        street.removeAttribute('disabled');
                                        houseNumber.removeAttribute('disabled');
                                    }
                                }, 1000);
                            }
                        }
                        }else{
                            this.apiErrorOccured('getCities', result);
                        }
                    },
                    (error) => {
                        setTimeout(function(){
                            loadingSpinner.className.baseVal = "svg-inline--fa fa-spinner fa-w-16 fa-spin fa-2x d-none";
                        }, 1000);
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
        else if(postalCodeVal.length > 5){
            this.setState({postalCode: this.state.postalCode});
        }else if(postalCodeVal.length >= 0){
            var streetField = document.getElementById("street");
            streetField.value = "";
            houseNumber.value = "";
            city.classList.add('d-none');
        }else{
            if(e !== ''){
                city.classList.add("d-none");
            }
        }
    }

    handleStreetChange(e) {
        this.checkRlmValues(this.state.rlm, this.state.kwhRate, this.state.postalCode, this.state.city, e.target.value, this.state.houseNumber);
        let inp = e.target;
        let arr = this.state.streetsArray;

        var street = <input autoComplete="new-password" onKeyDown={this.handleKeydownStreet} type='text' id='street' value={e.target.value}
                            onChange={this.handleStreetChange} placeholder='Straße'/>;
        this.setState({streetOptions: street, street: e.target.value});

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/

        /*execute a function when someone writes in the text field:*/
        var a, b, i, val = inp.value;

        this.closeAllLists(inp);

        if (!val) {
            return false;
        }

        var count = 0;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", inp.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        inp.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            /*check if the item starts with the same letters as the text field value:*/
            if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
                count++;
                if(count === 8) break;
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                b.innerHTML += arr[i].substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener("click", function (e) {

                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;

                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    // closeAllLists();

                    //close all lists
                    var x = document.getElementsByClassName("autocomplete-items");
                    for (var i = 0; i < x.length; i++) {
                        if (inp !== x[i] && inp !== inp.value) {
                            x[i].parentNode.removeChild(x[i]);
                        }
                    }

                });

                a.appendChild(b);
            }
        }
    }

    handleKeydownStreet(e){
        var x = document.getElementById(e.target.id + "autocomplete-list");
        this.checkRlmValues(this.state.rlm, this.state.kwhRate, this.state.postalCode, this.state.city, e.target.value, this.state.houseNumber);
        var currentFocus = this.state.currentFocus;
        if (x) x = x.getElementsByTagName("div");

        if (e.key === 'ArrowDown') {
            currentFocus++;
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);

        } else if (e.key === 'ArrowUp') { //up

            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (e.key === 'Enter') {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                var div = x[currentFocus];
                e.target.value = div.getElementsByTagName('input')[0].value;
                this.closeAllLists(e.target);
            }
        }

        this.setState({currentFocus: currentFocus});
    }

    addActive(x, currentFocus) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        this.removeActive(x);

        var arr = Array.from(x);

        if (currentFocus >= arr.length){
            currentFocus = 0;

        } else if (currentFocus < 0) {
            currentFocus = 0;
        }
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");

        return currentFocus;
    }

    removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    closeAllLists(inp){
        /*close any already open lists of autocompleted values*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (inp !== x[i] && inp !== inp.value) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    checkRlmValues(rlmSelect, kWhRateVal, postalCodeVal, cityVal, streetVal, houseNumberVal){
        let rlm = rlmSelect;
        let kWh = kWhRateVal;
        let postalCode = postalCodeVal +"";
        let city = cityVal;
        let cityCheck =  false;
        let street = streetVal;
        let houseNumber = houseNumberVal+ "";
        let uploadWrapper = $("#upload-wrapper");
        let fakeUploadJQ = $("#fake-upload");

        if (city === true ||city.length > 0){
            cityCheck = true;
        }

        if(rlm){
            kWh = kWh.replace(" kWh", "");
            kWh = kWh.replace(/\./g, "");
            //check if all values are set

            if(this.props.energyType === 'GAS'){
                if (parseInt(kWh) >= 1500000 &&
                    postalCode.length === 5 &&
                    cityCheck &&
                    street.length > 0 &&
                    houseNumber.length > 0
                ){// show loadprofile
                    this.setState({showLoadProfile: true});

                    uploadWrapper.slideDown();
                    fakeUploadJQ.hide();
                    // fakeUploadJQ.removeClass('d-none');
                    fakeUploadJQ.fadeIn(1000);
                }else{ //user deleted input -> hide loadprofile
                    fakeUploadJQ.fadeOut(100);
                    uploadWrapper.slideUp();
                    this.setState({showLoadProfile: false});
                }
            }else{
                if (parseInt(kWh) >= 30000 &&
                    postalCode.length === 5 &&
                    cityCheck &&
                    street.length > 0 &&
                    houseNumber.length > 0
                ){// show loadprofile
                    this.setState({showLoadProfile: true});

                    uploadWrapper.slideDown();
                    fakeUploadJQ.hide();
                    // fakeUploadJQ.removeClass('d-none');
                    fakeUploadJQ.fadeIn(1000);
                }else{ //user deleted input -> hide loadprofile
                    fakeUploadJQ.fadeOut(100);
                    uploadWrapper.slideUp();
                    this.setState({showLoadProfile: false});
                }
            }
        }
        else{ // slp -> hide loadProfile
            this.setState({showLoadProfile: false});
        }
    }

    handleChangeCity(e){
        this.setState({cityName: e.target.value});
        //Bei mehreren Städten muss die autocomplete box der Straßen sich Ändern
        var selectCity = e.target;
        getStreetsArray(this.state.postalCode, selectCity.options[selectCity.selectedIndex].value).then(streets =>{ //get streets for this city
            if(streets !== 'ERR'){
                var streetField = document.getElementById("street");
                streetField.value = ""; //empty streets val since the street will propebly not exist
                this.closeAllLists(streetField); //close auto complete lists
                var streetsTmp = [];
                for (var i = 0; i < streets.length; i++) {
                    if (streetsTmp.indexOf(streets[i].streetName) === -1){
                        streetsTmp.push(streets[i]); //fill array with streets
                    }
                }
                this.setState({streetsArray: streetsTmp}); //set state for auto complete
                streetsTmp = []; //set array empty
            }else{
                this.apiErrorOccured('getStreetsArray', streets);
            }
        });
    }

    handleChangeHouseNumber(e){
        this.setState({houseNumber: e.target.value});
        this.checkRlmValues(this.state.rlm, this.state.kwhRate, this.state.postalCode, this.state.city, this.state.street, e.target.value);
    }

    handleChangeRLM(e){
        this.setState({ rlm: true, slp: false });
        this.checkRlmValues(true, this.state.kwhRate, this.state.postalCode, this.state.city, this.state.street, this.state.houseNumber);
    }
    handleChangeSLP(e){
        this.setState({ rlm: false, slp: true });
        this.checkRlmValues(false, this.state.kwhRate, this.state.postalCode, this.state.city, this.state.street, this.state.houseNumber);
    }

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    render() {
        let editing = this.props.editedCsPoint;
        let submitEvent;
        let inputStreet;
        let inputHouseNumber;
        var minSlp = "Eine Strom SLP Abnahmestelle muss mind. einen Verbrauch von 6.000 kWh haben.";
        var maxSLP = "Eine Strom SLP Abnahmestelle darf max. einen Verbrauch von 150.000 kWh haben.";
        var minRlm = "Eine Strom RLM Abnahmestelle muss mind. einen Verbrauch von 30.000 kWh haben.";
        var maxRlmNoLP = "Eine RLM Abnahmestelle ohne Lastgang darf max. einen Verbrauch von 500.000 kWh haben.";

        let zaehlertypInfo = '';
        if(this.props.energyType === 'GAS'){
            maxSLP = "Eine Gas SLP Abnahmestelle darf max. einen Verbrauch von 1.500.000 kWh haben.";
            minSlp = "Eine GAS SLP Abnahmestelle muss mind. einen Verbrauch von 10.000 kWh haben.";
            minRlm = "Eine Gas RLM Abnahmestelle muss mind. einen Verbrauch von 1.500.000 kWh haben.";
            zaehlertypInfo = 'Bei Lieferstellen wird zwischen zwei Zählertypen unterschieden: Bis zu einem Gasverbrauch von ca. 1.500.000 kWh/a wird von einem SLP-Zähler gesprochen. Sie erkennen einen SLP-Zähler daran, wenn sie einen monatlichen Abschlag bezahlen und die Rechnung einmal im Jahr nach der Zählerablesung erstellt wird. Ein RLM-Zähler wird ab einem Gasverbrauch von ca. 1.500.000 kWh/a verwendet. Er erfasst alle 60 Minuten Ihren Verbrauch und erstellt damit einen Lastgang. Sie erkennen einen RLM-Zähler daran, wenn sie eine monatliche Rechnung mit Ihrem exakten Verbrauch erhalten.';
        }else{
            zaehlertypInfo = 'Bei Lieferstellen wird zwischen zwei Zählertypen unterschieden: Bis zu einem Stromverbrauch von ca. 100.000 kWh/a wird von einem SLP-Zähler gesprochen. Sie erkennen einen SLP-Zähler daran, wenn sie einen monatlichen Abschlag bezahlen und die Rechnung einmal im Jahr nach der Zählerablesung erstellt wird. Ein RLM-Zähler wird ab einem Stromverbrauch von ca. 100.000 kWh/a verwendet. Er erfasst alle 15 Minuten Ihren Verbrauch und erstellt damit einen Lastgang. Sie erkennen einen RLM-Zähler daran, wenn sie eine monatliche Rechnung mit Ihrem exakten Verbrauch erhalten.';
        }

        //If not in edit mode, bind "create" to submit event and preset fields properly, else bind "update" in edit mode
        if(editing === ''){
            //Street value darf nicht aus dem State gespeichert werden.
            inputStreet = <input autoComplete="new-password" onKeyDown={this.handleKeydownStreet} type='text' id='street'
                                 onChange={this.handleStreetChange} placeholder='Straße' disabled/>;
            submitEvent = this.createBoxSubmit;
            inputHouseNumber = <input autoComplete="new-password" className="col-md-2 popup" type='text' id='houseNumber' value={this.state.houseNumber}
                                                              onChange={this.handleChangeHouseNumber} placeholder='Hausnr.' disabled/>;
        }else {
            inputStreet = this.state.streetOptions;
            submitEvent = () => this.editBoxSubmit(editing);
            inputHouseNumber = <input autoComplete="new-password" className="col-md-2 popup" type='text' id='houseNumber' value={this.state.houseNumber}
                                      onChange={this.handleChangeHouseNumber} placeholder='Hausnr.'/>;
        }

        if(this.state.showModal){
            var element = document.getElementsByTagName('body')[0];
            element.classList.add('dark-theme');
        }

        var that=this;
        function handleCity() {
            if(that.state.city !== ''){ // render city options into select input
                return that.state.city;
            }else{ // render default city option into select input
                return <option value={'Ort'}>Ort</option>;
            }
        }

        return (
            <>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                <Modal overlayclassname='modal-form'
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                    size="lg"
                    backdrop="static"
                >
                 <ModalHeader closeButton>
                     <ModalTitle><h3>Abnahmestelle hinzufügen</h3></ModalTitle>
                 </ModalHeader>
                 <ModalBody>
                    <div className='eless-form row m-0 clearfix'>
                        <div className='zaehlertyp-box'>
                            <div className='col-md-12 p-0'>
                                <h4>Zählertyp
                                    <span className={'info-wrapper'} >
                                        <img src={InfoIcon} onClick={this.displayInfo} alt='info-icon' id='zaehlertyp-info-icon' className='info-icon' />
                                        <SpeechBubble id={'zaehlertyp-info'} content={zaehlertypInfo} />
                                    </span>
                                </h4>
                            </div>
                            <div className='col-md-12 control-group p-0'>
                                <label className="control control-radio mr-4">
                                    SLP Zähler
                                    <input id='slp' data-value={1} className='radio lastType' type='radio' defaultChecked={this.state.slp} onClick={this.handleChangeSLP} name='lasttyp' value='SLP'/>
                                    <div className="control_indicator" />
                                </label>
                                <label className="control control-radio">
                                    RLM Zähler
                                    <input id='rlm' data-value={0} className='radio lastType' type='radio' defaultChecked={this.state.rlm} onClick={this.handleChangeRLM} name='lasttyp' value='RLM'/>
                                    <div className="control_indicator" />
                                </label>
                                <div className="lds-ring d-none">
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-12 p-0 cspoint-address row ml-0 mr-0'} >
                        <div className={'col-md-12 p-0'}><h4>Abnahmestelle</h4></div>
                        <input required className="col-md-12 col-sm-12 consumption-input-mask" type='text'
                               id='kwh' value={this.state.kwhRate} onChange={this.handleKwhRate} onFocus={this.delkWhString} onBlur={this.addkWhString}
                               placeholder='Jahresverbrauch'/>
                        <div className={'col-md-12 col-sm-12 alert-text d-none popupErrorMessage'} id={'kwhRateMessage'}>Bitte geben Sie Ihren geschätzten Verbrauch (in kWh) an.</div>
                        <div className={'col-md-12 col-sm-12 alert-text d-none popupErrorMessage'} id={'kwhRateTooLowSlp'}>{minSlp}</div>
                        <div className={'col-md-12 col-sm-12 alert-text d-none popupErrorMessage'} id={'kwhRateTooLowRlm'}>{minRlm}</div>
                        <div className={'col-md-12 col-sm-12 alert-text d-none popupErrorMessage'} id={'kwhRateTooHighNoLp'}>{maxRlmNoLP}</div>
                        <div className={'col-md-12 col-sm-12 alert-text d-none popupErrorMessage'} id={'kwhRateTooHighSlp'}>{maxSLP}</div>
                        <div className={'col-md-4 col-sm-12 p-0 input-spinner'}>
                            <input required type='number' id='postalCode'
                                   value={this.state.postalCode} onChange={this.handleChangePostalCode}
                                   placeholder='PLZ' className={'postal-code'}/>
                            <FontAwesomeIcon className={'fa-spin fa-2x d-none'} id={"loadingSpinnerPopupBox"} icon={faSpinner}/>
                        </div>
                        <div className="col-md-1 p-0 zipcity-separator"/>
                        <select id={"city"} className={'city-select city-select-popupbox col-md-7 col-sm-12'} onChange={this.handleChangeCity} value={this.state.cityName}>
                            {handleCity(0)}
                        </select>

                        <div className={'col-md-12 col-sm-12 alert-text d-none popupErrorMessage'} id={'postalCodeMessage'}>Geben Sie bitte eine gültige Postleitzahl an.</div>
                        <div className={"col-md-9 autocomplete p-0 popup"}>
                            {inputStreet}
                        </div>

                        <div className="col-md-1 p-0 zipcity-separator"/>
                            {inputHouseNumber}
                        <div className={'col-md-12 alert-text d-none popupErrorMessage'} id={'streetMessage'}>Geben Sie bitte eine Straße mit einer validen Hausnummer an.</div>
                        </div>
                        <LastType
                            energyType={this.props.energyType}
                            editedCsPoint={this.props.editedCsPoint}
                            slp={this.state.slp}
                            rlm={this.state.rlm}
                            slpType={this.state.slpType}
                            voltageLevel={this.state.voltageLevel}
                            loadProfileCheck={this.state.loadProfileCheck}
                            uploadedFileName={this.state.uploadedFileName}
                            callbackAboutCheckbox={this.callbackAboutCheckbox}
                            callbackFromParent={this.myCallback}
                            callbackAboutFileName={this.callbackAboutFileName}
                            callbackAboutFileId={this.callbackAboutFileId}
                            callbackAboutLastType={this.callbackAboutLastType}
                            callbackAboutLoadProfileID={this.callbackAboutLoadProfileID}
                            kwhRate={this.state.kwhRate}
                            csPointState={this.state.csPointState}
                            customerID={this.customerID}
                            submitPopupbox={this.createBoxSubmit}
                            currentBusinessType={this.state.currentBusinessType}
                        />
                    </div>
                 </ModalBody>
                 <ModalFooter>
                    <div id={'button-wrapper'} className='col-md-12 mt-3 p-0'>
                        <button className='eless-zurueck-btn' onClick={this.handleCloseModal}><span className={'mr-4 o-arrow_left01-1'} />Abbrechen</button>
                        <SubmitAddCP event={submitEvent}/>
                    </div>
                 </ModalFooter>
             </Modal>
            </>
        );
    }
}