function loadingAnimationLastSet(loadingBar) {
    var width = global.loadingFirstSet;

    //load rest of loadingbar if profile is predicted (with less delay)
    let triggerLoadRest = setInterval(loadingLastPercent, 100);
    function loadingLastPercent() {
        //load last Percent wich are left out to give the customer the feeling something is happenning
        if (width >= 100) {
            //stop looping
            clearInterval(triggerLoadRest);
        } else {
            width++;
            loadingBar.style.width = width + '%';
        }
    }
}

export default loadingAnimationLastSet;
