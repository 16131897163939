import React from "react";
import SlpTypes from "./SlpType";
import $ from "jquery";
import InfoIcon from "../media/Information.png";
import SpeechBubble from "./SpeechBubble";
import '../global';

class LastType extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            abnahmeprofilSLP: '',
            abnahmeprofilRLM: 'hide',
            extendedForm: '',
            slp: true,
            rlm: false,
            // showLoadProfile: false
        };
        this.displayAbnahmeprofil = this.displayAbnahmeprofil.bind(this);
        this.extendForm = this.extendForm.bind(this);
        this.cutForm = this.cutForm.bind(this);
        this.displayInfo = this.displayInfo.bind(this);
        this.fileUploaded = this.fileUploaded.bind(this);
        // this.apiPredictLoadProfile = this.apiPredictLoadProfile.bind(this);
    }

    componentDidMount() {
       $(".lastType").on("click", function () {

           var radioClass = $(".lastType");

           radioClass.each(function( ) {
               $(this).attr('disabled',true);
           });

           var $this = $(this);
           var lastType = $this.val();
           var wrapperSLP = $("#abnahmeprofil-slp");
           var wrapperRLM = $("#extendedForm");
           var spinner = $(".lds-ring");
           var buttonWrapper = $('#button-wrapper');

           spinner.removeClass("d-none");

           if (lastType === "SLP"){
               wrapperRLM.fadeOut();
               buttonWrapper.fadeOut();
               setTimeout(function(){
                   wrapperSLP.fadeIn();
                   buttonWrapper.fadeIn();
               }, 500);
           }else{
               wrapperSLP.fadeOut();
               buttonWrapper.fadeOut();
               setTimeout(function(){
                   wrapperRLM.fadeIn();
                   buttonWrapper.fadeIn();
               }, 500);
           }

           setTimeout(function(){
               spinner.addClass("d-none");
               radioClass.each(function( ) {
                   $(this).attr('disabled',false);
               });
           }, 750);
       });

        this.setState({
            slp: this.props.slp,
            rlm: this.props.rlm
        });

        //Beim editieren abhängend von Zählertyp unnötigen Felder verstecken
        if(this.props.slp){
            this.setState({
                extendedForm: 'hide'
            });
        }else{
            this.setState({
                abnahmeprofilSLP: 'hide'
            });
            $('#extendedForm').show();
        }

        if(this.props.loadProfileCheck){
            var fileupload = document.getElementById('fileupload');
            var warning = $('#warning');
            warning.show();
            fileupload.classList.add('d-none');
            this.setState({abnahmeprofilRLM: ''})
        }
    }

    fileUploaded(){
        //file vars
        let files = $('#upload')[0].files[0];
        let name = files.name;
        let extension = (/[.]/.exec(name)) ? /[^.]+$/.exec(name)[0] : undefined;
        let shortenLength = 23; //shorten the length at this value because it gets to big to display

        //vars for error
        let errorMsgField = document.getElementById('error-message-fupload');
        let emptyLoadProfileMessage = document.getElementById('loadProfileMessage');
        var errorMessage2 = document.getElementById('error-message-no-loadprofile');
        let allowedExtensions = ['xlsx', 'xls', 'csv'];

        //html file input fields
        let fakeUpload = document.getElementById('fake-upload');
        let upload = document.getElementById('upload');

        //buttons and icons in button for loading wheel
        let submitBtn = document.getElementById("consumptionPointsCreate");

        //loading spinner in button
        let loadingLoadProfileSpinner = document.getElementById("loadingLoadProfileSpinner");

        //elements for loading animation
        var  myProgress = document.getElementById('myProgress');
        var  progressBar = document.getElementById('myBar2');

        //vars for setting loading bar
        var width = 1;
        let loadFirstSetOfPercent = 92;

        //hide error message if visible
        if(!errorMessage2.classList.contains('d-none')){
            errorMessage2.classList.add('d-none');
        }

        //vars for params
        let begin = document.getElementById('duration-start').value;
        let end = document.getElementById('duration-end').value;
        let kWh = this.props.kwhRate;

        //shorten name if its to big to display
        if (name.length >= shortenLength) {
            name = name.substr(0, 20);
            name = name + '...';
        }
        let lgShowName = name + " (" + extension + ") " + files.size / 1000 + " kb";

        //get date in right format
        var splitBegin = begin.split(".");
        begin = splitBegin[2]+ "-" + splitBegin[1]+ "-" + splitBegin[0];

        var splitEnd = end.split(".");
        end = splitEnd[2]+ "-" + splitEnd[1]+ "-" + splitEnd[0];

        kWh = kWh.replace(" kWh", "");
        kWh = kWh.replace(/\./g, "");

        function loadFirstSetPercent() {
            // hold if width reaches a defined limit
            if (width >= loadFirstSetOfPercent) {
                //stop looping
                clearInterval(triggerLoadFirst);
            } else {
                progressBar.style.backgroundColor = "#041133";
                fakeUpload.classList.add("add-white");
                if (width === 2){
                    //set trasition for smooth loading animation (after width = 2 because it will look buggy on 1)
                    progressBar.style.transitionDuration = "1.2s";
                }
                //count width up and set value to the loading bar
                width++;
                //progressValue.textContent = 'lgName";
                progressBar.style.width = width + '%';
            }
        }

        if (allowedExtensions.indexOf(extension) > -1) {//if allowed Extensions start upload processes
            //load first 96% of progreess bar (fill finish after resonse from api)
            var triggerLoadFirst = setInterval(loadFirstSetPercent, 250);

            //hide upload error message
            errorMsgField.classList.add('d-none');
            emptyLoadProfileMessage.classList.add('d-none');
            fakeUpload.classList.remove('alert-border');
            fakeUpload.classList.add('border-while-loading');

            //disable submit btn and show loading animation in btn
            submitBtn.disabled = true;
            //disable upload field while uploading
            upload.disabled = true;

            //hide upload and show loading process bar
            loadingLoadProfileSpinner.classList.remove('d-none');

            //set data for upload
            let formData = new FormData();
            formData.append('file', files);
            formData.append('user', global.apiUser);
            formData.append('auth_token', global.apiUserAuthToken);
            formData.append('service', global.apiServicePrediction);
            formData.append('function', global.apiFunctionPredictLoadProfile);
            formData.append('param[state]', this.props.csPointState);
            formData.append('param[foreign_user_id]', this.props.customerID);
            formData.append('param[prediction_begin]', begin);
            formData.append('param[prediction_end]', end);
            formData.append('param[ratio_base_value]', '0.35');
            formData.append('param[base_adjustment_impact]', '1');
            formData.append('param[predicted_consumption]', kWh);
            formData.append('param[sheet_number]', '0');
            const that = this;

            //ajax post to api
            $.ajax({
                headers: {
                    Authorization: global.apiBearer
                },
                url: global.apiURL,
                type: 'post',
                data: formData,
                contentType: false,
                processData: false,
                success: function (response){
                    if(response !== 0 && response.status !== 'API ERROR'){
                        let triggerLoadRest = setInterval(loadingLastPercent, 150);
                        function loadingLastPercent() {
                            //load last Percent wich are left out to give the customer the feeling something is happenning
                            if (width >= 100) {
                                clearInterval(triggerLoadRest);
                                fakeUpload.classList.remove('border-while-loading');
                                afterUpload();
                                that.props.callbackAboutFileId(response.result.load_profile_id, response.result.prediction_id);
                                fakeUpload.dataset.id = response.result.file_id;
                                fakeUpload.placeholder = lgShowName;
                                //If the consumptionPoint isnt beeing updated, submit the popupbox right after upload finished
                                if(that.props.editedCsPoint === ''){
                                    that.props.submitPopupbox();
                                }
                            } else {
                                width++;
                                //progressValue.textContent = width + "%";
                                progressBar.style.width = width + '%';
                            }
                        }
                    }else{
                        clearInterval(triggerLoadFirst);
                        progressBar.style.backgroundColor = "transparent";
                        fakeUpload.classList.remove("add-white");
                        fakeUpload.classList.remove('border-while-loading');
                        fakeUpload.placeholder = "Lastgang hier plazieren oder auswählen";
                        fakeUpload.classList.add('alert-border');
                        errorMsgField.classList.remove('d-none');
                        afterUpload();
                    }

                    function afterUpload(){
                        submitBtn.disabled = false;
                        upload.classList.remove('d-none');
                        myProgress.classList.add('d-none');
                        fakeUpload.classList.remove('d-none');
                        loadingLoadProfileSpinner.classList.add('d-none');

                        //reset values for potential new upload
                        width = 0;
                    }
                    upload.disabled = false;
                }
            });

            this.props.callbackAboutFileName(lgShowName);
        } else {
            var fileUpload = document.getElementById('upload');
            fileUpload.value = '';
            // no allowed extensions -> display error and set field-input-text to "upload" again
            fakeUpload.placeholder = "Lastgang hier plazieren oder auswählen";
            fakeUpload.classList.add('alert-border');
            errorMsgField.classList.remove('d-none');
        }
    }

    displayAbnahmeprofil (e) {
        const fileupload = document.getElementById('fileupload');
        const lastgangfile = document.getElementById('lastgangfile');
        var warning = $('#warning');

        warning.slideToggle();

        if(this.state.abnahmeprofilRLM === 'hide'){
            fileupload.classList.add('d-none');
            this.setState({abnahmeprofilRLM: ''});
        }else{
            fileupload.classList.remove('d-none');
            this.setState({abnahmeprofilRLM: 'hide'});
        }

        this.props.callbackAboutCheckbox(lastgangfile.checked);
    }

    cutForm () {
        var extendedForm = $('#extendedForm');

        extendedForm.slideUp();
        extendedForm.show();
    }

    extendForm () {
        var extendedForm = $('#extendedForm');

        $('#abnahmeprofil-slp').slideUp();

        setTimeout(function(){
            extendedForm.slideDown();
            extendedForm.show();
        }, 550);
    }

    displayInfo (e) {
        var arr = e.target.id.split('-');
        var elementName = arr[0];
        var infoBox = $('#' + elementName + '-info');

        if (infoBox.is(":visible")){
            infoBox.fadeOut();
        }else{
            infoBox.fadeIn();
        }
    }

    render(){
        var energytype = '';
        var spannungsebeneInfo = '';
        var checkboxElement = '';
        if(this.props.energyType === 'GAS'){
            spannungsebeneInfo = 'Die Angabe der Druckstufe hat keinen Einfluss auf Ihren Energiepreis. Sie wird lediglich benötigt, um die Netzentgelte korrekt abzufragen und hat damit rein informativen Charakter. Sofern Sie Ihre Druckstufe nicht kennen, wählen Sie bitte "Standard".';
            energytype = 'Gas';
        }else{
            spannungsebeneInfo = 'Die Angabe der Spannungsebene hat keinen Einfluss auf Ihren Energiepreis. Sie wird lediglich benötigt, um die Netzentgelte korrekt abzufragen und hat damit rein informativen Charakter. Sofern Sie Ihre Spannungsebene nicht kennen, wählen Sie bitte "Standard".';
            energytype = 'Strom';
            checkboxElement = <label className="control control-checkbox mr-4 mb-0">
                Ich besitze keine Lastgangdatei
                <input type='checkbox' onChange={this.displayAbnahmeprofil} value='lastgangfile' name='lastgangfile' defaultChecked={this.props.loadProfileCheck} id='lastgangfile' />
                <div className="control_indicator" />
            </label>;
        }

        var options = [];
        var selectTitle = 'Druckebene';

        if(this.props.energyType === 'ELECTRICITY'){
            selectTitle = 'Spannungsebene';
            options = [
                "Niederspannung (Standard)",
                "Mittelspannung",
                "Hochspannung",
                "Höchstspannung",

            ];
        }else{
            selectTitle = 'Druckstufe';
            options = [
                "Niederdruck (Standard)",
                "Mitteldruck",
                "Hochdruck",
            ];
        }

        var styleSS = {
            display: 'none'
        };
        return (
            <div className='col-md-12 p-0' ref={this.setWrapperRef}>

                <SlpTypes display={this.state.abnahmeprofilSLP} slpType={this.props.slpType} lastType='SLP' id='slpTypeSLP' myBarID={'myBar'} energyType={this.props.energyType}/>

                <div id='extendedForm' style={styleSS} className={'col-md-12 p-0 ' + this.state.extendedForm}>
                    <div className='spannungsebene-box'>
                        <h4 className='select-label'>{selectTitle}
                            <span className={'info-wrapper'} >
                                <img src={InfoIcon} onClick={this.displayInfo} alt='info-icon' id='spannungsebene-info-icon' className='info-icon' />
                                <SpeechBubble id={'spannungsebene-info'} content={spannungsebeneInfo} />
                            </span>
                        </h4>
                        <select id='spannungsebene'  name='spannungsebene' className='mt-0 custom-select' defaultValue={this.props.voltageLevel}>
                            {options.map((option, id) => (
                                <option key={id} value={id}>{option}</option>
                            ))}
                        </select>
                    </div>

                    <div id={"upload-wrapper"}>
                        <div className='control-group mt-4'>
                            <h4 className='select-label'>{energytype}-Lastgang hochladen
                                <span className={'info-wrapper'} >
                                <img src={InfoIcon} onClick={this.displayInfo} alt='info-icon' id='loadprofile-info-icon' className='info-icon' />
                                <SpeechBubble id={'loadprofile-info'} content={'Der Lastgang ist ein technisches Protokoll, das Ihren Strom- oder Gasverbrauch widerspiegelt. Dieser kann, je nach dem zuständigen Netzbetreiber, zum Teil unterschiedlich aussehen und basiert meist auf einem .csv- oder .xls-Format. Falls Ihnen kein Lastgang vorliegt, können Sie diesen von Ihrem derzeitigen Energieversorger in einem formlosen Schreiben anfordern.'} />
                            </span>
                            </h4>
                            {checkboxElement}
                        </div>
                        <div id='warning'>
                            <div><b>Achtung:</b></div>
                            <div>Das Hochladen einer Lastgangdatei ist aus mehreren Gründen sinnvoll. Die meisten Energielieferanten unterbreiten nur Angebote an Interessenten, die eine gültige Lastgangdatei zur Verfügung stellen. Darüber hinaus erhalten Interessenten mit hochgeladenem Lastgang grundsätzlich günstigere Angebote, da die Lieferanten andernfalls einen Risikoaufschlag in ihre Angebote einkalkulieren.</div>
                        </div>

                        <div id='fileupload'>
                            <div className='mt-2'>
                                <input id='upload' onChange={this.fileUploaded} className='was-validated fileupload-field mt-0' type='file' name='fileupload' placeholder='fileupload' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                <input id='fake-upload' data-id="0" type='text' className='fake-upload mt-0' placeholder={this.props.uploadedFileName} />
                                <div id="myProgress">
                                    <div id="myBar2" className={'myBar'}>
                                        <span id={"progressValue"}/>
                                    </div>
                                </div>
                                <span id='error-message-fupload' className='alert-text d-none'>Falsches Dateiformat</span>
                                <span id='error-message-no-loadprofile' className='alert-text d-none'>Bitte laden Sie eine Lastgangdatei hoch.</span>
                            </div>
                        </div>
                        <div id={'loadProfileMessage'} className={'alert-text d-none popupErrorMessage'}>Bitte laden Sie hier Ihre Lastgangdatei hoch.</div>
                        <SlpTypes display={this.state.abnahmeprofilRLM} slpType={this.props.slpType}  lastType='RLM' id='slpTypeRLM' myBarID={'myBar3'} energyType={this.props.energyType}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default LastType;
