import "whatwg-fetch";

/*

 */
export default async function modifyCustomer(customerID = "", company = "", firstname = "", lastname, postal_code ,  city , street, house_number) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionModifyCustomer,
            param: {
                company: company,
                first_name: firstname,
                last_name: lastname,
                customer_id: customerID,
                billing_address:{
                    first_name: firstname,
                    last_name: lastname,
                    company: company,
                    street: street,
                    house_number: house_number,
                    zip: postal_code,
                    city: city,
                },
            }
        })
    }).then(res => res.json())
        .then(result => {
            if (result.status === "ok") {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(rejected => {
            console.log('Request failed', rejected);
        });
}