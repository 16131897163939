import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import ModalBody from "react-bootstrap/ModalBody";
import React from "react";
export default class Accountsettings extends React.Component {

    render(){
        return(<Modal overlayclassname='modal-form'
               show={this.props.show}
               onHide={this.handleCloseWarning}
               size="lg">
            <ModalHeader>
                <div className={"col-md-6 col-lg-6 col-sm-12"}>
                    <ModalTitle>Achtung</ModalTitle>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12 text-right">
                    <FontAwesomeIcon onClick={this.props.handleClose} className={"fa-lg"} icon={faTimes} />
                </div>
            </ModalHeader>
            <ModalBody>
                {this.props.content}
            </ModalBody>
            <div className={"row warning-popup-footer"}>
                <div className={"col-md-6 col-lg-6 col-sm-12"}>
                    <button className='eless-zurueck-btn' onClick={this.props.handleClose}>
                        Abbrechen
                    </button>
                </div>
                <div className={"col-md-6 col-lg-6 col-sm-12"}>
                    <button type='submit' className='eless-weiter-btn' onClick={this.props.delete()} id='consumptionPointsCreate'>
                        Löschen
                    </button>
                </div>
            </div>
        </Modal>);
    }
}
