import React from "react";
import checkSessionStatus from "../functions/checkSessionStatus";
import Header from "../Header";
import Footer from "../Footer";

export default class ConstructionSite extends React.Component {
    componentDidMount() {
        checkSessionStatus();
    }

    render() {
        return(
            <>
                <Header />
                <div className='max-width-content'>
            <div id={'constructionSiteWrapper'}>
                <span className={'o-configuration-1'} /><br />
                Diese Seite befindet sich noch in der Entwicklungsphase, wird aber bald verfügbar sein.<br />
                Bitte schauen Sie zum einen späteren Zeitpunkt wieder vorbei.
            </div>
                </div>
                <Footer />
            </>
        );
    }
}