import "whatwg-fetch";

export default async function modifyConsumptionPoint(csPoint, customerID, state) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionModifyConsumptionPoint,
            param: {
                customer_id: customerID,
                consumption_point_id: csPoint.id,
                consumption_type: csPoint.consumption_type,
                load_profile_id: csPoint.load_profile_id,
                prediction_id: csPoint.prediction_id,
                business_type: csPoint.extended_information.business_type,
                medium_type: 'ELECTRICITY',
                specification: csPoint.extended_information.specification,
                city: csPoint.city,
                postal_code: csPoint.postal_code,
                street: csPoint.street,
                house_number: csPoint.house_number,
                state: state,
                country: 'DE',
            }
        })
    }).then(res => res.json())
        .then((result) => {
            if (result.status === "ok") {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(function (error) {                        // catch
            console.log('Request failed', error);
        });
}