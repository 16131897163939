import React, {Component} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import './global';
import TariffsApi from './requests/TariffsApi';
import DurationAndConsumptionPoints from "./benutzereingabe/DurationAndConsumptionPoints";
import ConsumptionPointsInfo from "./requests/CSPoints/ConsumptionPointsInfo";
import ContractAddFinished from "./uebersicht/ContractAddFinished";
import ErrorComponent from "./errorpage/ErrorComponent";
import Login from "./userlogin/Login";
import ActivateNewPassword from "./userlogin/ActivateNewPassword";
import EmailSentMessage from "./uebersicht/EmailSentMessage";
import Accountsettings from "./uebersicht/Accountsettings";
import Resetpassword from "./userlogin/ResetPassword";
import ConstructionSite from "./errorpage/ConstructionSite"
import Contracts from "./uebersicht/Contracts";
import PasswordForgottenMail from "./userlogin/PasswordForgottenMail";
import WrongUrlMessage from "./errorpage/WrongUrlMessage";
// Anhand der URL wird geschaut welche Komponente gerendert werden soll.

class AppRouter extends Component {
    render() {
        return (
            <Router>
                <ErrorComponent>
                    <Switch>
                        <Route path={global.LoginURL} component={Login}/>
                        <Route path={global.PasswordForgottenMail} component={PasswordForgottenMail}/>

                        <Route path={global.ActivateNewPassword} component={ActivateNewPassword}/>
                        <Route path={global.HomeURL} exact component={DurationAndConsumptionPoints}/>
                        <Route path={global.TariffsPage+'/:key'} component={TariffsApi}/>
                        <Route path={global.ConsumptionPointsInfoPage+'/:key'} component={ConsumptionPointsInfo}/>
                        <Route path={global.ContractAddFinishedPage+'/:key'} component={ContractAddFinished}/>
                        <Route path={global.Settings} component={ConstructionSite} />
                        <Route path={global.ContractSuccess + '/:tariffType'} component={EmailSentMessage} />
                        <Route path={global.Accountsettings} component={Accountsettings} />
                        <Route path={global.Resetpassword} component={Resetpassword} />
                        <Route path={global.Contracts} component={Contracts} />
                        <Route path={global.ForgotPassword} component={ConstructionSite} />

                        <Route path={global.defaultURL} component={Login} />
                        <Route path={global.BlankDefaultURL} component={Login} />
                        <Route exact path={"/"} component={() => {return (<Redirect to='/abnahmestellen' />)}} />
                        <Route component={WrongUrlMessage} />
                    </Switch>
                </ErrorComponent>
            </Router>
        );
    }
}

export default AppRouter;