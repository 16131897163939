function handleFormatDurationDate(functionName, date = ""){

   if (functionName === "getDefaultStartDate"){
       return setDefaultStartDate();
   }
   else if (functionName === "getMinDurationDate"){
       return setMinDurationDate(date);
   }
   else if (functionName === "getDefaultEndDate"){
       return setDefaultEndDate(date);
   }
   else if (functionName === "getMaxDurationDate"){
       return setMaxDurationDate();
   }



    /* -------------------------------------------------------------------------------------------
        Returns the Default set Startdate for customers
        The Provider must be given 1 Month time to register the net Customer
        So the function returns the todays date + 1 Month (for registration)
        Example: its the 01.01.2019 and you want a Contact asap. your startDate for the Contract Would be the 31.01.2019
     */
   function setDefaultStartDate(){
       var minStartDate = new Date();
       minStartDate =  minStartDate.setMonth(minStartDate.getMonth() + 1);
       minStartDate = new Date(minStartDate);
       return minStartDate.setDate(minStartDate.getDate());
   }

    /* -------------------------------------------------------------------------------------------
         This function returns the default Date after selecting/ changing the Startdate
         The default duration for a Offer is 12 Monts - 1 day
         Example: if you select the 01.12.2019 the Default- end would be 31.12.2019
      */
    function setDefaultEndDate(date){
        //Wenn Anfangsdatum gesetzt ist, berechne daraus das Enddatum
        if(date !== ''){
            date.setMonth(date.getMonth() +11);
            date.setDate(date.getDate() - 1);
        }else{
            date = new Date();
            date.setMonth(date.getMonth() +11);
            date.setDate(date.getDate() - 1);
        }
        return date;
    }

    /* -------------------------------------------------------------------------------------------
        The function returns the date of the shortest time a offer can be calculated
        the min. Duration of an Offer is 1 month from the Startdate - 1 Day
        Example: if your Startdate is the 01.01.2019 the min Duration is the 31.01.2019
    */
    function setMinDurationDate(startDate){
        startDate = startDate.setMonth(startDate.getMonth() + 1);
        return startDate.setDate(startDate.getDate() - 1);
    }


    /* -------------------------------------------------------------------------------------------
        The function returns the max. value we can calculate a Offer for.
        Since Our HPFC (Price Forwad Curve) is only Providing prices for 3 years we cant calculate any further
        the max. Duration of an Offer is 3 years from the Startdate - 1 Day
        Example: if your Startdate is the 01.01.2019 the min Duration is the 31.01.20122
    */
    function setMaxDurationDate(){
        var dt = new Date();
        dt.setFullYear(dt.getFullYear() + 3);
        return dt.toISOString().split('T')[0];
    }






}



export default handleFormatDurationDate;