const AES = require("crypto-js/aes");
const ENC = require("crypto-js/enc-utf8");
/* function to check if the internal tracked customerID is matching with the id you are logged in with */
/* check if you have access on this offer */
function checkCustomerID(internCustomerID) {
    var sessionCustomerID;
    if (typeof localStorage['securityToken'] !== "undefined") {
        sessionCustomerID = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
        if (internCustomerID+"" !== sessionCustomerID+"")  { //if id*s not matching redirect to login
            localStorage.removeItem("securityToken");
            window.location.replace(global.LoginURL);
        }
    }else{
        window.location.replace(global.LoginURL); //if no secutity token is set -> redirect to login
    }
}
export default checkCustomerID;