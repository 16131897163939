function validateMaloId(id) {
    var maloField = document.getElementById("maloID-" + id);
    var errorMalo = document.getElementById('errorMalo-' + id);
    var successMalo = document.getElementById('successMalo-' + id);
    var maloVal = maloField.value;
    if (maloVal.length === 11) {
        // Abb. 1
        if (maloVal.match(/^[0-9]+$/)){
            // validitation
            // step a)
            var ungerade = 0;
            var count;
            for (count = 0; count < 10; count++) {
                ungerade += parseInt(maloVal.substr(count, 1));
                count++;
            }
            // step b)
            var gerade = 0;
            for (count = 1; count < 10; count++) {
                gerade += parseInt(maloVal.substr(count, 1));
                count++;
            }
            gerade *= 2;
            // step c)
            var sum = gerade + ungerade; // == subtrahent
            // step d)
            var minuend = 10 * Math.ceil(sum / 10);
            var expected = minuend - sum;
            if (parseInt(maloVal.substr(10, 1)) === expected) {
                //ist korrekt
                successMalo.classList.remove('d-none');
                maloField.classList.remove('alert-border');
                errorMalo.classList.add('d-none');
            } else{
                //nicht korrekt
                maloField.classList.add('alert-border');
                errorMalo.classList.remove('d-none');
                successMalo.classList.add('d-none');
            }
        }
    } else if(maloVal.length > 11){
        maloField.value = maloVal.substr(0,11);
    }else if(maloVal.length === 0){
        errorMalo.classList.add('d-none');
        successMalo.classList.add('d-none');
        maloField.classList.remove('alert-border');
    }else{
        errorMalo.classList.remove('d-none');
        successMalo.classList.add('d-none');
        maloField.classList.remove('alert-border');
    }
}

export default validateMaloId;