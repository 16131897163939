import React from "react";

export default function createCitySelectOptions (resultCities){
    var arrayCities = [];
    var usedCities = [];
    for (var i = 0; i < resultCities.length; i++) {
        if (usedCities.indexOf(resultCities[i].city) === -1){
            arrayCities.push(<option key={i} value={resultCities[i].city} data-state={resultCities[i].state}>{resultCities[i].city}</option>);
            usedCities.push(resultCities[i].city);
        }
    }

    return arrayCities;
}
