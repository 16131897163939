import React from "react";
import $ from "jquery";
import Tariffs from "./Tariffs";
import GetLowestTariffPrice from "../../functions/getLowestTariffPrice";
import toggleIcon from "../../functions/toggleIcon";
import Breadcrumb from "../../benutzereingabe/Breadcrumb";

/* Diese Komponente kümmert sich um die Darstellung der Filter in der Sidebar, als auch das Durchfiltern der Angebote.
*  Die Angebote werden dann an die Tariffs komponente weitergegeben und dort angezeigt. */

export default class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            vertragsverlaengerung: '',
            energiequelle: '',
            anbieter: '',
            kuendigungsfrist: '',
            mengentoleranz: '',
            tariffs: [],
            allFilter: [],

        };

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    componentWillReceiveProps(nextProps, prevState) {
        let filteredArray = GetLowestTariffPrice(null, null, nextProps.tariffs, null);
        let lowestPrice = filteredArray[1];
        let lowestBase = filteredArray[2];
        var tariffs = nextProps.tariffs;

        if (typeof lowestPrice === "undefined"){
            lowestPrice = "Keine Angebote";
            lowestBase = "-";
        }
        this.setState({lowestPriceKwh: lowestPrice, lowestBase: lowestBase});
        setTimeout(function(nextProps){
            this.setState({tariffs: tariffs})
        }.bind(this), 2000);
    }

    componentDidMount() {
        $( document ).ready(function() {
            $(".filter-dropwdown").on("click", function () {
                var $this = $(this);
                var dropdownId = $this.data("childid");
                var dropdownElement = $('#'+dropdownId);
                var iconElement = document.getElementById(dropdownId + "Icon");

                if (!dropdownElement.is(':animated')){
                    if (iconElement.classList.contains("o-down-1")){
                        setTimeout(function() {
                            toggleIcon(iconElement, 'o-down-1', 'o-up-1')
                        }, 200);
                    }else{
                        setTimeout(function() {
                            toggleIcon(iconElement, 'o-up-1', 'o-down-1');
                        }, 200);

                    }
                    dropdownElement.slideToggle();
                }
            });
        });
    }

    handleChangeFilter(e, filterCategory){
        let tariffs = this.props.tariffs;
        let filteredArray = GetLowestTariffPrice(e, filterCategory, tariffs , this.state.allFilter);
        let lowestPrice = filteredArray[1];
        let lowestBase = filteredArray[2];

       if (typeof lowestPrice === "undefined" ||lowestPrice === null){
           lowestPrice = "Keine Angebote";
           lowestBase = "-";
       }

        this.setState({tariffs: filteredArray[0], lowestPriceKwh: lowestPrice, lowestBase: lowestBase});
    }


    render() {
        let cancellationPeriod = 'cancellation_period'; //Kündigungsfirst
        let contractExtention =  "contract_extension"; // Vertrags verlängerung
        let energySource = "energy_source"; //Ökostrom (1 Öko / 0 Normal)
        let personalSupport = "personal_support"; //persönlicher Support
        let additionalServices = "additional_services"; //Mit weiteren Energiedienstleistungen

        return (
            <div id='tariffs-page'>
                <Breadcrumb active={2} match={this.props.match} />

            <div className='row'>
            <div id='filter' className='col-lg-3 hidden-sm'>
                <h3 className={'mb-25'}>Filterkriterien</h3>
                <div className='filter-box'>
                    <div  ref='vertragsverlaengerung' className='filter-dropwdown' data-childid='vertragsverlaengerung'>
                        Vertrags-<br/>
                        verlängerung
                        <label className="mb-0 eless-checkbox dropdown-arrow twolines" >
                            <span id={"vertragsverlaengerungIcon"} className={'o-down-1'} />
                        </label>
                    </div>
                    <div id='vertragsverlaengerung' className='mb-3 filter-options'>
                        <label className="control control-radio mr-4" data-filter-value={"Keine Verlängerung"}>
                            Keine Vertragsverlängerung
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, contractExtention)} data-filter={'none'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            1 Monat
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, contractExtention)} data-filter={'1 month'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            3 Monate
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, contractExtention)} data-filter={'3 months'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            6 Monate
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, contractExtention)} data-filter={'6 months'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            12 Monate
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, contractExtention)} data-filter={'12 months'}/>
                            <div className="control_indicator" />
                        </label>
                    </div>
                </div>
                <div className='filter-box'>
                    <div className='filter-dropwdown' data-childid='energiequelle'>
                        Energiequelle
                        <label className="mb-0 eless-checkbox dropdown-arrow" >
                            <span id={"energiequelleIcon"} className={'o-down-1'} />
                        </label>
                    </div>
                    <div id='energiequelle' className='mb-3 filter-options'>
                        <label className="control control-radio mr-4">
                            Grün
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, energySource)}  data-filter={'green'} />
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            Grau
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, energySource)}  data-filter={'grey'} />
                            <div className="control_indicator" />
                        </label>
                    </div>
                </div>
                <div className='filter-box'>
                <div className='filter-dropwdown' data-childid='anbieter'>Anbieter
                    <label className="mb-0 eless-checkbox dropdown-arrow" >
                        <span id={"anbieterIcon"} className={'o-down-1'} />
                    </label>
                </div>
                <div id='anbieter' className='mb-3 filter-options'>
                    <label className="control control-radio mr-4">
                        Persönliche Betreuung
                        <input type='checkbox' onChange={e => this.handleChangeFilter(e, personalSupport)}  data-filter={'yes'}/>
                        <div className="control_indicator" />
                    </label>
                    <label className="control control-radio mr-4">
                        Mit weiteren Energie-Dienstleistungen
                        <input type='checkbox' onChange={e => this.handleChangeFilter(e, additionalServices)} data-filter={'yes'} />
                        <div className="control_indicator" />
                    </label>
                </div>
                </div>
                <div className='filter-box'>
                    <div className='filter-dropwdown' data-childid='kuendigungsfrist'>
                        Kündigungsfrist
                        <label className="mb-0 eless-checkbox dropdown-arrow" >
                            <span id={"kuendigungsfristIcon"} className={'o-down-1'} />
                        </label>
                    </div>
                    <div id='kuendigungsfrist' className='mb-3 filter-options'>
                        <label className="control control-radio mr-4">
                            2 Wochen
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, cancellationPeriod)} data-filter={'2 weeks'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            1 Monat
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, cancellationPeriod)} data-filter={'1 month'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            3 Monate
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, cancellationPeriod)} data-filter={'3 months'}/>
                            <div className="control_indicator" />
                        </label>
                        <label className="control control-radio mr-4">
                            Festes Vertragsende
                            <input type='checkbox' onChange={e => this.handleChangeFilter(e, cancellationPeriod)} data-filter={'none'}/>
                            <div className="control_indicator" />
                        </label>
                    </div>
                </div>
            </div>
                <div className='col-lg-9 col-md-12 col-sm-12'>
                    <Tariffs
                        page={'tariffs'}
                        match={this.props.match.params}
                        offerID={this.props.offerID}
                        customerID={this.props.customerID}
                        tariffs={this.state.tariffs}
                        allFilter={this.state.allFilter}
                    />
                </div>
            </div>
            </div>
        );
    }
}