function unixToDate(unixDate, format) {
    var date;
    var year;
    var month;
    var day;

    if (format === "Ymd"){
        // Convert timestamp to milliseconds
        date = new Date(unixDate*1000);
        year = date.getFullYear();
        month = date.getMonth()+1;
        day = date.getDate();
        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;

        return year + "-" + month+'-'+day;
    }
    else if (format === "api"){
        // Convert timestamp to milliseconds
        date = new Date(unixDate*1000);
        year = date.getFullYear();
        day = date.getMonth()+1;
        month = date.getDate();
        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;

        return year + "-" + month+'-'+day;
    }else if(format === 'fix'){
        //unixDate is not a timestamp in this case
        date = unixDate.split('.');
        day = date[0];
        month = date[1];
        year = date[2];
        return year+'-'+month+'-'+day;
    }
    else if(format === 'dot'){
        //unixDate is not a timestamp in this case
        date = unixDate.split('-');
        day = date[2];
        month = date[1];
        year = date[0];
        return day + '.' + month + '.' + year;
    }
    else{
        return null;
    }

}

export default unixToDate;
