import "whatwg-fetch";

export default async function createOffer(customerID, begin, end, mediumType, consumptionPoints) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionCreateOffer,
            param: {
                customer_id: customerID,
                offer_begin: begin,
                offer_end: end,
                medium_type: mediumType,
                consumption_points: consumptionPoints, // war state.selectedConsumptionPoints
            }
        })
    }).then(res => res.json())
        .then(
            (result) => {
                if (result.status === "ok" && result.result !== null) {
                    return result.result;
                }else{
                    return "ERR";
                }
            }
        ).catch(rejected => {
            console.log('Request failed', rejected);
    });
}