import "whatwg-fetch";

export default async function displayContract(customerID, contractID) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiServiceContractGenerator,
            function: global.apiFunctionDisplayContract,
            param: {
                customer_id: customerID,
                contract_id: contractID,
            }
        })
    }) .then(response => response.blob())
        .then((contractData) => {
                    return contractData;
            }
        ).catch(rejected => {
            console.log('Request failed', rejected);
        });
}