import handleFormatNumber from './handleFormatNumber';

function getLowestTariffPrice(e, filterCategory,  tariffs, allFilter) {

    var lowestPrice = 9999;
    var lowestPriceRes = 0;
    // var kWhRate;
    var basePriceLowest;
    var formatedBase;
    var unformatedTariffPrice;
    var unformatedTariffBase;
    var baseCommaValue;
    var validTariffs;

    const replace_price = function(price)   {
          if(price.indexOf('€') >= 0)   {
              price = price.replace(/\./g, "");
              price = price.replace(",", ".");
              price = price.replace(" €", "");
          }


          price = price.split('.');
          price[0] = handleFormatNumber(price[0]);
          if(typeof price[1] === 'undefined')
              price[1] = '00';
          else if(price[1].length < 2)
              price[1] = price[1] + "0";
          else if(price[1].length > 2)
              price[1] = price[1].substring(0, 2);

          return price[0] + "," + price[1] + " €";

    };

    if (e === null || filterCategory === null || allFilter === null){ //---------- ON LOAD FUNCTION -----------
        if (tariffs === "[]"){
            validTariffs = [];
        }else{
            validTariffs = tariffs.filter((tariff) => {
                //Make price to string so we can replace potential formated code
                unformatedTariffPrice = tariff.price.price_rate + "";
                //replace fromated code back to integer, since we have to compare numbers later
                unformatedTariffPrice = unformatedTariffPrice.replace(",", ".");
                unformatedTariffPrice = parseFloat(unformatedTariffPrice.replace(" ct/kWh", ""));

                //get price (can be formated)
                tariff.price.yearly_price = replace_price(tariff.price.yearly_price + "");
                /*
                            //replace all possible formated values
                            unformatedTariffBase = unformatedTariffBase.replace(" €", "");
                            unformatedTariffBase = unformatedTariffBase.replace(",", "");
                            unformatedTariffBase = unformatedTariffBase.replace(/\./g, "");

                            //cut out comma value
                            baseCommaValue = unformatedTariffBase.substring(unformatedTariffBase.length - 2);
                            unformatedTariffBase = unformatedTariffBase.substring(0, unformatedTariffBase.length - 2);


                            //BASE PRICE FORMAT
                            formatedBase = handleFormatNumber(unformatedTariffBase);
                            formatedBase = formatedBase + "," + baseCommaValue;
                            tariff.price.yearly_price = formatedBase +" €";
                */
                //compare unformated price with current lowest price
                if (lowestPrice > unformatedTariffPrice){
                    //replace new lowest price and format result we will return
                    lowestPrice = unformatedTariffPrice;
                    lowestPriceRes = unformatedTariffPrice +"";
                    lowestPriceRes = lowestPriceRes.replace(".", ",") + " ct/kWh";
                    basePriceLowest = tariff.price.yearly_price;
                }
                if (lowestPrice > 0){
                    //tariff.price.price_rate = lowestPriceRes;
                }else{
                    lowestPriceRes = tariff.price.price_rate + "";
                    lowestPriceRes = lowestPriceRes.replace(".", ",");
                    //tariff.price.price_rate = lowestPriceRes;
                }

                //FORMAT PRICE
                // kWhRate = tariff.price.price_rate + "";
                //tariff.price.price_rate = kWhRate.replace(".", ",");

                return tariff;
            });
        }


    } else{  // ------------------ ON CHANGE FUNCTION --------------
        validTariffs = tariffs;
        let element = e.target;
        let filterValue = element.dataset.filter;

        //Create array with values to Filter
        //If primary value doesnt exist, create it, else update it
        if (typeof allFilter[filterCategory] === 'undefined'){
            allFilter[filterCategory] = [filterValue];
        }else{
            //If secondary value exists, remove it, else create it
            if(allFilter[filterCategory].includes(filterValue)){
                //Remove value from Array on disable radio
                for(let i = 0; i < allFilter[filterCategory].length; i++){
                    if (allFilter[filterCategory][i] === filterValue) {
                        allFilter[filterCategory].splice(i, 1);
                    }
                }

                //Remove primary value if doesnt contain any secondary values
                if(allFilter[filterCategory].length === 0){
                    if (filterCategory in allFilter){
                        delete allFilter[filterCategory];
                    }
                }
            }else{
                //Add value to Array on enable radio
                allFilter[filterCategory].push(filterValue)
            }
        }
        //if(!allFilter.hasOwnProperty(filterCategory))
        if(Object.keys(allFilter).length === 0){
            validTariffs = tariffs.filter((tariff) => {
                //Make price to string so we can replace potential formated code
                unformatedTariffPrice = tariff.price.price_rate + "";
                //replace fromated code back to integer, since we have to compare numbers later
                unformatedTariffPrice = unformatedTariffPrice.replace(",", ".");
                unformatedTariffPrice = parseFloat(unformatedTariffPrice.replace(" ct/kWh", ""));

                //get price (can be formated)
                tariff.price.yearly_price = replace_price(tariff.price.yearly_price + "");

                //compare unformated price with current lowest price
                if (lowestPrice > unformatedTariffPrice){
                    //replace new lowest price and format result we will return
                    lowestPrice = unformatedTariffPrice;
                    lowestPriceRes = unformatedTariffPrice +"";
                    lowestPriceRes = lowestPriceRes.replace(".", ",") + " ct/kWh";
                    basePriceLowest = tariff.price.yearly_price;

                }
                if (lowestPrice > 0){
                    //tariff.price.price_rate = lowestPriceRes;
                }else{
                    lowestPriceRes = tariff.price.price_rate + "";
                    lowestPriceRes = lowestPriceRes.replace(".", ",");
                    //tariff.price.price_rate = lowestPriceRes;
                }

                return tariff;
            });
        }


        else {
            for (var setFilterCategory in allFilter) {
                // eslint-disable-next-line
                validTariffs = validTariffs.filter((tariff) => {
                    //Make price to string so we can replace potential formated code
                    unformatedTariffPrice = tariff.price.price_rate + "";
                    //replace fromated code since we have to compare numbers later
                    unformatedTariffPrice = unformatedTariffPrice.replace(",", ".");
                    unformatedTariffPrice = parseFloat(unformatedTariffPrice.replace(" ct/kWh", ""));

                    //get price (can be formated)
                    unformatedTariffBase = tariff.price.yearly_price + "";

                    //replace all possible formated values
                    unformatedTariffBase = unformatedTariffBase.replace(" €", "");
                    unformatedTariffBase = unformatedTariffBase.replace(",", "");
                    unformatedTariffBase = unformatedTariffBase.replace(/\./g, "");

                    //cut out comma value
                    baseCommaValue = unformatedTariffBase.substring(unformatedTariffBase.length - 2);
                    unformatedTariffBase = unformatedTariffBase.substring(0, unformatedTariffBase.length - 2);

                    //compare if key is in filter
                    for (var filterCategory in tariff["tariff_condition"]) {
                        //if key matches
                        if (filterCategory === setFilterCategory) {
                            if (typeof allFilter[setFilterCategory] !== 'undefined') {
                                if (allFilter[setFilterCategory].includes(tariff["tariff_condition"][setFilterCategory])) {
                                    //BASE PRICE FORMAT
                                    formatedBase = handleFormatNumber(unformatedTariffBase);
                                    formatedBase = formatedBase + "," + baseCommaValue;
                                    tariff.price.yearly_price = formatedBase + " €";

                                    //compare unformated price with current lowest price
                                    if (lowestPrice >= unformatedTariffPrice) {
                                        //replace new lowest price and format result we will return
                                        lowestPrice = unformatedTariffPrice;
                                        lowestPriceRes = unformatedTariffPrice + "";
                                        lowestPriceRes = lowestPriceRes.replace(".", ",") + " ct/kWh";
                                        basePriceLowest = tariff.price.yearly_price;
                                    }

                                    //FORMAT PRICE
                                    // kWhRate = tariff.price.price_rate + "";
                                    //tariff.price.price_rate = kWhRate.replace(".", ",");

                                    return tariff;
                                }
                            }
                        }
                    }

                    //Added because of console warning
                    return 0;
                });
            }
        }
    }
    if (validTariffs.length === 0){
        lowestPriceRes = null;
    }
    return [validTariffs, lowestPriceRes, basePriceLowest];
}


export default getLowestTariffPrice;
