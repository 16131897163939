import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalTitle from "react-bootstrap/ModalTitle";

export default class APIErrorAlert extends React.Component {
    constructor(props) {
        super(props);
        this.closeAlert = this.closeAlert.bind(this);
    }

    closeAlert(){
        this.setState({show: false});
        this.props.closeApiError();
    }

    render() {
        return(
            <Modal id={'apiErrorPopup'} show={this.props.show} onHide={this.closeAlert}>
                <ModalHeader className={'text-center'} closeButton>
                    <span className={'o-delete-circle-1 medium-icon'}/><ModalTitle>Verbindungsfehler</ModalTitle>
                </ModalHeader>
                <ModalBody className={'text-center'}>Es ist ein Problem bei der Verbindung mit dem Server aufgetreten. Einige Funktionalitäten der Webseite könnten dadurch eingeschränkt sein. <br/><br/> Versuchen Sie es bitte später erneut oder kontaktieren Sie uns direkt unter 0821 899998-0</ModalBody>
            </Modal>
        );
    }
}