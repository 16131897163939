import "whatwg-fetch";

export default async function calculateAllTariffs(offerID) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionCalculateAllTariffs,
            param: {
                offer_id: offerID,
            }
        })
    }).then(res => res.json())
        .then((result) => {
                if (result.status === "ok" && result.result !== null) {
                    return result.result;
                }else{
                    return "ERR";
                }
            }
        ).catch(rejected => {
            console.log('Request failed', rejected);
        });
}