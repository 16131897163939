import React, { PureComponent } from 'react';
import {
    PieChart, Pie, Cell,
} from 'recharts';

const COLORS = ['#323232', '#FE821E'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
                                   cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                               }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default class Example extends PureComponent {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/';

    constructor(props) {
        super(props);
        this.state = {
            nothing: '',
        };
    }





    render() {
        let unformatedYearlyPrice;
        let formatedYearlyPrice = this.props.yearlyPrice +"";

        //replace all possible formated values
        formatedYearlyPrice = formatedYearlyPrice.replace(" €", "");
        formatedYearlyPrice = formatedYearlyPrice.replace(",", "");
        formatedYearlyPrice = formatedYearlyPrice.replace(/\./g, "");
        //cut out comma value
        formatedYearlyPrice = formatedYearlyPrice.substring(0, formatedYearlyPrice.length - 2);
        unformatedYearlyPrice = parseInt(formatedYearlyPrice);
        if (this.props.spot === 1){
            unformatedYearlyPrice = 0;
        }
        const data = [
            { name: 'Group A', value: unformatedYearlyPrice },
            { name: 'Group B', value: this.props.sumNetFee },
        ];


        return (
            <PieChart width={400} height={200}>
                <Pie
                    data={data}
                    cx={80}
                    cy={80}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
            </PieChart>
        );
    }
}
