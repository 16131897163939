function getEmailByID(customerID) {
    fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctiongetEmailById,
            param: {
                customer_id: customerID
        }
        })
    }).then(res => res.json())
        .then(result => {
            if (result.status === "ok") {
            }else{
                console.log('Customer Mail not found 1');
            }
        }).catch(rejected => {
            // this.setState({apiError: true});
            console.log('Customer Mail API error');
        });
}
export default getEmailByID;