import "whatwg-fetch";

export default async function generateContract(customerID, contractID, ) {
    return await  fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiServiceContractGenerator,
            function: global.apiFunctionGenerateContract,
            param: {
                customer_id: customerID,
                contract_id: contractID,
            }
        })
    }).then(res => res.json())
        .then((results) => {
                if (results.status === "ok") {
                    return results.result;
                }else{
                    return "ERR";
                }
            }
        ).catch(rejected => {
            console.log('Request failed', rejected);
        });
}