import "whatwg-fetch";

export default async function createConsumptionPoint(consumptionPointData, customerID) {
    return await  fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionCreateConsumptionPoint,
            param: {
                customer_id: customerID,
                postal_code: consumptionPointData[0],
                city: consumptionPointData[1],
                street: consumptionPointData[2],
                house_number: consumptionPointData[3],
                consumption_type: consumptionPointData[4],
                specification: consumptionPointData[5],
                state: consumptionPointData[6],
                load_profile_id: consumptionPointData[7],
                prediction_id: consumptionPointData[8],
                business_type: consumptionPointData[9],
                medium_type: consumptionPointData[10],
                country: 'DE',
            }
        })
    }).then(res => res.json())
        .then((result) => {
            if (result.status === "ok" && result.result !== null) {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(function (error) {                        // catch
            console.log('Request failed', error);
        });
}