import React from 'react';
import $ from "jquery";

export default class SpeechBubble extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClickOutside(event) {
        if(this.wrapperRef && !this.wrapperRef.contains(event.target)){
            $('#'+this.props.id).fadeOut();
        }
    }

    /*
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        //Close on click outside
        this.wrapperRef = node;
    }

    componentDidMount() {
        //Close on click outside
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        //Close on click outside
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        return (
            <div ref={this.setWrapperRef} id={this.props.id} className='bubble'>
                {this.props.content}
            </div>
        );
    }
}