import getUrlParam from "./getUrlParams";

function getEnergyType() {
    var medium_type = null; //null if no data is tracked
    if (typeof getUrlParam()["medium_type"] !== "undefined"){ //if has get Parameter with mediumType set it
        medium_type =  getUrlParam()["medium_type"];
    } else if (typeof localStorage['mediumTypeParam'] !== "undefined")  { //if localStrorage tracked last Selected mediumType set it
        var tmp_medium_type = localStorage['mediumTypeParam'];
        if (tmp_medium_type === "GAS" || tmp_medium_type === "ELECTRICITY"){ //check if manipulated
            medium_type = tmp_medium_type;
        }
    }
    return medium_type;
}

export default getEnergyType;