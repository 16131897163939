
/* API Request */

global.apiURL = 'https://api2.eless.de/';
global.apiBearer = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE2OGVkMThlMjcxYWE2NWQzZTdiNTU0Y2I3ODdjMWM3MDY5N2RiZGRiMzUxM2Q3Yjg5Y2M0YWM5ZmI2NmI0MmU3NmFiOWVkMzliMzliMmI5In0.eyJhdWQiOiI2IiwianRpIjoiYTY4ZWQxOGUyNzFhYTY1ZDNlN2I1NTRjYjc4N2MxYzcwNjk3ZGJkZGIzNTEzZDdiODljYzRhYzlmYjY2YjQyZTc2YWI5ZWQzOWIzOWIyYjkiLCJpYXQiOjE2MDI2NzE0ODYsIm5iZiI6MTYwMjY3MTQ4NiwiZXhwIjoxNjM0MjA3NDg2LCJzdWIiOiIiLCJzY29wZXMiOlsiKiJdfQ.abIbKK66k5QgWhKEPhrLcI0F8cNJQusdFIWblpNZb1n9ACF1TdOwMCN2s3mU_uG3-FvpL6nDHYPccosoaonOvvnwr-dNlq2qTJAZeRpyadreITPUHAlJvKca0ChH-8oHPtyaGAAu7Wi5ZlSoJsEb09vQ4rtn5PvLJffPUPX_5zb3PZhOgUsFPeg8kxxO3wSXJwrNNcBCRcVaectElk3rsB48L-YtV1JmqgtM0SzhuqrVCvhfM9F3mhZI6c6tSIUjoTOSd-ljNHl0HRq4dFlREzvReScbi8lmGTN4BabJRYCZyG7Infu-cjGrFsx-CqqcOqOTKZQDA1TrE_z5mEsazmW0D87r0RXHOW8FYbLt81f3JjCvobs9VeBKDXTV2bP4Umxv079EvdownnFpk7UD1KI6TsnVlg9Livt7qT-Z25naxgfFF71TYI5V478xS5lsOlHNFjEEtmAf7Jf0K8c3H484EFbD4Nt7T5dFYzzfOy9eU6hXI-b3YV0j5GkYKP2l9WaiInsgvIdnxL7L2hmSEC_JbQbbdcKIm4ibae9zCeIcg7vnqz2b-vBljau6tjYugW6VQU_UFpRoyMfoKYJ52e3XSJz2jsfh0KYhnAqUb7JuZUTk1Rm4lYepSvAfIpFQ8hO-XrTX9KAWx8bDEwBWnmhlA6Ezuap4Y0IG4Zk0h9c'
global.apiContentType = 'application/json';
global.apiParamState = 'BY';

//loadProfile case
global.apiAccept = 'application/json';
global.apiContentTypeFile = 'multipart/form-data';

/* User Data */

global.apiUser = 'abc';
global.apiUserAuthToken = 'abc';

/* API Parameters */

// prediction parameters
global.apiParamUserID = '10';
global.apiParamPredictionBegin = '2020-01-01';
global.apiParamPredictionEnd = '2020-12-31';
global.apiParamRatioBaseValue = '0.35';
global.apiParamBaseAdjustmentImpact = '1';
global.apiParamPredictedConsumption = '871080';
global.apiParamSheetNumber = '0';
global.apiParamFile = 'file.csv';

/* API Services */

global.apiElectricityProfileService = 'ElectricityProfileService';
global.apiGasProfileService = 'GasProfileService';
global.apiCalculationManagementService = 'CalculationManagementService';
global.apiServicePrediction = 'PredictionService';
global.apiServiceNetfee = 'NetFeeService';
global.apiServiceLoadProfile = 'LoadProfileService';
global.apiServiceContractGenerator = "ContractGenerator";

/* API Functions */

/* password */


global.apiFunctionPasswordForgotten = "password_forgotten";
global.apiFunctionResetPassword = "reset_password";

/* login */
global.apiFunctionVerifyPassword = "customer_verify_password";


/* customer */
global.apiFunctiongetEmailById = "get_email_by_customer_id";

//calculate
global.apiFunctionCalculateAllTariffs = "calculate_all_tariffs";
global.apiFunctionGetCustomer = "get_customer";
global.apiFunctionModifyCustomer = "modify_customer";

//subscribe model
global.apiFunctionCreateSubscription = "create_subscription";

//contract
global.apiFunctionCreateContract = "create_contract";
global.apiFunctionGetContract = "get_contract";
global.apiFunctionGetContractsByCustomer = "get_contracts_by_customer";
global.apiFunctionModifyContract = "modify_contract";
global.apiFunctionGenerateContract = "generate_contract";
global.apiFunctionDisplayContract = "display_contract";

//get_all_supplier for preProvider
global.apiFunctionGetAllSuppliers = "get_all_suppliers";


// loadProfile functios apiFunctionGetLoadProfileAnalysisData
global.apiFunctionGetPredictionInformation = 'getPredictionInformation';

// electricityProfiles functions
global.apiFunctionGetProfiles = 'getProfiles';
global.apiFunctionPredictStandardProfile = 'predict_standard_profile';

// consumptionPoint functions
global.apiFunctionCreateConsumptionPoint = 'create_consumption_point';
global.apiFunctionOfferAddConsumptionPoint = 'add_consumption_point';
global.apiFunctionDeleteConsumptionPoint = 'delete_consumption_point';
global.apiFunctionModifyConsumptionPoint = 'modify_consumption_point';
global.apiFunctionGetCalculatedTariffs = 'get_calculated_tariffs';
global.apiFunctionGetCspointsByOffer = 'get_consumption_points_of_offer';
global.apiFunctionGetCspointsByCustomer = 'get_consumption_points_by_customer';

// prediction functions (lastgang(loadprofile) upload)
global.apiFunctionPredictLoadProfile = 'predictLoadProfile';


global.apiFunctionActivateCustomer = 'activate_customer';

// netfeeservice functions
global.apiFunctionGetStreets = 'get_streets';
global.apiFunctionsGetCity = "get_cities";
global.apiFunctionCalculateNetFee = "calculate_net_fee";

/* OFFER, TOKEN AND VOUCHER */
global.apiFunctionGetOfferByToken = "get_offer_by_token";
global.apiFunctionCreateOffer = "create_offer";
global.apiFunctionModifyOffer = "modify_offer";
global.apiFunctionValidateVoucher = "validate_offer_access_key";

/* URL's for redirects and rendering */
global.defaultURL = "localhost:3000";
global.LoginURL = '/login';
global.HomeURL = '/abnahmestellen';
global.TariffsPage = '/tarife';
global.ConsumptionPointsInfoPage = '/lieferstellen';
global.ContractAddFinishedPage = '/vertrag-abschliessen';
global.InvalidTokenPage = '/invalidtoken';
global.ExpiredTokenPage = '/expiredtoken';
global.ResetPassword = '/passwort-vergessen';
global.ActivateNewPassword = '/passwort-aktivieren';
global.PasswordForgottenMail = '/passwort-vergessen-mail';
global.ContractSuccess = '/herzlichen-glueckwunsch';
global.Settings = '/einstellungen';
global.Accountsettings = '/kontoeinstellungen';
global.Resetpassword = '/passwort-zuruecksetzen';
global.Contracts = '/vertraege';
global.ForgotPassword = '/passwort-vergessen';
global.BlankDefaultURL = 'app.eless.de';

global.maxAttemts = 6;


//keys
global.reCapture = "6LedYsEUAAAAAFFG6PyDJwcjpd79c50Me4fQ2PXs";
global.encriptKey = "ClkhO7rFZswdObQLANOO5rQFpXAGx8Qj";

global.rlmProfiles = [
    "Büro",
    "Tankstelle",
    "Metzgerei",
    "Einzelhandel",
    "Gastronomie",
    "Bäckerei",
    "Fitnessstudio",
    "Handwerksbetriebe",
    "Wäscherei",
    "Autohaus",
    "Hotel",
    "Produzierendes Gewerbe",
    "Druckerei",
    "Spedition",
    "Sonstiges"
];

global.slpProfiles = {};


global.rlmCodes = {

};

//define here how far the first loading bar should go until the fetch is processing
global.loadingFirstSet = 92;
//define here how fast the first loading bar should load until the fetch is processing
global.loadingFirstSetInterval = 92;

/* translation for netFee Service */
global.arrayWithElectricityTranslations = {
    accounting_rate: {name: 'Abrechnungskosten', unit: 'EUR_PER_MONTH'},
    concession_fee: {name: 'Konzessionsabgabe', unit: 'CT_PER_KWH'},
    deactivation_levy: {name: '§18 AbLaV Umlage', unit: 'CT_PER_KWH'},
    fee_kwk: {name: 'KWKG-Umlage', unit: 'CT_PER_KWH'},
    fee_meter_reading: {name: 'Messststellenbetrieb', unit: 'EUR_PER_MONTH'},
    fee_measurement_reading: {name: 'Messdienstleistungen', unit: 'EUR_PER_MONTH'},
    offshore_levy: {name: 'Offshore-Umlage', unit: 'CT_PER_KWH'},
    peak: {name: 'Leistungspreis', unit: 'EUR_PER_KW'},
    reactive_consumption: {name: 'Kosten für Blindmehrarbeit', unit: 'CT_PER_KWH'},
    real_energy_d: {name: 'Arbeitspreis', unit: 'CT_PER_KWH'},
    renewable_energy_levy: {name: 'EEG-Umlage', unit: 'CT_PER_KWH'},
    socket: {name: 'Grundpreis', unit: 'EUR_PER_MONTH'},
    special_customer_additive: {name: '§19 StromNEV Umlage', unit: 'CT_PER_KWH'},
    tax: {name: 'Stromsteuer', unit: 'CT_PER_KWH'}
};

global.arrayWithGasTranslations = {
    accounting_rate: {name: 'Abrechnungskosten', unit: 'EUR_PER_MONTH'},
    concession_fee: {name: 'Konzessionsabgabe', unit: 'CT_PER_KWH'},
    neutrality_charge: {name: 'Bilanzierungsumlage', unit: 'CT_PER_KWH'},
    fee_measurement_reading: {name: 'Messdienstleistungen', unit: 'EUR_PER_MONTH'},
    fee_meter_reading: {name: 'Messstellenbetrieb', unit: 'EUR_PER_MONTH'},
    peak: {name: 'Leistungspreis', unit: 'EUR_PER_KW'},
    real_energy: {name: 'Arbeitspreis', unit: 'CT_PER_KWH'},
    socket: {name: 'Grundpreis', unit: 'EUR_PER_MONTH'},
    tax: {name: 'Erdgassteuer', unit: 'CT_PER_KWH'},
    co2_tax: {name: 'CO2-Steuer', unit: 'CT_PER_KWH'}
};
/* end translation for netfee service */