import "whatwg-fetch";

export default async function modifyConsumptionPoint2(cspID, customerID, offerID, company, city, postalCode, street, houseNumber, state, customerNumber, preProviderName, maloID) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionModifyConsumptionPoint,
            param: {
                consumption_point_id: cspID,
                customer_id: customerID,
                offer_id: offerID,
                invoice_address: {
                    company: company,
                    city: city,
                    postal_code: postalCode,
                    street: street,
                    house_number: houseNumber,
                    state: state,
                    country: "DE",
                },
                internal_customer_id: customerNumber,
                supplier_name: preProviderName,
                external: 1,
                market_identifier: maloID,
            }
        })
    }).then(res => res.json())
        .then((result) => {
            if (result.status === "ok") {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(function (error) {                        // catch
            console.log('Request failed', error);
        });
}