import React from "react";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Alert from "react-bootstrap/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
// import AES from "crypto-js/aes";
// import ENC from "crypto-js/enc-utf8";

export default class ChangeEmail extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            apiError: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    validateEmail(email) {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit(e){
        //Process values
        // var customerID = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
        var email = document.getElementById('email').value;

        //Message handling
        var errorHandling = document.getElementById("error-handling");
        var successHandling = document.getElementById("success-handling");
        var invalidEmailMessage = document.getElementById('invalidEmail');

        //Remove old ErrorMessages
        errorHandling.classList.add('d-none');
        invalidEmailMessage.classList.add('d-none');
        successHandling.classList.add('d-none');

        //Ladeanimation & Benutzeraktionen einschränkung
        var subBtn = e.target;
        var spinner = document.getElementById("spinner");
        subBtn.disabled = true;
        spinner.classList.remove("d-none");

        if(this.validateEmail(email)){
            //TODO Hier kommt der email change fetch
            successHandling.classList.remove('d-none');

            subBtn.disabled = false;
            spinner.classList.add("d-none");
        }else{
            errorHandling.classList.remove('d-none');
            invalidEmailMessage.classList.remove('d-none');

            subBtn.disabled = false;
            spinner.classList.add("d-none");
        }

        //TODO Change Email fetch
    }

    render (){
        return(
            <>
                <APIErrorAlert closeApiError={() => this.setState({apiError: false})} show={this.state.apiError} />
                <Modal id={'resetPasswordPopup'} show={this.props.show} onHide={this.props.closePopup}>
                    <ModalHeader closeButton>
                    </ModalHeader>
                    <ModalBody className={'text-center'} closeButton>
                        <div className={"row"}>
                            <div className={"col-md-12"} >
                                <div id={"login-body"} className={"eless-form"}>
                                    <h2 className={'login-title'}>E-Mail ändern</h2>
                                    <span className={'mb-3 d-block'}>
                                        Bitte geben Sie die neue E-Mail Adresse ein.
                                    </span>
                                    <Alert id="success-handling" className={'mb-1 d-none eless-success text-left'}>
                                        <span className={'o-checked-circle-1 medium-icon'}/><b>Erfolg!</b><br />
                                        <span className={"ml-1"}>Es wurde eine Nachricht an die neue E-Mail Adresse gesendet. Überprüfen Sie bitte Ihr postfach und bestätigen Sie die E-Mail über den erhaltenen Link</span>
                                    </Alert>

                                    <Alert id="error-handling" className={'mb-1 d-none eless-error text-left'}>
                                        <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b><br />
                                        <span id={"invalidEmail"} className={"d-none"}>Geben Sie bitte eine gültige E-Mail ein.</span>
                                    </Alert>
                                    <input autoComplete="new-password" type='text' id='email' placeholder='E-Mail' />
                                    <button id={"resetPassword"}  className='eless-weiter-btn eless-btn w-100 mb-5 mt-2 position-relative' onClick={(e) => this.handleSubmit(e)}>
                                        Weiter
                                        <span id={'spinner'} className={'d-none'}><FontAwesomeIcon className={'fa-spin fa-2x'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}