import React from 'react';

export default class ErrorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log('Error:', error, 'Info:', JSON.stringify(info));
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div><h2>Etwas ist schief gelaufen...</h2>Versuchen Sie es bitte später noch einmal oder kontaktieren Sie uns unter 0821 89 99 98-0</div>;
        }
        return this.props.children;
    }
}