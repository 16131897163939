function handleFormatEnergyPrice(unformattedPriceRate, unit = "ct/kWh"){
    var formattedPriceRate;
    var priceRateString = unformattedPriceRate +'';
    if(priceRateString.includes(unit)){
        var priceRateParts = priceRateString.split(" "); // Diese Zeile und die nachfolgende if hizugefügt wegen einem Bug mit Gas Energiepreis
        if(priceRateParts[0].length < 5){
            var kWhSplit = priceRateParts[0].split(',');
            if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 1) formattedPriceRate =  kWhSplit[0] + "," + kWhSplit[1] + "00 " + unit;
            else if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 2) formattedPriceRate =  kWhSplit[0] + "," + kWhSplit[1] + "0 " + unit;
        }else{
            formattedPriceRate = unformattedPriceRate; // Original line
        }
    }else{
        kWhSplit = priceRateString.split('.');
        if (typeof kWhSplit[1]=== "undefined") formattedPriceRate =  kWhSplit[0] + ",000 " + unit;
        else if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 1) formattedPriceRate =  kWhSplit[0] + "," + kWhSplit[1] + "00 " + unit;
        else if (typeof kWhSplit[1]!== "undefined" && kWhSplit[1].length === 2) formattedPriceRate =  kWhSplit[0] + "," + kWhSplit[1] + "0 "+ unit;
        else formattedPriceRate =  kWhSplit[0] + "," + kWhSplit[1] + " " + unit;
    }
    return formattedPriceRate;
}

export default handleFormatEnergyPrice;