import React from 'react';
import RefreshOfferFunction from "../functions/refreshOfferFunction";
import checkSession from "../functions/checkSessionStatus";

export default class Breadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cspointsResult: [],
            cspointsResultArray: [],
            customerID: 0,
            offerID: 0,
            begin: '',
            end: '',
            visited: [1],
            totalConsumption: 0,
            consumptionPointCount: 0,
            firstLink: <span />,
            secondLink: <span />,
            thirdLink: <span />,
            fourthLink: <span />,
        };
    }

    componentDidMount() {
        checkSession();

        //variable for activating visited URL's
        var visitedPaths = this.state.visited;

        switch(this.props.match.path){
            case '/abnahmestellen':
                break;
            case '/tarife/:key':
                if(!visitedPaths.includes(2))visitedPaths.push(2);
                break;
            case '/lieferstellen/:key':
                if(!visitedPaths.includes(3))visitedPaths.push(2,3);
                break;
            case '/vertrag-abschliessen/:key':
                if(!visitedPaths.includes(4))visitedPaths.push(2,3,4);
                break;
            default:
                break;
        }
        //Save the updated array
        this.setState({visited: visitedPaths});

        //current active link
        let firstClass='';
        let secondClass='';
        let thirdClass='';
        let fourthClass='';

        if(this.props.active === 1){
            firstClass = 'active';
        }else if(this.props.active === 2){
            secondClass = 'active';
        }else if(this.props.active === 3){
            thirdClass = 'active';
        }else{
            fourthClass = 'active';
        }

        //Get User Token for other Links
        var userToken = this.props.match.params.key;

        //Display links with href only if visited before
        var firstElement;
        var secondElement;
        var thirdElement;
        var fourthElement;

        //First Link
        firstElement = <a href={global.HomeURL} className={'bc-item col-md-3 col-lg-3'}>
            <div id={'firstBreadcrumb'} className={firstClass}>
                <div className={'first circle'}>1</div>Lieferstellen wählen
            </div>
        </a>;

        //Second link
        if(visitedPaths.includes(2)){
            secondElement = <a href={global.TariffsPage+'/'+userToken} className={'bc-item col-md-3 col-lg-3'}>
                <div id={'secondBreadcrumb'} className={secondClass + ' md-text-center'}>
                    <div className={'circle'}>2</div>Angebote vergleichen
                </div>
            </a>;
        }else{
            secondElement = <div id={'secondBreadcrumb'} className={'bc-item col-md-3  col-lg-3 '+secondClass}>
                        <div className={'circle'}>2</div>Angebote vergleichen
                </div>;
        }

        //Third link
        if(visitedPaths.includes(3)){
            thirdElement = <a href={global.ConsumptionPointsInfoPage+'/'+userToken} className={'bc-item col-md-3 col-lg-3'}>
                <div id={'thirdBreadcrumb'} className={thirdClass}>
                    <div className={'circle'}>3</div>Vertragsdaten angeben
                </div>
            </a>;
        }else{
            thirdElement = <div id={'thirdBreadcrumb'} className={'bc-item col-md-3  col-lg-3 '+thirdClass}>
                    <div className={'circle'}>3</div>Vertragsdaten angeben
            </div>;
        }

        //Fourth link
        if(visitedPaths.includes(4)){
            fourthElement = <a href={global.ContractAddFinishedPage+'/'+userToken} className={'bc-item col-md-3  col-lg-3'}>
                <div id={'fourthBreadcrumb'} className={fourthClass}><div className={'circle'}>4</div>Bestellübersicht ändern</div>
            </a>;
        }else{
            fourthElement = <div id={'thirdBreadcrumb'} className={'bc-item col-md-3 col-lg-3'+fourthClass}>
                    <div className={'circle'}>4</div>Bestellübersicht ändern
            </div>;
        }

        //Put the links into state for render
        this.setState({
            firstLink: firstElement,
            secondLink: secondElement,
            thirdLink: thirdElement,
            fourthLink: fourthElement
        })
    }

    render(){

        var refreshContent = "";
        var actionOneMargin = "mb-2";



        if(this.props.active === 1){
            refreshContent = <span/>;
            actionOneMargin  = "mb-4";
        }else{
            refreshContent = <div className={"mb-4"}>
                    <RefreshOfferFunction />
            </div>;
        }
        return (
            <div>
                <div className={'eless-breadcrumb row' + actionOneMargin}>
                    {this.state.firstLink}
                    {this.state.secondLink}
                    {this.state.thirdLink}
                    {this.state.fourthLink}
                </div>
                {refreshContent}
            </div>

        );
    }
}

