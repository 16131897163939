import React from "react";
import checkSessionStatus from "../functions/checkSessionStatus";
import getContractsByCustomer from "../fetches/getContractsByCustomer";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import handleFormatEnergyPrice from "../functions/handleFormatEnergyPrice";
import handleFormatDate from "../functions/handleFormatDate";
import translateCancellationPeriod from "../functions/translateCancelation";
import translateContractStatus from "../functions/translateContractStatus";
import Header from "../Header";
import Footer from "../Footer";
const AES = require("crypto-js/aes");
const ENC = require("crypto-js/enc-utf8");

export default class Contracts extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            contracts: []
        };

        this.getContent = this.getContent.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
    }

    componentDidMount() {
        checkSessionStatus();

        var customerID = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
        getContractsByCustomer(customerID).then(result => {
            if(result !== 'ERR'){
                this.setState({contracts: result});
            }else{
                this.apiErrorOccured('getContractsByCustomer', result)
            }
        });
    }

    getContent(){
        var hasData = 0;
        //return value if emtpy table
        var emptyTable = <div className={'contract-row'}>
            <div className={'col-md-12'}>
                Sobald Sie einen Vertrag abgeschlossen haben, wird dieser hier für Sie abgelegt.
            </div>
        </div>;

        if(this.state.contracts !== [] && this.state.contracts !== '[]'){
            var content;
            content = this.state.contracts.map((contract, index) => {
                //format value
                if(contract.offer_result !== null) {
                    var name = Contracts.checkTariffName(contract.offer_result.tariff.name);
                    var priceRate = handleFormatEnergyPrice(contract.offer_result.price.price_rate);
                    var date = handleFormatDate(contract.contract_end);
                    var cancellationPeriod = translateCancellationPeriod(contract.offer_result.keyfacts.cancellation_period);
                    var status = translateContractStatus(contract.status);

                    if (contract.status !== "checkout") {
                        return (
                            <div className={'contract-row'}>
                                <div className={'row'}>
                                    <div className={'col-lg-2 col-md-3 col-sm-3 col-6'}>{name}</div>
                                    <div className={'col-lg-2 col-md-2 col-sm-3 d-none d-sm-flex'}>{priceRate}</div>
                                    <div className={'col-lg-2 col-md-2 d-none d-md-flex'}>{date}</div>
                                    <div className={'col-lg-2 d-none d-lg-flex'}>{cancellationPeriod}</div>
                                    <div className={'col-lg-2 col-md-2 col-sm-3 d-none d-sm-flex'}>{status}</div>
                                    <div className={'col-lg-2 col-md-3 col-sm-3 col-6 text-decoration-underline disabled-download-link'}>Vertrag downloaden</div>
                                </div>
                            </div>)
                    }
                }
            });
           // if (content[0] === undefined) return emptyTable; //there was no contract submitted -> return empty table

            // check if table has valid contract
            content.forEach(function(item){
                if(typeof item !== "undefined") hasData = 1
            });

            if (hasData === 1) return content; // return filled table
            else return emptyTable
        }else{
            return emptyTable; // no contracts -> empty table
        }
    }

    static checkTariffName(tariffName){
        if (tariffName.length > 17){
            return tariffName.substr(0, 15) +".."
        }
        return tariffName;
    }


    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
    }


    render (){
        var contracts = this.getContent();

        return(
            <>
                <Header />
                <div className='max-width-content'>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                <div className={'tariffs-title section_heading'}>
                    <h2>Verträge</h2>
                </div>
                <div className={'row mb-3'}>
                    <div className={'col-lg-2 col-md-3 col-sm-3'}><h5>Tarif</h5></div>
                    <div className={'col-lg-2 col-md-2 col-sm-3 d-none d-sm-flex'}><h5>Energiepreis</h5></div>
                    <div className={'col-lg-2 col-md-2 d-none d-md-flex'}><h5>Laufzeit</h5></div>
                    <div className={'col-lg-2 d-none d-lg-flex'}><h5>Kündigungsfrist</h5></div>
                    <div className={'col-lg-2 col-md-2 col-sm-3 d-none d-sm-flex'}><h5>Status</h5></div>
                </div>
                {contracts}
                </div>
                <Footer />
            </>
        );
    }
}