import React, { PureComponent } from 'react';
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class MoreInformation extends PureComponent {
    render() {
        var headline;
        var label;
        if (this.props.mediumType === "GAS") {
            headline = <h4>Gaskennzeichnung</h4>;
            if (this.props.tariff.tariff_information.labelling_file_id === null){
                label = <span>Der Versorger hat leider keine Gaskennzeichnung hinterlegt.</span>
            }else{
                label =  <a target={"_blank"} href={"https://api2.eless.de/static_file?file_id=" + this.props.tariff.tariff_information.labelling_file_id}>
                    <FontAwesomeIcon icon={faDownload} color={"#FE821E"}/> <span className={"downloadLink"}>Gaskennzeichnung herunterladen</span>
                </a>
            }
        }
        else {
            headline = <h4>Stromkennzeichnung</h4>;
            if (this.props.tariff.tariff_information.labelling_file_id === null){
                label = <span>Der Versorger hat leider keine Stromkennzeichnung hinterlegt.</span>
            }else{
                label =  <a target={"_blank"} href={"https://api2.eless.de/static_file?file_id=" + this.props.tariff.tariff_information.labelling_file_id}>
                    <FontAwesomeIcon icon={faDownload} color={"#FE821E"}/> <span className={"downloadLink"}>Stromkenzeichnung herunterladen</span>
                </a>
            }
        }

        return (
            <div className={"row"}>
                <div className={"col-md-12 col-sm-12 col-lg-12"}>
                    {headline}
                </div>
                <div>
                    <div className={"row"}>
                        <div className={"col-md-12 col-sm-12 col-lg-12 ml-3 mt-2 mb-3"}>
                            {label}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}