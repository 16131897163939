import React from 'react';

class Footer extends React.Component {

    render() {
        return(<footer id={'footer'} className='footer'>
            <div className='section-content mt-3'>
                <div className="row m-0">
                    <div className="col-md-6 left-side">e.less Systems GmbH © 2019-2021</div>
                    <div className="col-md-6 text-right">Support (Mo. bis Fr. 9-17 Uhr) +49 821 899 998 - 0</div>
                </div>
            </div>
        </footer>)
    }
}

export default Footer;