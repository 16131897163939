import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalTitle from "react-bootstrap/ModalTitle";

export default class CustomMessageAlert extends React.Component {

    render() {
        return(
            <Modal id={'apiErrorPopup'} show={this.props.show} onHide={this.props.closeModal}>
                <ModalHeader className={'text-center'} closeButton>
                    <span className={'o-delete-circle-1 medium-icon'}/><ModalTitle>{this.props.title}</ModalTitle>
                </ModalHeader>
                <ModalBody className={'text-center'}>{this.props.content}</ModalBody>
            </Modal>
        );
    }
}