import React from "react";
import ResetPassword from "../userlogin/ResetPassword";
import VerifyActionPopup from "../benutzereingabe/VerifyActionPopup";
import ChangeEmail from "../userlogin/ChangeEmail";
import checkSessionStatus from "../functions/checkSessionStatus";
import Footer from "../Footer";
import Header from "../Header";

export default class Accountsettings extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            showResetPasswordBox: false,
            showVerifyAction: false,
            showChangeEmail: false,
        };

        this.deleteAccount = this.deleteAccount.bind(this);
    }

    componentDidMount() {
        checkSessionStatus();
    }

    deleteAccount(){
        console.log('Account Deleted');
        this.setState({showVerifyAction: false});
        window.location.href = "https://eless.de";
    }

    render (){
        return(
            <>
                <Header />
                <div className='max-width-content'>
                <div className={'tariffs-title section_heading'}>
                <h2>Kontoeinstellungen</h2>
                </div>
                {/*<div className={'accountsetting-row mb-3'}>*/}
                {/*    <div className={'row'}>*/}
                {/*        <div className={'col-4 col-sm-4'}><h5>E-Mail</h5></div>*/}
                {/*        <div className={'col-4 col-sm-6'}>login@email.com</div>*/}
                {/*        <div className={'col-2 col-sm-2 text-decoration-underline cursor-pointer'} onClick={() => this.setState({showChangeEmail: true})}>Bearbeiten</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={'accountsetting-row mb-5'}>
                    <div className={'row'}>
                        <div className={'col-4 col-sm-4'}><h5>Passwort</h5></div>
                        <div className={'col-4 col-sm-6'}>**********</div>
                        <div className={'col-2 col-sm-2 text-decoration-underline cursor-pointer'} onClick={() => this.setState({showResetPasswordBox: true})}>Bearbeiten</div>
                    </div>
                </div>
                {/*<div className={'row'}>*/}
                {/*    <div className={'col-md-12 text-decoration-underline'} onClick={() => this.setState({showVerifyAction: true})}>Account löschen</div>*/}
                {/*</div>*/}
                <ResetPassword show={this.state.showResetPasswordBox} closePopup={() => this.setState({showResetPasswordBox: false})} />
                <VerifyActionPopup show={this.state.showVerifyAction} content={'Sind Sie sich sicher, dass Sie Ihr Account löschen möchten?'} handleClose={() => this.setState({showVerifyAction: false})} delete={() => this.deleteAccount} />
                <ChangeEmail show={this.state.showChangeEmail} closePopup={() => this.setState({showChangeEmail: false})} />
                </div>
                <Footer />
            </>
        );
    }
}