import "whatwg-fetch";

async function modifyCustomer3(customerID, password, gender, company, name, phone) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiCalculationManagementService,
            function: global.apiFunctionModifyCustomer,
            param: {
                customer_id: customerID,
                password: password,
                gender: gender,
                company: company,
                last_name: name,
                phone: phone,
            }
        })
    })
        .then(res => res.json())
        .then(result => {
            console.log("test", result);
            if (result.status === "ok") {

                return result.result;
            }else{
                return "ERR";
            }
        }).catch(rejected => {
            console.log('Request failed', rejected);
        });
}

export default modifyCustomer3;