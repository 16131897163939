import getOfferByToken from "./getOfferByToken";
import $ from "jquery";
import calculateAllTariffs from "../fetches/calculateAllTariffs";

function refreshOffer() {
    var path = window.location.pathname.split("/");
    if (path.includes("tarife") === true){
        //skip loading animation here
        $( ".eless-weiter-btn" ).each(function( btn ) {
            $(this).attr("disabled", true);
        });

        $( ".eless-cta-btn" ).each(function( btn ) {
            $(this).attr("disabled", true);
        });
    }else{
        var submitBtn = document.getElementsByClassName("eless-weiter-btn");
        if(typeof submitBtn[0] === 'undefined'){
            submitBtn = document.getElementsByClassName("eless-cta-btn");
            submitBtn[0].disabled = true;
        }else{
            submitBtn[0].disabled = true;
        }
        //loading spinner in button
        // var loadingLoadProfileSpinner = document.getElementById("loadingLoadProfileSpinner");
        // loadingLoadProfileSpinner.classList.remove('d-none');

        /*
            $( ".clickable-icon" ).each(function( icon ) {
                var $this = $(this);
                $this.click(false);
                $this.addClass("fa-disabled")
            });
        */
    }

    getOfferByToken().then(res =>{
        var token = res.result.token;
        var offerID = res.result.id;
        calculateAllTariffs(offerID).then((result) => {
            if (result !== "ERR") {
                //on success
                //reloacationg to benefits
                window.location.href = global.TariffsPage + "/" + token;
            }else{
                //error
                if (path.includes("tarife")){
                    $( ".eless-weiter-btn" ).each(function( btn ) {
                        $(this).attr("disabled", false);
                    });
                }else{
                    submitBtn[0].disabled = false;
                    // loadingLoadProfileSpinner.classList.add('d-none');
                    /*
                    $( ".clickable-icon" ).each(function( icon ) {
                        var $this = $(this);
                        $this.click(true);
                        $this.removeClass("fa-disabled")
                    });
                    */
                }
            }
        })
    });
}

export default refreshOffer;