import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Tariffs from "../requests/tariffs/Tariffs";
import getOfferByToken from "../functions/getOfferByToken";
import handleFormatNumber from "../functions/handleFormatNumber";
import refreshOffer from "../functions/refreshOfferBtnClick";
import getCountDown from "../functions/refreshOfferCountDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSpinner} from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../benutzereingabe/Breadcrumb";
import $ from "jquery";
import toggleIcon from "../functions/toggleIcon";
import Alert from "react-bootstrap/Alert";
import getStreetsArray from "../functions/getStreetsArray";
import LoadingAnimationDotted from "../functions/loadingAnimationsDotted";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import getCspointsByOffer from "../fetches/getCspointsByOffer";
import getPredictionInformation from "../fetches/getPredictionInformation";
import getCustomer from "../fetches/getCustomer";
import getContract from "../fetches/getContract";
import modifyContract from "../fetches/modifyContract";
import handleFormatEnergyPrice from "../functions/handleFormatEnergyPrice";
import handleFormatBasePrice from "../functions/handleFormatBasePrice";
import displayContract from "../fetches/displayContract";
import getUrlParam from "../functions/getUrlParams";
import Header from "../Header";
import Footer from "../Footer";
import validateVar from "../functions/validateVariable";

var _rendered = false;

export default class ContractAddFinished extends React.Component {
    constructor() {
        super();
        this.state = {
            customerData: [],
            tariff: [],
            cpData: "",
            customerID : 0,
            contractID : 0,
            postalCode: '',
            apiError: false,
            contractLink: "",
            contractName: "",
            loadingSpinner: '',
            buttonDisabled: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.editPersonalData = this.editPersonalData.bind(this);
        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.createStreetAutocompleteBox = this.createStreetAutocompleteBox.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.handleDisplayContract = this.handleDisplayContract.bind(this);
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    handleStreetChange(e){
        this.createStreetAutocompleteBox(e.target, this.state.streetsArray);
    }

    editPersonalData(){
        window.location.href = global.ConsumptionPointsInfoPage+'/'+this.props.match.params.key;
    }

    componentDidMount() {
        getOfferByToken().then(res =>{
            if(res !== 'ERR'){
                getCountDown(res);
                var offerID = res.result.id;
                var customerID = res.result.customer_id;
                var count = 0;
                let arrayState = [];
                let maxCount;

                this.setState({
                    customerID: customerID,
                    offerID: offerID,
                });

                //Get Consumption Points without their LoadProfile ID's
                getCspointsByOffer(offerID).then((firstResults) => {
                    if(firstResults !== 'ERR'){
                        //var cpRsults =  results.result;
                        this.setState({cpData: firstResults});

                        // GET CONSUMPTION FOR CPS
                        firstResults.map(firstResult => {
                            let csPointData = firstResult;
                            maxCount = firstResults.length;
                            getPredictionInformation(firstResult.prediction_id).then((secondResult) => {
                                if (secondResult !== 'ERR' && secondResult !== null){ // !== null was set before too so let's keep it
                                    //add consumption to obj
                                    csPointData.mean_consumption = handleFormatNumber(secondResult.mean_consumption+"") + " kWh";
                                    //tmp array for saving in state array later
                                    arrayState[count] = csPointData;
                                    //set state array on last loop so it doesnt get re rendert all the time
                                    if (count === maxCount-1)
                                    {
                                        setTimeout(function(){
                                            this.setState({cspointsResultArray: arrayState});
                                        }.bind(this), 3000);
                                    }
                                    count++;
                                    //next service
                                    /* GET CUSTOMER DATA */
                                    getCustomer(customerID).then((thirdResult) => { //Get LoadProfile ID's add it to the Consumption Point Data from prev fetch
                                        if (thirdResult !== 'ERR'){
                                            var data = thirdResult.customer_information;
                                            var billing = data.billing_address;
                                            var bankInfo = data.bank_account;

                                            var customerData = [{
                                                company: data.company,
                                                email: data.email,
                                                gender: "Männlich",
                                                firstname: billing.first_name,
                                                lastname: billing.last_name,
                                                street: billing.line1,
                                                houseNumber: billing.line2,
                                                postalCode: billing.zip,
                                                city: billing.city,
                                                //bank
                                                bank_owner: bankInfo.account_owner,
                                                bank_iban: bankInfo.iban,
                                            }];

                                            getContract(customerID, offerID).then((fourthResult) => {
                                                if (fourthResult !== 'ERR'){
                                                    // const replace_price = function(price)   {
                                                    //     if(price.indexOf('€') >= 0)   {
                                                    //         price = price.replace(/\./g, "");
                                                    //         price = price.replace(",", ".");
                                                    //         price = price.replace(" €", "");
                                                    //     }
                                                    //     price = price.split('.');
                                                    //     price[0] = handleFormatNumber(price[0]);
                                                    //     if(typeof price[1] === 'undefined')
                                                    //         price[1] = '00';
                                                    //     else if(price[1].length < 2)
                                                    //         price[1] = price[1] + "0";
                                                    //     else if(price[1].length > 2)
                                                    //         price[1] = price.substring(0, 2);
                                                    //
                                                    //     return price[0] + "," + price[1] + " €";
                                                    // };
                                                    // var price = fourthResult.offer_result_price_;
                                                    // var replace_price = handleFormatEnergyPrice(price, "€");

                                                    var kWhRate = fourthResult.offer_result.tariff.price.price_rate+"";
                                                    this.signType = fourthResult.offer_result.tariff.tariff_condition.sign_type;
                                                    fourthResult.offer_result.tariff.price.price_rate = kWhRate.replace(".", ",") + " ct/kWh";
                                                    fourthResult.offer_result.tariff.price.yearly_price = handleFormatBasePrice(fourthResult.offer_result.tariff.price.yearly_price);

                                                    this.setState({
                                                        contractID: fourthResult.contract_file,
                                                        customerData: customerData,
                                                        tariff: [fourthResult.offer_result.tariff]
                                                    });

                                                    displayContract(customerID, fourthResult.contract_file).then((contractData) => {
                                                        this.setState({
                                                            contractLink: URL.createObjectURL(contractData),
                                                            contractName: fourthResult.offer_result.tariff.name
                                                        });
                                                    })
                                                }else{
                                                    this.apiErrorOccured('getContract', res);
                                                }
                                            });
                                        }else{
                                            this.apiErrorOccured('getCustomer', res);
                                        }
                                    });
                                }else{
                                    this.apiErrorOccured('getPredictionInformation', res);
                                }
                            });
                            return 0; // return 0 from map function
                        });
                    }else{
                        this.apiErrorOccured('getCspointsByOffer', res);
                    }
                });
            }else{
                this.apiErrorOccured('getOfferByToken', res);
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();

        var error = 0;

        var elessCheckbox = document.getElementById("elessAGB");
        var providerCheckbox = document.getElementById("providerAGB");
        var checkboxError = $("#checkboxError");
        var checkboxErrorSpan = $("#checkboxErrorSpan");
        var errorText = "";

        if (elessCheckbox.classList.contains("o-layout-1")){
            error = 1;
            errorText = "<br> Bitte Stimmen Sie den e.less AGB's zu.";
        }
        if (providerCheckbox.classList.contains("o-layout-1")){
            error = 1;
            errorText = errorText + "<br> Bitte Stimmen Sie den AGB's des Energieversorgers zu.";
        }

        if(!error){
            this.setState({loadingSpinner: <FontAwesomeIcon className={'fa-spin fa-2x position-spinner'} id={"loadingLoadProfileSpinner"} icon={faSpinner}/>, buttonDisabled: true});

            checkboxError.slideUp();
            modifyContract(this.state.customerID, this.state.offerID).then((result) => {
                if (result !== "ERR"){
                    if(this.signType === 'OFFLINE'){
                        window.location.href = global.ContractSuccess+'/offline';
                    }else{
                        window.location.href = global.ContractSuccess+'/online';
                    }
                }else{
                    this.apiErrorOccured('modifyContract', result);
                    this.setState({loadingSpinner: '', buttonDisabled: false});
                }
            });
        }else {
            checkboxErrorSpan.html(errorText);
            checkboxError.slideDown();
        }
    }

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    RefreshClick() {
        refreshOffer()
    }

    handleCheckbox(checkboxName){
        var checkbox = document.getElementById(checkboxName);
        if (checkbox.classList.contains("o-layout-1")) toggleIcon(checkbox, "o-layout-1", 'o-checkbox-checked-square-1');
        else toggleIcon(checkbox, 'o-checkbox-checked-square-1',"o-layout-1");
    }

    handleKeydown(e){
        var x = document.getElementById(e.target.id + "autocomplete-list");
        var currentFocus = this.state.currentFocus;
        if (x) x = x.getElementsByTagName("div");
        if (e.key === 'ArrowDown') {
            currentFocus++;
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (e.key === 'ArrowUp') { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            currentFocus = this.addActive(x, currentFocus);
        } else if (e.key === 'Enter') {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                var div = x[currentFocus];
                e.target.value = div.getElementsByTagName('input')[0].value;
                this.closeAllLists(e.target);
            }
        }
        this.setState({currentFocus: currentFocus});
    }

    createStreetAutocompleteBox(inp, arr){
        var test_string = function(a, b)   {
            a = a.split('');
            b = b.split('');
            for(var i = 0; i < b.length; i++)   {
                if($.isNumeric(a[i]) && $.isNumeric(b[i]))    {
                    if(a[i] !== b[i])
                        return false;
                } else {
                    if(typeof a[i] !== 'undefined'){
                        if($.isNumeric(a[i]) || $.isNumeric(b[i]))
                            return false;
                        if(a[i].toUpperCase() !== b[i].toUpperCase())
                            return false;
                    } else return false;
                }
            }
            return true;
        };

        /*the autocomplete function takes two arguments,
                                the text field element and an array of possible autocompleted values:*/

        /*execute a function when someone writes in the text field:*/
        var a, b, i, val = inp.value;

        this.closeAllLists(inp);

        if (!val) {
            return false;
        }

        var count = 0;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", inp.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        inp.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            arr[i] = arr[i] + "";
            /*check if the item starts with the same letters as the text field value:*/
            if (test_string(arr[i], val)) {
                count++;
                if(count === 8) break;
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                b.innerHTML += arr[i].substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener("click", function (e) {

                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;

                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    // closeAllLists();

                    //close all lists
                    var x = document.getElementsByClassName("autocomplete-items");
                    for (var i = 0; i < x.length; i++) {
                        if (inp !== x[i] && inp !== inp.value) {
                            x[i].parentNode.removeChild(x[i]);
                        }
                    }
                });
                a.appendChild(b);
            }
        }
    }

    addActive(x, currentFocus) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        this.removeActive(x);
        var arr = Array.from(x);
        if (currentFocus >= arr.length){
            currentFocus = 0;
        } else if (currentFocus < 0) {
            currentFocus = 0;
        }
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
        return currentFocus;
    }

    removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    closeAllLists(inp){
        /*close any already open lists of autocompleted values*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (inp !== x[i] && inp !== inp.value) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    handleDisplayContract(e){
        window.open(this.state.contractLink)
    }

    getDisplayContratBtn(){


        /* Sample function that returns boolean in case the browser is Internet Explorer*/
        function isIE() {
            var ua = navigator.userAgent;
            /* MSIE used to detect old browsers and Trident used to newer ones*/
            var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
            return is_ie;
        }
        var isIe = isIE();
        /*
            if x is set in url means that there was an error while generating the contract -> dont display contract because of errors.
         */
        if(isIe) {
            return <button className='eless-zurueck-btn w-100 mt-5 mb-5'>Vertragsvorschau auf Internet Explorer nicht verfügbar</button>
        }
        else if(typeof getUrlParam()["x"] === "undefined") {
            return <a href={this.state.contractLink} download={this.state.contractName + "-Vertrag.pdf"}>
                <button onClick={this.handleDisplayContract} className='eless-zurueck-btn w-100 mt-5 mb-5'>
                    Mustervertrag herunterladen
                </button>
            </a>
        }
        else{
            return <button className='eless-zurueck-btn w-100 mt-5 mb-5'>Vertragsvorschau derzeit nicht verfügbar</button>
        }
    }

    render() {
        if(document.getElementById('streetEdit') !== null){
            if (_rendered === false){
                _rendered = true;
                var postalCodeValue = document.getElementById('postalCodeEdit').value;
                var cityValue = document.getElementById('cityEdit').value;
                getStreetsArray(postalCodeValue, cityValue).then(res =>{
                    this.setState({streetsArray: res});
                });
            }

        }

        function getPreInfos(infos) {
            var cancelation = infos.contract_cancellation;
            if (validateVar(cancelation) && cancelation.supplier_name !== "" && cancelation.internal_customer_id !== ""){

            return  <div className={'col-lg-6 col-md-6 col-sm-12 mb-4'}>
                        <div className={'col-lg-6 col-md-6 col-sm-6 col-6 p-0 d-inline-block'}>
                            <u><b>Kündigung ist erwünscht</b></u><br />
                            <b>Altversorger:</b><br />
                            <b>Kundennummer:</b><br />
                        </div>
                        <div className={'col-lg-6 col-md-6 col-sm-6 col-6 p-0 d-inline-block'}>
                            {cancelation.supplier_name}<br />
                            {cancelation.internal_customer_id}<br />
                        </div>
                    </div>
            }else{
                return  <div className={'col-lg-6 col-md-6 col-sm-12 mb-4'}>
                    <div className={'col-lg-6 col-md-6 col-sm-6 col-6 p-0 d-inline-block'}>
                        <b>Kündigung ist <u>nicht</u> erwünscht</b><br />
                        <b>Altversorger:</b><br />
                        <b>Kundennummer:</b><br />
                    </div>
                    <div className={'col-lg-6 col-md-6 col-sm-6 col-6 p-0 d-inline-block'}>
                        N/A<br />
                        N/A<br />
                    </div>
                </div>
            }
        }

        function getInvoiceInfos(invoice) {
            if (invoice.company !== null){

                return  <div className={'col-lg-6 col-md-6 col-sm-6 mb-4'}>
                    <u><b>Rechnungsanschrift</b></u><br />
                    <div className={'col-lg-4 col-md-4 col-sm-4 col-12 p-0 d-inline-block'}>
                        <b>Firma:</b><br />
                        <b>PLZ:</b><br />
                        <b>Ort:</b> <br />
                        <b>Strasse:</b>  <br />
                        <b>Hausnummer:</b><br />
                    </div>
                    <div className={'col-lg-8 col-md-8 col-sm-8 col-12 p-0 d-inline-block'}>
                        {invoice.company}<br />
                        {invoice.postal_code}<br />
                        {invoice.city}<br />
                        {invoice.street}<br />
                        {invoice.house_number}<br />
                    </div>

                </div>
            }else{
                return  <div className={'col-lg-6 col-md-6 col-sm-12 mb-4'}>
                    <div className={'col-lg-6 col-md-6 col-sm-6 col-6 p-0 d-inline-block'}>
                        <b><u>Rechnungsanschrift entspricht Anschrift des Vertragspartners</u></b><br />
                    </div>
                </div>
            }
        }

        function showBusinessType(businessType, lastType) {
           if (businessType === null || businessType === "") return "Mit Lastgang";
            else {

                return businessType;
           }


           /*
            slpType: global.rlmCodes[csPt.extended_information.business_type],
                        currentBusinessType: global.slpProfiles[csPt.extended_information.business_type]
                        */
        }
        var customerData = this.state.customerData[0];
        var cpData = this.state.cspointsResultArray;

        //wait till all values are set
        if(cpData !== "" && typeof cpData !== "undefined" && customerData !== "" && typeof customerData !== "undefined"){

        return(
            <>
                <Header />
                <div className='max-width-content'>
            <div id={'contractSummary'}>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                <Breadcrumb active={4} match={this.props.match}/>
                <Tabs defaultActiveKey="vertragspartner" className={'main-tabs mt-3'}>
                    <Tab eventKey="vertragspartner" title="Vertragspartner">
                        <div className={'row'}>
                            <div className={'col-lg-6 col-md-6 col-sm-12 mb-4 position-relative personal-data-wrapper'}>
                                <span id={"personal-data-icon"} onClick={() => this.editPersonalData()} className={'eless-icon o-edit-1'} />
                                <b>Persönliche Daten:</b><br />
                                <div className={'col-lg-4 col-md-4 col-sm-4 col-4 p-0 d-inline-block'}>
                                    <b>Firma:</b><br />
                                    <b>Name:</b><br />
                                    <b>E-Mail:</b><br />
                                    <b>Ort:</b><br />
                                    <b>Strasse:</b><br />
                                </div>
                                <div className={'col-lg-8 col-md-8 col-sm-6 col-6 p-0 d-inline-block'}>
                                    {customerData.company}<br />
                                    {customerData.firstname} {customerData.lastname}<br />
                                    {customerData.email}<br />
                                    {customerData.postalCode} {customerData.city}<br />
                                    {customerData.street} {customerData.houseNumber}<br />

                                </div>
                            </div>

                            <div className={'col-lg-6'}>
                                <b>Zahlungsart:</b><br />

                                <div className={'col-lg-4 col-md-4 col-sm-4 col-4 p-0 d-inline-block'}>
                                    Überweisung<br />
                                </div>
                            </div>
                            {/*<div className={'col-lg-6'}>*/}
                                {/*<b>Bankdaten:</b><br />*/}

                                {/*<div className={'col-lg-4 col-md-4 col-sm-4 col-4 p-0 d-inline-block'}>*/}
                                    {/*<b>Kontoinhaber:</b><br />*/}
                                    {/*<b>IBAN:</b><br />*/}
                                {/*</div>*/}
                                {/*<div className={'col-lg-8 col-md-8 col-sm-6 col-6 p-0 d-inline-block'}>*/}
                                    {/*{customerData.bank_owner}<br />*/}
                                    {/*{customerData.bank_iban} <br />*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </Tab>
                    <Tab eventKey="lieferadressen" title="Lieferadressen" className={'csPointsInfo'}>
                        {cpData.map(function(csPoint, index){
                            return (<div key={index} className={'csPointInfo'}>
                                <div className={'row'}>
                                    <div className={'col-lg-12 mb-4'}><h4><b>{index+1}. {csPoint.street} {csPoint.house_number}, {csPoint.postal_code} {csPoint.city}</b></h4></div>
                                    <div className={'col-lg-6 col-md-6 col-sm-12 mb-4'}>
                                        <div className={'col-lg-4 col-md-4 col-sm-4 col-4 p-0 d-inline-block'}>
                                            <b>Malo ID:</b><br />
                                            <b>Verbrauch:</b><br />
                                            <b>Lasttyp:</b><br />
                                            <b>Branche:</b><br />

                                        </div>
                                        <div className={'col-lg-8 col-md-8 col-sm-6 col-6 p-0 d-inline-block'}>
                                            {csPoint.extended_information.market_identifier}<br />
                                            {csPoint.mean_consumption}<br />
                                            {csPoint.consumption_type}<br />

                                            {showBusinessType(csPoint.extended_information.business_type, csPoint.consumption_type)}
                                        </div>
                                    </div>
                                        {getPreInfos(csPoint.extended_information)}
                                        {getInvoiceInfos(csPoint.invoice_address)}
                                </div>
                            </div>)}
                        )}
                    </Tab>
                    <Tab eventKey="tarif" title="Tarif">
                        <Tariffs page={'contractAddFinished'} hideSubmitBtn={true} tariffs={this.state.tariff} offerID={this.state.offerID} customerID={this.state.customerID} />
                    </Tab>
                </Tabs>

                <div className={"mt-4"}>
                    <span className={'text'}>
                    Vielen Dank für Ihr Interesse! Anbei erhalten Sie Ihren individuell erstellten Versorgungsvertrag vorab
                    zur Prüfung als Muster. Um den Original-Vertrag zu erhalten, klicken Sie bitte weiter unten auf "Kostenpflichtig bestellen",
                    dieser wird Ihnen anschließend direkt per Mail zugestellt.
                    </span>
                </div>

                <div>
                    {this.getDisplayContratBtn()}
                </div>
                
                <span className={'text'}>
                    Hinweis: Bitte beachten Sie, dass Sie mit Ihrer Bestätigung auf "KOSTENPFLICHTIG BESTELLEN" eine verbindliche Anfrage,
                    zu den von Ihnen gewählten Konditionen, an den Energieversorger stellen. Dieser bearbeitet Ihre Anfrage
                    innerhalb von zwei Werktagen.
                </span>
                <br/>

                <form className={"mt-3"} onSubmit={this.handleSubmit}>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <Alert id={'checkboxError'} className={'eless-dynamic-error'}>
                                <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                <span id={"checkboxErrorSpan"}/>
                            </Alert>
                        </div>
                    </div>

                    <div className={"row mr-0"}>
                        <div className={"col-md-12"}>
                            <label className="pr-2 mb-0 eless-checkbox" onClick={() => this.handleCheckbox("elessAGB")}>
                                <span id={'elessAGB'} className={'o-layout-1 '} /><span className={'checkbox-text'}>Ich habe die AGB der e.less Systems GmbH gelesen und akzeptiere diese.</span>
                            </label>
                            <a className={"downloadLinkAGB"}  target={"_blank"} href={"https://www.eless.de/agb/"}>
                                <span ><b>(e.less AGB)</b></span>
                            </a>
                        </div>
                    </div>
                    <div className={"row mr-0"}>
                        <div className={"col-md-12"}>
                            <label className="pr-2 mb-0 eless-checkbox"  onClick={() => this.handleCheckbox("providerAGB")}>
                                <span id={'providerAGB'} className={'o-layout-1'} /><span className={'checkbox-text'}>Ich habe die AGB des Energieversorgers gelesen und akzeptiere diese.</span>
                            </label>
                            <a className={"downloadLinkAGB"}  target={"_blank"} href={"https://api2.eless.de/static_file?file_id=" + this.state.tariff[0].tariff_information.agb_file_id}>
                                <span ><b>(Energieversorger AGB)</b></span>
                            </a>
                        </div>
                    </div>
                    <div className={"row mr-0"}>
                        <div className={"col-md-12 pr-0"}>
                            <button className='eless-cta-btn eless-btn w-100 mt-5 mb-100' id={'submitBtn'} disabled={this.state.buttonDisabled}>Kostenpflichtig bestellen
                                {this.state.loadingSpinner}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
            <Footer />
            </>
        );
        }else{
            return(
                <>
                    <Header />
                    <div className='max-width-content'>
                        <LoadingAnimationDotted text={'Vertrag wird vorbereitet'} hideclass={this.state.showLoadingAnimation} />
                    </div>
                    <Footer />
                </>
            )
        }
    }
}