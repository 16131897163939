import "whatwg-fetch";

async function getStreets(postalCode, city) {
    return await fetch(global.apiURL, {
        method: 'POST',
        headers: {
            'Authorization': global.apiBearer,
            'Content-Type': global.apiContentType
        },
        http_errors: false,
        body: JSON.stringify({
            version: 1.0,
            user: global.apiUser,
            auth_token: global.apiUserAuthToken,
            service: global.apiServiceNetfee,
            function: global.apiFunctionGetStreets,
            param: {
                postal_code: postalCode,
                city: city
            }
        })
    })
        .then(res => res.json())
        .then((result) => {
            if (result.status === "ok") {
                return result.result;
            }else{
                return "ERR";
            }
        }).catch(function (error) { // catch
        console.log('Request failed', error);
    });
}

export default getStreets;