import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircle, faDownload} from "@fortawesome/free-solid-svg-icons";

class TariffInformation extends React.Component {
    constructor(props) {
        super(props);
        this.checkSocket = this.checkSocket.bind(this);
        this.translateCancellationPeriod = this.translateCancellationPeriod.bind(this);
        this.getTranslation = this.getTranslation.bind(this);
        this.translateContractExtension = this.translateContractExtension.bind(this);
        this.getBrochureFile = this.getBrochureFile.bind(this);
        this.getHeadlineTariffDocuments = this.getHeadlineTariffDocuments.bind(this);
    }

    translateCancellationPeriod (cancellation) {
        var split = cancellation.split(' ');
        if (split[0] === "none") {
            return <div>
                Festes Vertragsende
            </div>
        }else{
            var translated = this.getTranslation(split[1]);
            return <div>
               {split[0] + " " + translated}
            </div>
        }
    }

    translateContractExtension (extension) {
        var split = extension.split(' ');
        if (split[0] === "none") {
            return <div>
               Vertragsverlängerung: Keine Verlängerung
            </div>;
        }else{
            var translated = this.getTranslation(split[1]);
            return <div>
               {split[0] + " " + translated}
            </div>
        }

    }

    getTranslation(translate){
        if (translate.includes("months"))return "Monate";
        else if (translate.includes("weeks"))return "Wochen";
        else if (translate.includes("month"))return "Monat";
        else if (translate.includes("week"))return "Woche";
        else return "N/A";
    }

    checkSocket(socket){
        if(socket > 0){
            return socket;
        }else{
            return <div>Keine Grundgebühr</div>
        }
    }

    getHeadlineTariffDocuments(fileID){
        if (fileID !== null) return  <h5>AGB & Tarifbroschüre</h5>;
        else return <h5>AGB</h5>

    }


    getBrochureFile(fileID){
       if (fileID !== null){
           return <div className={"col-md-6 col-lg-6 col-sm-12"}>
               <a target={"_blank"} href={"https://api2.eless.de/static_file?file_id=" + this.props.tariff.tariff_information.brochure_file_id}>
                   <FontAwesomeIcon icon={faDownload} color={"#FE821E"}/> <span className={"downloadLink"}>Tarif- Broschüre herunterladen</span>
               </a>
           </div>
       }
    }

    render() {
        var tariff = this.props.tariff;
        var conditions = tariff.tariff_condition;

        conditions.payment_options = "Überweisung, Lastschrift";
        conditions.payment_method = "Monatlich";
        conditions.payment_deadline = "14 Tage";
        conditions.contract_type = "Online";

        var energySource;
        if (tariff.tariff_condition.energy_source === "green") energySource = "Öko";
            else energySource = "Grau";

        var socket = this.checkSocket(tariff.price.socket);

        var table;
        if(this.props.gasQuality !== '' && this.props.controlArea !== ''){
            table =
                <table className="table table-hover tariff-information-table">
                    <tbody>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Tarifbeschreibung</th>
                        <td>{tariff.description}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Gasqualität</th>
                        <td>{this.props.gasQuality}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Marktgebiet</th>
                        <td>{this.props.controlArea}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Grundgebühr</th>
                        <td>{socket}</td>
                    </tr>
                    {/*<tr>*/}
                        {/*<th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Mengentoleranz</th>*/}
                        {/*<td>+/- {tariff.tariff_condition.quantity_tolerance} %</td>*/}
                    {/*</tr>*/}
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Vertragstyp</th>
                        <td>{ conditions.contract_type}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Energiequelle</th>
                        <td>{energySource}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Vertragsverlängerung</th>
                        <td>{this.translateContractExtension(conditions.contract_extension)}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Kündigungsfrist</th>
                        <td>{this.translateCancellationPeriod(conditions.cancellation_period)}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Zahlungsart</th>
                        <td>{conditions.payment_options}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Zahlungsweise</th>
                        <td>{conditions.payment_method}</td>
                    </tr>
                    <tr>
                        <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Zahlungsziel</th>
                        <td>{conditions.payment_deadline}</td>
                    </tr>
                    </tbody>
                </table>;
        }else{
            table =
            <table className="table table-hover tariff-information-table">
                <tbody>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Tarifbeschreibung</th>
                    <td>{tariff.description}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Grundgebühr</th>
                    <td>{socket}</td>
                </tr>
                {/*<tr>*/}
                    {/*<th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Mengentoleranz</th>*/}
                    {/*<td>+/- {tariff.tariff_condition.quantity_tolerance} %</td>*/}
                {/*</tr>*/}
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Vertragstyp</th>
                    <td>{ conditions.contract_type}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Energiequelle</th>
                    <td>{energySource}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Vertragsverlängerung</th>
                    <td>{this.translateContractExtension(conditions.contract_extension)}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Kündigungsfrist</th>
                    <td>{this.translateCancellationPeriod(conditions.cancellation_period)}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Zahlungsart</th>
                    <td>{conditions.payment_options}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Zahlungsweise</th>
                    <td>{conditions.payment_method}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Zahlungsziel</th>
                    <td>{conditions.payment_deadline}</td>
                </tr>
                </tbody>
            </table>;
        }

        return(
            <div className={"col-md-12 col-lg-12 col-sm-12"}>
                <h4>Tarif Informationen</h4>
                <div className={"row"}>
                    <div className={"col-md-12 col-lg-12 col-sm-12"}>
                        {table}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-md-12 col-lg-12 col-sm-12"}>
                        {this.getHeadlineTariffDocuments(this.props.tariff.tariff_information.brochure_file_id)}
                    </div>
                </div>

                <div className={"row mt-3"}>
                    <div className={"col-md-6 col-lg-6 col-sm-12"}>
                        <a target={"_blank"} href={"https://api2.eless.de/static_file?file_id=" + this.props.tariff.tariff_information.agb_file_id}>
                            <FontAwesomeIcon icon={faDownload} color={"#FE821E"}/> <span className={"downloadLink"}>AGB herunterladen</span>
                        </a>
                    </div>

                    {this.getBrochureFile(this.props.tariff.tariff_information.brochure_file_id)}
                </div>

            </div>
        );
    }
}

export default TariffInformation;