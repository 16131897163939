import React from 'react';
import ElessLogo from './media/energydeal_white.png';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './global';

class Header extends React.Component {

    state = {
       burgerMenuClass: '',
       fullPageMenu: '',
    };

    toggleMenu = () => {
        if(this.state.burgerMenuClass === ''){
            this.setState({burgerMenuClass: ' cross',
                fullPageMenu:
                    <div id="eless-menu" className={'full-page-menu'}>
                        <div id="eless-menu">
                            <div className="eless-menu-links">
                                <a className='eless-menu-link' href={global.HomeURL}>Angebote</a>
                                <a className='eless-menu-link' href={global.Contracts}>Verträge</a>
                                <a className='eless-menu-link' href={global.Accountsettings}>Konto</a>
                                <a className='eless-menu-link' href={''} onClick={this.logout}>Logout</a>
                            </div>
                        </div>
                    </div>
            });
            document.body.classList.add('disable-scrolling');
        }else{
            this.setState({burgerMenuClass: '', fullPageMenu: ''});
            document.body.classList.remove('disable-scrolling');
        }
    };

    logout = (e) => {
       e.preventDefault();

        localStorage.removeItem("securityToken");
        localStorage.removeItem("token");

        window.location.href = "https://www.eless.de/";
    };

    render() {
        const logo_style = {width: '170px'};

        return(<header>
            <ReactCSSTransitionGroup
                transitionName="fade"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
                {this.state.fullPageMenu}
            </ReactCSSTransitionGroup>
            <div className='section-content'>
                <div className="site-branding-container">
                    <a href="https://app.eless.de/abnahmestellen"><img src={ElessLogo} alt={'logo'} style={logo_style}/></a>
                </div>

                <div className="menu-link-wrapper on" onClick={this.toggleMenu}>
                    <div className="menu-link-text">
                        Menü
                    </div>
                    <div id="menu-link-icon" className={"menu-link-icon"+this.state.burgerMenuClass}>
                        <div className="top-bar"></div>
                        <div className="mid-bar"></div>
                        <div className="bot-bar"></div>
                    </div>
                </div>
            </div>
        </header>)
    }
}

export default Header;