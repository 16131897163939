import React from 'react';
import CSPointsIndex from "./CSPointsIndex";
import Duration from "./Duration";
import '../global';
import handleFormatNumber from '../functions/handleFormatNumber';
import Energytype from "./Energytype";
import Breadcrumb from "./Breadcrumb";
import Alert from 'react-bootstrap/Alert';
import APIErrorAlert from '../errorpage/APIErrorAlert';
import ErrorHandlerCP from './ErrorHandlerConsumptionPoint/ErrorHandlerCP';
import unixToDate from "../functions/unixToDate";
import ActivatePassword from "../userlogin/ActivatePassword";
import getEnergyType from "../functions/getEnergyType";
import getUrlParam from "../functions/getUrlParams";
import getProfiles from "../fetches/getProfiles";
import getCspointsByCustomer from "../fetches/getCspointsByCustomer";
import getPredictionInformation from "../fetches/getPredictionInformation";
import Footer from "../Footer";
import Header from "../Header";
const AES = require("crypto-js/aes");
const ENC = require("crypto-js/enc-utf8");

export default class DurationAndConsumptionPoints extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            cspointsResultArray: [],
            backupConsumptionPointsForFilter: [],
            energyType: 'ELECTRICITY',
            errorClass: 'hide-error',
            errorText: '',
            apiError: false,
            startDate:  "",
            cspfilters: [],
            changeDate: false,
            cspointsAreLoading: true,
            showPasswordModal: false
        };

        this.setState = this.setState.bind(this);
        this.updateEnergyType = this.updateEnergyType.bind(this);
        this.refreshConsumptionPoints = this.refreshConsumptionPoints.bind(this);
        this.getProfiles = this.getProfiles.bind(this);
        this.callbackDisplayError = this.callbackDisplayError.bind(this);
        this.callbackFilterConsumptionpoints = this.callbackFilterConsumptionpoints.bind(this);
        this.callbackUpdateBegin = this.callbackUpdateBegin.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.filterConsumptionPoints = this.filterConsumptionPoints.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.apiErrorOccured = this.apiErrorOccured.bind(this);
        this.apiErrorCallback = this.apiErrorCallback.bind(this);
    }

    apiErrorCallback = () => {
        this.setState({apiError: false});
    };

    callbackUpdateBegin = (changedDate) => {
        var cps = this.state.cspointsResultArray;
        this.setState({
            startDate: changedDate,
            changeDate: true
        });
        var filters = document.getElementsByClassName("active"); //get all selected Inputs
        var arrayLength = filters.length;
        var validIDs = [  //whitelist ID's from Filter
            "hasContract",
            "hasNoContract",
            "lastTypeSLP",
            "lastTypeRLM"
        ];
        var filterArray = [];
        for (var i = 0; i < arrayLength; i++) {  //get all filter values from elements
            if (validIDs.includes(filters[i].id)){
                filterArray.push(filters[i].dataset.value);
            }
        }
        this.filterConsumptionPoints(filterArray, cps);  //use set filter on the consumptionPoints
    };

    callbackFilterConsumptionpoints = (filter, action, sibling = "") => {
        if(filter === '' && action==='clear'){
            if(this.state.backupConsumptionPointsForFilter.length !== 0){
                var cspoints = this.state.backupConsumptionPointsForFilter;
                this.setState({cspointsResultArray: cspoints, cspfilters: []});
            }
        }else{
            var consumptionPoints = this.state.cspointsResultArray;
            var filters = this.state.cspfilters;
            if(action === 'add'){ //add or remove filter when clicked
                filters = filters.filter(e => e !== sibling);//remove siling if exists
                filters.push(filter);//add new filter value
            }else{
                filters = filters.filter(e => e !== filter); //remove selected filter value
            }
            this.filterConsumptionPoints(filters, consumptionPoints);
        }
    };

    filterConsumptionPoints(filters, consumptionPoints){
        this.setState({cspfilters: filters});
        if(this.state.backupConsumptionPointsForFilter.length === 0){ //Backup consumptionpoints before filtering them
            this.setState({backupConsumptionPointsForFilter: consumptionPoints});
        }

        if(this.state.backupConsumptionPointsForFilter.length !== 0){  //Always filter thourgh all consumptionpoints
            consumptionPoints = this.state.backupConsumptionPointsForFilter;
        }

        if(filters.includes('RLM')){  //Filter itself
            consumptionPoints = consumptionPoints.filter(cspoint => cspoint.consumption_type === 'RLM');
        }
        else if(filters.includes('SLP')){
            consumptionPoints = consumptionPoints.filter(cspoint => cspoint.consumption_type === 'SLP');
        }

        var begin = document.getElementById("duration-start").value;
        var beginNew = unixToDate(begin, "fix");  //format it so we can make a js date format out of it (to get unix time for compare reasons)
        beginNew = new Date(beginNew);

        if(filters.includes('Contract')){
            consumptionPoints = consumptionPoints.filter(function(cspoint){
                if (cspoint.extended_information.hasOwnProperty("contracts")) {
                    if (cspoint.extended_information.contracts.length > 0) {
                        var contracts = cspoint.extended_information.contracts;
                        for (var property in contracts) {
                            if (contracts[property].status === "submitted" || contracts[property].status === "confirmed") {
                                //check if offer begin is in range of contract duration
                                // end = unixToDate(contracts[property].contract_end, "dot");
                                var newEnd = new Date(contracts[property].contract_end);
                                //if start date is after the end date of the contract enable it
                                if (beginNew < newEnd) {
                                    return cspoint;
                                }
                            }
                        }
                    }
                }
                return null;
            });
        }
        else if(filters.includes('NoContract')) {
            consumptionPoints = consumptionPoints.filter(function(cspoint){
                var isTrue = 1;
                if (cspoint.extended_information.hasOwnProperty("contracts")) {
                    if (cspoint.extended_information.contracts.length > 0) {
                        var contracts = cspoint.extended_information.contracts;
                        for (var property in contracts) {
                            if (contracts[property].status === "submitted" || contracts[property].status === "confirmed"){
                                //check if offer begin is in range of contract duration
                                // end = unixToDate(contracts[property].contract_end, "dot");
                                var newEnd = new Date(contracts[property].contract_end);
                                //if start date is after the end date of the contract enable it
                                if (beginNew < newEnd) {
                                    isTrue = 0;
                                }

                            }
                        }
                        if (isTrue === 1) return cspoint;
                        else return null;
                    }
                }
                return cspoint;
            });
        }

        //Update variable with consumptionpoints to display
        this.setState({cspointsResultArray: consumptionPoints});
    }

    getStartDate(){
        if(this.state.startDate === ""){
            var date = new Date();
            date = date.setMonth(date.getMonth() + 1);
            return date;
        }else{
            return this.state.startDate;
        }

    }

    callbackUpdateCspointsArray = (cspoints) => {
        this.setState({cspointsResultArray: cspoints});
    };

    callbackDisplayError = (text) => {
        this.setState({errorClass: '', errorText: text});
    };

    getProfiles(energyType){
        var service;
        if(energyType === 'ELECTRICITY'){
            service = global.apiElectricityProfileService;
        }else{
            service = global.apiGasProfileService;
        }

        var lastTypes = ["RLM", "SLP"];

        global.slpProfiles = {};
        global.rlmCodes = {};

        lastTypes.forEach(function(lastType) {
                getProfiles(service, lastType).then(function(data){
                    if(data !== 'ERR'){
                        data.map( single => {
                                if (lastType === "SLP"){
                                    global.slpProfiles[single.code] = single.name;
                                }else {
                                    global.rlmCodes[single.code] = single.name;
                                }
                                return 0;
                            }
                        );
                    }else{
                        this.setState({apiError: true});
                    }
                }.bind(this))
        }.bind(this));
    }

    updateEnergyType = (energyType) => {
        localStorage['mediumTypeParam'] = energyType;
        this.setState({energyType: energyType, cspointsResultArray: [], displayCspointsLoading: 'active', cspointsAreLoading: true});
        this.getProfiles(energyType);
        this.refreshConsumptionPoints(energyType);
    };

    componentDidMount() {
        var mediumTypeParam = getEnergyType(); //check if we tracked last click
        if (mediumTypeParam !== null){ //if we have a result
            if (mediumTypeParam === "GAS" || mediumTypeParam === "ELECTRICITY"){ //check if val is manipulated
                this.setState({
                    energyType: mediumTypeParam
                });
            }else{
                mediumTypeParam = this.state.energyType; //else set state default val (electricity)
            }
        }else{
            mediumTypeParam = this.state.energyType; //else set state default val (electricity)
        }
        localStorage['mediumTypeParam'] = mediumTypeParam; //set localStorage (incase of reloading site)
        this.refreshConsumptionPoints(mediumTypeParam);
        this.getProfiles(mediumTypeParam);

        //trigger modal when first time on page
        var offerActivationTokenParam = getUrlParam()["activation"];
        if(typeof offerActivationTokenParam !== "undefined") {
            this.setState({
                showPasswordModal: true
            });
        }
    }

    refreshConsumptionPoints(energyType){
        var countDown = setInterval(function() {
            if (typeof localStorage['securityToken'] !== "undefined") {
                this.customerID  = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
                //TODO Siehe zwei untere TODOS
                var arrayIndex = 0;

                let arrayState = [];
                this.setState({energyType: energyType});
                //Get Consumption Points without their LoadProfile ID's
                getCspointsByCustomer(this.customerID, energyType).then(function(firstResult){ //Get LoadProfile ID's add it to the Consumption Point Data from prev fetch
                    if(firstResult !== 'ERR'){
                        if (firstResult !== "[]"){
                            firstResult.map(function(single){
                                let csPointData = single;
                                if(single.prediction_id === null){
                                    //TODO wie entstehen denn Lieferstellen ohne prediction ID? Es wäre besser
                                    // wenn man die Überprüfung on null 2 Zeilen höher nicht gebraucht hätte.
                                }else{
                                    getPredictionInformation(single.prediction_id).then(function(secondResult){
                                        if(secondResult !== 'ERR'){
                                            //add consumption to obj
                                            csPointData.mean_consumption = handleFormatNumber(secondResult.mean_consumption+"") + " kWh";
                                            //tmp array for saving in state array later
                                            //TODO arrayIndex changed from count because of the upper TODO
                                            arrayState[arrayIndex] = csPointData;
                                            arrayIndex++;
                                        }else{
                                            this.apiErrorOccured('predictInformation', firstResult);
                                        }
                                    }.bind(this));
                                    return 0;
                                }
                            }.bind(this));

                            setTimeout(function() {
                                this.setState({
                                    displayCspointsLoading: '',
                                    cspointsAreLoading: false,
                                    cspointsResultArray: arrayState,
                                });
                            }.bind(this), 2000);
                        }else{
                            this.setState({
                                displayCspointsLoading: '',
                                cspointsAreLoading: false
                            });
                        }
                    }else{
                        this.apiErrorOccured('cspointsByCustomer', firstResult);
                    }
                }.bind(this));
                clearInterval(countDown);
            }
        }.bind(this), 500);
        //have to set timeout because it takes a short time to save customerID in session
    }

    apiErrorOccured(fetchName, result){
        this.setState({apiError: true});
        console.log(fetchName, result);
    }

    closeModal(){
        this.setState({showPasswordModal: false});
    }

    render(){
        return(
            <>
                <Header />
                <div className='max-width-content'>
                <APIErrorAlert closeApiError={this.apiErrorCallback} show={this.state.apiError} />
                <Breadcrumb active={1} match={this.props.match}/>
                <ErrorHandlerCP />
                <div className={'tariffs-title section_heading'}>
                    <h2>Energieträger</h2>
                </div>
                <Energytype cspointsAreLoading={this.state.cspointsAreLoading} callbackUpdateEnergyType={this.updateEnergyType} energyType={this.state.energyType} />
                <div className='tariffs-title section_heading'>
                    <h2 className='marked'>Lieferzeitraum</h2>
                </div>
                <Duration callbackUpdateBegin={this.callbackUpdateBegin} startDate={this.getStartDate()}/>
                <div className='tariffs-title section_heading'>
                    <h2 className='marked'>Lieferstellen</h2>
                </div>
                <Alert className={'eless-error ' + this.state.errorClass}>
                    <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b> {this.state.errorText}
                </Alert>
                <CSPointsIndex
                    callbackRemoveSiblingFilter={this.callbackRemoveSiblingFilter}
                    callbackFilterConsumptionpoints={this.callbackFilterConsumptionpoints}
                    callbackUpdateCspointsArray={this.callbackUpdateCspointsArray}
                    callbackDisplayError={this.callbackDisplayError}
                    energyType={this.state.energyType}
                    match={this.props.match}
                    customerID={this.customerID}
                    cspointsResult={this.state.cspointsResultArray}
                    cspfilters={this.state.cspfilters}
                    displayCspointsLoading={this.state.displayCspointsLoading}
                    changeDate={this.state.changeDate}
                    cspointsAreLoading={this.state.cspointsAreLoading}
                />
                <ActivatePassword customerID={this.customerID}  show={this.state.showPasswordModal} closeModal={this.closeModal} />
                </div>
                <Footer />
            </>
        );
    }
}