import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import getUrlParam from "../functions/getUrlParams";
import resetPassword from "../fetches/resetPassword";
import elesslogo from "../media/energydeal-blue.png";
import Header from "../Header";
import Footer from "../Footer";
const AES = require("crypto-js/aes");

export default class ActivateNewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleSubmitCsPoints = this.handleSubmitCsPoints.bind(this);

    }

    componentDidMount() {
        setTimeout(function () {
            var wrapperElement = document.getElementById('login-wrapper');
            wrapperElement.classList.add('show-login-wrapper');
        }, 100);
    }


    handleSubmitCsPoints(e){
        var subBtn = e.target;
        var password1 = document.getElementById("password1");
        var password2 = document.getElementById("password2");
        var spinner = document.getElementById("spinner");
        var errorHandling = document.getElementById("error-handling");
        // var expiredToken = document.getElementById("expiredToken");
        var invalidPassword = document.getElementById("invalidPassword");
        var invalidToken = document.getElementById("invalidToken");
        var emptyPassword = document.getElementById("emptyPassword");
        var successHandling = document.getElementById("success-handling");
        var error = 0;
        var token = getUrlParam()["reset"];
        subBtn.disabled = true;

        invalidToken.classList.add("d-none");
        successHandling.classList.add("d-none");
        emptyPassword.classList.add("d-none");
        invalidPassword.classList.add("d-none");
        errorHandling.classList.add("d-none");
        spinner.classList.remove("d-none");

        if (typeof token === "undefined"){
            error = 1;
            invalidToken.classList.remove("d-none");
        }

        if (password1.value !== password2.value){
            error = 1;
            password1.classList.add("alert-border");
            password2.classList.add("alert-border");
            invalidPassword.classList.remove("d-none");

        }
        else if ( password1.value.length < 6 ){
            error = 1;
            password1.classList.add("alert-border");
            password2.classList.add("alert-border");
            emptyPassword.classList.remove("d-none");
        }
        else {
            password1.classList.remove("alert-border");
            password2.classList.remove("alert-border");
            invalidPassword.classList.add("d-none");
            emptyPassword.classList.add("d-none");
        }

        if (error === 0){
            resetPassword(token, password1.value).then(results => {
                        if (results !== "ERR") { //success
                            errorHandling.classList.add("d-none");
                            setTimeout(function () {
                                successHandling.classList.remove("d-none");
                                spinner.classList.add("d-none");
                                subBtn.disabled = false;
                                localStorage['securityToken'] = AES.encrypt(results.customer_id+'', global.encriptKey);
                                setTimeout(function () {
                                    window.location.href = global.HomeURL;
                                }, 500);
                            }, 1500);
                        } else {
                            //error
                            if (results.result === "Unknown customer."){}
                            //error
                            invalidToken.classList.remove("d-none");
                            errorHandling.classList.remove("d-none");
                        }
                        spinner.classList.add("d-none");
                        subBtn.disabled = false;
                    }).catch(function (error) {
                        spinner.classList.add("d-none");
                        subBtn.disabled = false;
                        console.log('Request failed', error);
            });
        }else{
            successHandling.classList.add("d-none");
            setTimeout(function () {
                errorHandling.classList.remove("d-none");
                spinner.classList.add("d-none");
                subBtn.disabled = false;
            }, 1000);
        }

    }

    render () {
        const logo_style={width: '75%'};
        return (
            <>
            <Header />
            <div className='max-width-content'>
            <div id='login-wrapper'>
                <div className={"row d-none d-sm-none d-md-block"}>
                    <div className={"col-md-12"}>
                        <div id={"login-header"}>
                            <a href={'https://www.eless.de/'}><img alt='' src={elesslogo} style={logo_style}/></a>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"} >
                        <div id={"login-body"} className={"eless-form"}>
                            <h2 className={'login-title'}>Neues Passwort festlegen</h2>
                            <Alert id="success-handling" className={'mb-1 d-none eless-success'}>
                                <span className={'o-checked-circle-1 medium-icon'}/><b>Erfolg!</b>
                                <span className={"ml-1"}>Ihr Passwort wurde erfolgreich geändert.</span>
                            </Alert>

                            <Alert id="error-handling" className={'mb-1 d-none eless-error'}>
                                <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b>
                                <span id={"invalidPassword"} className={"d-none ml-1"}><br/>Die angegebenen Passwörter stimmen nicht über ein.</span>
                                <span id={"invalidToken"} className={"d-none ml-1"}><br/>
                                    Aktivierungs-Token nicht bekannt oder abgelaufen. Bitte fordern Sie eine neue E-Mail an oder melden Sie sich bei <a className={"downloadLink"} target={"_blank"} href={"https://www.eless.de/kontakt/"}>unserem Support</a>.
                                </span>
                            <span id={"emptyPassword"} className={"d-none ml-1"}><br/>Bitte geben Sie ein gültiges Passwort an (mind. 6 Ziffern).</span>
                            </Alert>

                            <input autoComplete="new-password" type='password' id='password1' placeholder='Neues Passwort' />
                            <input autoComplete="new-password" type='password' id='password2' placeholder='Passwort bestätigen' />

                            <button id={"resetPassword"}  className='eless-cta-btn eless-btn w-100 login-cta mt-2 position-relative' onClick={(e) => this.handleSubmitCsPoints(e)}>
                                Bestätigen
                                <span id={'spinner'} className={'d-none'}><FontAwesomeIcon className={'fa-spin fa-2x'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/></span>
                            </button>
                        </div>
                    </div>
                    <div className={"col-md-12 text-center mt-1 mb-2"} >
                        <div id={"login-footer"} className={"eless-form"}>
                            <a title="Zum e.less Login" href={"/login"} className={"mb-2 link"}> Zurück zum Login</a> <br/>
                            <a className={"link"} title="Zur e.less Startseite" href={"https://eless.de/" }> Zur Startseite</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
            </>
        );
    }
}