import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircle, faDownload} from "@fortawesome/free-solid-svg-icons";
import handleFormatNumber from "../../../functions/handleFormatNumber";

class ProviderInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        var tariff = this.props.tariff;
        var providerInfo = tariff.supplier.information;

        var foundationYear = providerInfo.foundation_year;
        if (foundationYear === 0) foundationYear = "Keine Angabe";

        var employeeCount = providerInfo.employee_count;
        if (employeeCount === 0) employeeCount = "Keine Angabe";
        else employeeCount = handleFormatNumber(employeeCount + "");

        var customerCount = providerInfo.customer_count;
        if (customerCount === 0) customerCount = "Keine Angabe";
        else customerCount = handleFormatNumber(customerCount + "");

        var sales = providerInfo.sales_revenue;
        if (sales === 0) sales = "Keine Angabe";
        else sales = (handleFormatNumber(sales + "")) +" €";
        var firstNames = tariff.supplier.basic_information.first_name;
        var lastNames = tariff.supplier.basic_information.last_name;
        var company = '';
        var firstName = '';
        var lastName = '';
        var owner = "";

        if('basic_information' in tariff.supplier){
            company = tariff.supplier.basic_information.company;
            //check if there is more than just 1 name in name vars
            if (firstNames.includes(",") === true && lastNames.includes(",")){
                //get single names and match them with lastname
                firstNames = firstNames.split(", ");
                lastNames = lastNames.split(", ");
                var idx = 0;
                firstNames.forEach(function(item){
                    firstName = item;
                    lastName = lastNames[idx];
                    if (firstName !== "n/a" && lastName !== "n/a")  owner = owner + " " + firstName +" "+ lastName;
                    idx++;
                });
            }else{ //just 1 name -> match with lastname
                firstName = firstNames;
                lastName = lastNames;
                if (firstName === "n/a" || lastName === "n/a") owner = "Keine Angabe";
                else owner = firstName +" "+ lastName;
            }
        }

        var table =
            <table className="table table-hover">
                <tbody>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Name</th>
                    <td>{company}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Gründung</th>
                    <td>{foundationYear}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Anzahl Mitarbeiter</th>
                    <td>{employeeCount}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Anzahl Kunden</th>
                    <td>{customerCount}</td>
                </tr>
                <tr>
                    <th scope="row"><FontAwesomeIcon icon={faCircle} size={"sm"} className={"iconNetFee"}/> Umsatz</th>
                    <td>{sales} </td>
                </tr>
                </tbody>
            </table>;

        return(
            <div className={"col-md-12 col-lg-12 col-sm-12"}>
                <h4>Über den Anbieter</h4>
                <div className={"row mb-3 mt-3"}>
                    <div className={"col-md-12 col-lg-12 col-sm-12"}>
                        {providerInfo.description}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12 col-lg-12 col-sm-12"}>
                        {table}
                    </div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col-md-8 col-lg-8 col-sm-12"}>
                        <a target={"_blank"} href={"https://api2.eless.de/static_file?file_id=" + this.props.tariff.supplier.information.brochure_file_id}>
                            <FontAwesomeIcon icon={faDownload} color={"#FE821E"}/> <span className={"downloadLink"}>Anbieter- Broschüre herunterladen</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProviderInformation;