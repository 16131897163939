import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import AES from "crypto-js/aes";
import ENC from "crypto-js/enc-utf8";
import modifyCustomer3 from "../fetches/modifyCustomer3";
import APIErrorAlert from "../errorpage/APIErrorAlert";
import verifyPasswordByCustomerId from "../fetches/verifyPasswordByCustomerId";
import Footer from "../Footer";
import Header from "../Header";

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonTypePw1: 'password',
            buttonTypePw2: 'password',
            apiError: false,
            show: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleShowPassword1 = this.handleShowPassword1.bind(this);
        this.handleShowPassword2 = this.handleShowPassword2.bind(this);
    }

    closePopup(){
        this.setState({show: false});
        this.props.closePopup();
    }

    handleSubmit(e){
        //Process values
        var customerID = AES.decrypt(localStorage['securityToken'], global.encriptKey).toString(ENC);
        var password = document.getElementById('password');
        var newPassword = document.getElementById('newPassword');

        //Message handling
        var errorHandling = document.getElementById("error-handling");
        var successHandling = document.getElementById("success-handling");
        var wrongPasswordMessage = document.getElementById('wrongPassword');
        var weakPasswordMessage = document.getElementById('weakPassword');
        var emptyPassword = document.getElementById('emptyPassword');

        //Remove old ErrorMessages
        successHandling.classList.add('d-none');
        errorHandling.classList.add('d-none');
        weakPasswordMessage.classList.add('d-none');
        wrongPasswordMessage.classList.add('d-none');

        //Ladeanimation & Benutzeraktionen einschränkung
        var subBtn = e.target;
        var spinner = document.getElementById("spinner");
        subBtn.disabled = true;
        spinner.classList.remove("d-none");

        if(password.value.length !== 0){
            emptyPassword.classList.add('d-none');
            //Check if the old password is correct
            verifyPasswordByCustomerId(customerID, password.value).then(results => {
                if(results !== 'ERR' && results !== "Permission denied."){
                    password.classList.remove("alert-border");
                    wrongPasswordMessage.classList.add('d-none');
                    if(weakPasswordMessage.classList.contains('d-none')){ //Wenn es keine "weakPasswort" Meldung gibt, kann hier das Alert verschwinden
                        errorHandling.classList.add('d-none');
                    }

                    //Check if new Password is correct, if so, disable all error messages and modify customers Password
                    if(newPassword.value.length >= 6){
                        newPassword.classList.remove("alert-border");

                        weakPasswordMessage.classList.add('d-none');
                        if(wrongPasswordMessage.classList.contains('d-none')){ //Wenn es keine "wrongPasswort" Meldung gibt, kann hier das Alert verschwinden
                            errorHandling.classList.add('d-none');
                        }
                        modifyCustomer3(customerID, newPassword.value).then((results) => {
                            if (results !== "ERR") {
                                //on success set session
                                errorHandling.classList.add("d-none");
                                setTimeout(function () {
                                    successHandling.classList.remove("d-none");
                                    password.value = '';
                                    newPassword.value = '';
                                    spinner.classList.add("d-none");
                                    subBtn.disabled = false;
                                }, 1500);
                            } else {
                                this.setState({apiError: true});
                                spinner.classList.add("d-none");
                                subBtn.disabled = false;
                            }
                        });
                    }else{ //Invalid new Password
                        newPassword.classList.add("alert-border");
                        errorHandling.classList.remove('d-none');
                        weakPasswordMessage.classList.remove('d-none');
                        spinner.classList.add("d-none");
                        subBtn.disabled = false;
                    }
                } else if(results === "Permission denied.") { //Invalid Password
                    errorHandling.classList.remove('d-none');
                    wrongPasswordMessage.classList.remove('d-none');
                    password.classList.add("alert-border");

                    spinner.classList.add("d-none");
                    subBtn.disabled = false;
                }else{ // API ERROR
                    this.setState({apiError: true});
                }
            });
        }else{//Password empty
            errorHandling.classList.remove('d-none');
            emptyPassword.classList.remove('d-none');
            password.classList.add('alert-border');

            spinner.classList.add('d-none');
            subBtn.disabled = false;
        }
    }

    handleShowPassword2(){
        if(this.state.buttonTypePw2 === 'text'){
            this.setState({buttonTypePw2: 'password'})
        }else{
            this.setState({buttonTypePw2: 'text'})
        }
    }

    handleShowPassword1(){
        if(this.state.buttonTypePw1 === 'text'){
            this.setState({buttonTypePw1: 'password'})
        }else{
            this.setState({buttonTypePw1: 'text'})
        }
    }

    render () {
        return (
            <>
                <APIErrorAlert closeApiError={() => this.setState({apiError: false})} show={this.state.apiError} />
                <Modal id={'resetPasswordPopup'} show={this.props.show} onHide={this.props.closePopup}>
                    <ModalHeader closeButton>
                    </ModalHeader>
                    <ModalBody className={'text-center'} closeButton>
                        <div className={"row"}>
                            <div className={"col-md-12"} >
                                <div id={"login-body"} className={"eless-form"}>
                                    <h2 className={'login-title'}>Passwort ändern</h2>
                                    <span className={'mb-3 d-block'}>
                                        Bitte geben Sie Ihr altes Passwort ein und wählen Sie anschließend ein neues Passwort mit mindestens 6 Zeichen.
                                    </span>
                                    <Alert id="success-handling" className={'mb-1 d-none eless-success text-left'}>
                                        <span className={'o-checked-circle-1 medium-icon'}/><b>Erfolg!</b><br />
                                        <span className={"ml-1"}>Ihr Passwort wurde erfolgreich geändert.</span>
                                    </Alert>

                                    <Alert id="error-handling" className={'mb-1 d-none eless-error text-left'}>
                                        <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler!</b><br />
                                        <span id={"emptyPassword"} className={"d-none"}>Geben Sie bitte das alte Passwort ein.<br /></span>
                                        <span id={"wrongPassword"} className={"d-none"}>Das angegebene alte Passwort ist falsch.<br /></span>
                                        <span id={"weakPassword"} className={"d-none"}>Das neue Passwort muss mindestens 6 Zeichen lang sein.</span>
                                    </Alert>
                                    <div className={'password-wrapper'}>
                                        <input type={this.state.buttonTypePw1} id={'password'} className={'password-field'} placeholder={'Altes Passwort'}/>
                                        <span className={'o-visible-1'} onClick={this.handleShowPassword1} />
                                    </div>
                                    <div className={'password-wrapper'}>
                                        <input type={this.state.buttonTypePw2} id={'newPassword'} className={'password-field'} placeholder={'Neues Passwort'} />
                                        <span className={'o-visible-1'} onClick={this.handleShowPassword2} />
                                    </div>
                                    <button id={"resetPassword"}  className='eless-weiter-btn eless-btn w-100 mb-5 mt-2 position-relative' onClick={(e) => this.handleSubmit(e)}>
                                        Weiter
                                        <span id={'spinner'} className={'d-none'}><FontAwesomeIcon className={'fa-spin fa-2x special'} id={"loadingLoadProfileSpinnerIcon"} icon={faSpinner}/></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}