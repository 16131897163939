import React from "react";

export default class LoadingAnimationDotted extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            loadingText: '',
        };
    }

    componentDidMount() {
        for (var i = 0; i < 1000; i++) {
            setTimeout(function() {
                forLoop(700);
            }, 8+4*700*i);
        }

        var that=this;

        function forLoop(delay) {
            setTimeout(function() {
                that.setState({loadingText: that.props.text+' .'});
                setTimeout(function() {
                    that.setState({loadingText: that.props.text+' ..'});
                    setTimeout(function() {
                        that.setState({loadingText: that.props.text+' ...'});
                        setTimeout(function() {
                            that.setState({loadingText: that.props.text+' '});
                        }, delay);
                    }, delay);
                }, delay);
            }, delay);
        }
    }

    render() {
        return (
            <div className={'loading-animation-dotted ' + this.props.hideclass}>
                <b>{this.state.loadingText}</b>
            </div>
        );
    }
}






