import React from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import getUrlParam from "../functions/getUrlParams";
import handleFormatDurationDate from "../functions/handleFormatDurationDate";
import Alert from 'react-bootstrap/Alert';
// import checkSession from "../functions/checkSessionStatus";
registerLocale('de', de);
setDefaultLocale('de', de);

/* Hier wird der Liefrzeitraum berechnet und gerendert */

export default class Duration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDuration: '',
            endDuration: '',
            durationError: <></>,
            submittedError: <></>,
            maxStartDate: this.getMaxStartDate(),
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
    }

    getMaxStartDate(){
        var dt = new Date();
        dt.setFullYear(dt.getFullYear() + 3);
        dt.setMonth(dt.getMonth() - 2);
        return Date.parse(dt.toISOString().split('T')[0]);
    }

    handleChangeStart(date){
        var calcDate = Date.parse(date);
        var minEndDate = new Date(calcDate);
        minEndDate.setMonth(minEndDate.getMonth() + 1);
        minEndDate = Date.parse(minEndDate);
        localStorage['begin'] = calcDate;
        this.setState({
            minDateEnd: minEndDate,
            //startDuration: date
        });

        //TODO check if timeout is needed
        // setTimeout(() => {
            this.props.callbackUpdateBegin(date)
        // },200);
    }

    handleChangeEnd(date){
        localStorage['end'] = date;
        this.setState({endDuration: date});
    }

    getEndDate(dt = ""){
        //Wenn Anfangsdatum gesetzt ist, berechne daraus das Enddatum
        if(dt !== ''){
            dt.setMonth(dt.getMonth() +11);
            dt.setDate(dt.getDate() - 1);
        }else{
            dt = new Date();
            dt.setMonth(dt.getMonth() +11);
            dt.setDate(dt.getDate() - 1);
        }
        dt.setHours(0,0,0,0);

        var maxDate = new Date(this.getMaxEndDate());
        if (dt > maxDate) dt = maxDate;
        return dt;
    }

    getMaxEndDate(){
        var dt = new Date();
        dt.setFullYear(dt.getFullYear() + 3);
        dt.setDate(dt.getDate() - 1);
        return dt.toISOString().split('T')[0];
    }

    getStartDateForEndDuration(date){
        let dt;
        // Somit wird beim laden der Seite der untere Teil ausgeführt, sonst der obere Teil.
        if(this.state.startDuration !== ''){
            dt = new Date(date);
            dt.setMonth(dt.getMonth() + 12);
        }else{
            dt = new Date();
            dt = dt.setMonth(dt.getMonth() + 13);
        }


        return dt;
    }

    componentWillReceiveProps(nextProps) {
        this.getDurationError();
        this.getSubmittedError();
        var offerBeginParam = getUrlParam()["offer_begin"];
        var defaultBegin = offerBeginParam*1000;
        var defaultDateBegin = new Date(defaultBegin);
        //var defaultBegin = "";
        var defaultEnd = "";
        var minEndDate = "";
        var calcDate;
        var now = new Date();


        //get date now +1 month to check if the saved date in session is still a valid date
        now.setHours(0,0,0,0);
        now  = now.setMonth(now.getMonth()+1);

        //if user login came via link on the website there might be get parameters
        if(typeof offerBeginParam !== "undefined"){ //set date with get params
            defaultBegin = offerBeginParam*1000;
            defaultDateBegin = new Date(defaultBegin);
            //the duration must be atleast 1 month
            minEndDate = defaultDateBegin.setMonth(defaultDateBegin.getMonth() + 1);
            //add 11 monts to get 1 year (+1 Month above) duration for default duration
            defaultEnd = defaultDateBegin.setMonth(defaultDateBegin.getMonth() + 11);
            localStorage['begin'] = defaultBegin;
            localStorage['end'] = defaultEnd;
        }
        else if (typeof localStorage['begin'] !== "undefined" && typeof localStorage['end'] !== "undefined" && localStorage['begin'] > now){ //set date with localStorage
            //begin
            defaultBegin = localStorage['begin'];
            defaultBegin /= 1000;

            defaultBegin = parseInt(defaultBegin) * 1000;
            calcDate = new Date(defaultBegin);
            //min date
            minEndDate = calcDate.setMonth(calcDate.getMonth() + 1);
           // defaultEnd = localStorage['end'];
            //end date
            defaultEnd = this.getEndDate(calcDate);
            defaultEnd = (parseInt(defaultEnd / 1000) * 1000);
            localStorage['end'] = defaultEnd;
        }
        else{//set local Storage if no get  parameter or storage is found
            defaultBegin = nextProps.startDate;
            defaultEnd = this.getEndDate();
            minEndDate = new Date(defaultBegin);
            minEndDate = minEndDate.setMonth(minEndDate.getMonth() + 1);

            localStorage['begin'] = defaultBegin;
            localStorage['end'] = defaultEnd;
        }

        //set state
        this.setState({
            startDuration: defaultBegin,
            endDuration: defaultEnd,
            minDateEnd: minEndDate
        });
    }

    getMinStartDate(){
        var minStartDate = new Date();
        minStartDate = minStartDate.setMonth(minStartDate.getMonth() + 1);
        return minStartDate;
    }

    getDurationError(){
        var expired = getUrlParam()["expired"];

        if (typeof  expired !== "undefined"){
            window.history.replaceState && window.history.replaceState(
                null, '', window.location.pathname + window.location.search.replace(/[\?&]expired=[^&]+/, '').replace(/^&/, '?')
            );
            this.setState({
                durationError: <Alert id={'checkboxError'} className={'eless-error'}>
                    <span className={'o-delete-circle-1 medium-icon'}/><b>Fehler! </b>
                    <span id={"checkboxErrorSpan"}>Der Angebotszeitraum kann nicht mehr angeboten werden.</span>
                </Alert>
            });
        }
    }

    getSubmittedError(){
        var submitted = getUrlParam()["submitted"];

        if (typeof  submitted !== "undefined"){
            window.history.replaceState && window.history.replaceState(
                null, '', window.location.pathname + window.location.search.replace(/[\?&]submitted=[^&]+/, '').replace(/^&/, '?')
            );
            this.setState({
                submittedError: <Alert id={'checkboxError'} className={'eless-info'}>
                    <span className={'o-info-help-1 medium-icon'}/><b>Info! </b>
                    <span id={"checkboxErrorSpan"}>Das Angebot, welches Sie bearbeiten wollten, wurde bereits abgeschlossen. Bitte wählen Sie eine andere Abnahmestelle.</span>
                </Alert>
            });
        }
    }


    render () {
        console.log('MinDate', handleFormatDurationDate("setDefaultStartDate"));

        return (
            <div id='duration-react'>
                {this.state.durationError}
                {this.state.submittedError}
                <div id='duration-fieldset' className='eless-form datepicker'>
                    <DatePicker dateFormat={'dd.MM.yyyy'} locale={'de'} id='duration-start' selected={this.state.startDuration} maxDate={this.state.maxStartDate}
                                onSelect={this.handleChangeStart} minDate={handleFormatDurationDate("getDefaultStartDate")}/>
                    <DatePicker dateFormat={'dd.MM.yyyy'} locale={'de'} id='duration-end' selected={this.state.endDuration} onSelect={this.handleChangeEnd} minDate={this.state.minDateEnd} maxDate={Date.parse(this.getMaxEndDate())}/>
                </div>
            </div>
        );
    }
}